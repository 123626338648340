<template>
  <CharlaFormPopup></CharlaFormPopup>
  <div class="subPage-background">
    <div class="subPage-wrapper">
      <subPageHeader></subPageHeader>
      <div class="page-title">
        <h1 :class="{ ruh1: Tr.currentLocale === 'ru' }">{{ $t("termsOfUse.mainTitle") }}</h1>
      </div>
      <div class="working__wrapper">
      <div class="working__container">
        <h2 class="working__title">{{ $t("termsOfUse.mainTitle") }}</h2>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title1") }}</div>
          <p class="working__text">{{ $t("termsOfUse.block1.text1") }}</p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block1.text2") }}</p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block1.text3") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title1_1") }}</div>
          <p class="working__text">{{ $t("termsOfUse.block2.text1") }}</p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block2.text2") }}</p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block2.text3") }}</p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block2.text4") }}</p>
          <br>
          <ul class="working__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block2.text5") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block2.text6") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block2.text7") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block2.text8") }}</p>
            </li>
          </ul>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block2.text9") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title1_2") }}</div>
          <p class="working__text">{{ $t("termsOfUse.block3.text1") }}</p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block3.text2") }}</p>
          <br>
          <ul class="working__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block3.text3") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block3.text4") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block3.text5") }}</p>
            </li>
          </ul>
          <br>
          <p class="working__text" v-if="Tr.currentLocale === 'ru'">
            Для возврата денежных средств на банковскую карту необходимо заполнить «Заявление о возврате денежных средств», которое Компания высылает по требованию на электронный адрес, и отправить его вместе с приложением копии паспорта по адресу <a href="mailto:welcome@zv-ai.ru">welcome@zv-ai.ru</a>.
          </p>
          <p class="working__text" v-if="Tr.currentLocale === 'en'">
            In order to make a refund to your bank card, you should fill in the "Application for refund", which the Company provides on request to your e-mail address, and send it together with a passport copy to <a href="mailto:welcome@zv-ai.ru">welcome@zv-ai.ru</a>.
          </p>
          <br>
          <p class="working__text" v-if="Tr.currentLocale === 'ru'">
            Возврат денежных средств будет осуществлен на банковскую карту в течение 21 (двадцати одного) рабочего дня со дня получения «Заявления о возврате денежных средств» Компанией. Для возврата денежных средств по операциям, проведенным с ошибками, необходимо обратиться с письменным заявлением и приложением копии паспорта, а также чеков/квитанций, подтверждающих ошибочное списание. Данное заявление необходимо направить по адресу <a href="mailto:welcome@zv-ai.ru">welcome@zv-ai.ru</a>.
          </p>
          <p class="working__text" v-if="Tr.currentLocale === 'en'">
            The refund will be made to the bank card within 21 (twenty-one) working days from the day of receiving the "Application for refund" by the Company. In order to refund money for transactions made in error, it is necessary to apply with a written application and attach a copy of the passport, as well as receipts/payment slip proving the incorrect payment. This application should be sent to <a href="mailto:welcome@zv-ai.ru">welcome@zv-ai.ru</a>.
          </p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block3.text8") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title2") }}</div>
          <p class="working__text">{{ $t("termsOfUse.title2_1") }}</p>
          <br>
          <ul class="working__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block4.text1") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block4.text2") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block4.text3") }}</p>
            </li>
          </ul>
          <br>
          <p class="working__text">{{ $t("termsOfUse.title2_2") }}</p>
          <br>
          <div class="main__grid">
            <p class="main__grid__title">{{ $t("termsOfUse.grid.gridTitle1") }}</p>
            <p class="main__grid__title">{{ $t("termsOfUse.grid.gridTitle2") }}</p>
            <p class="main__text">{{ $t("termsOfUse.grid.column1.row1") }}</p>
            <p class="main__text">{{ $t("termsOfUse.grid.column2.row1") }}</p>
            <p class="main__text">{{ $t("termsOfUse.grid.column1.row2") }}</p>
            <p class="main__text">{{ $t("termsOfUse.grid.column2.row2") }}</p>
            <p class="main__text">{{ $t("termsOfUse.grid.column1.row3") }}</p>
            <div>
              <p class="main__text first">{{ $t("termsOfUse.grid.column2.row3") }}</p>
            </div>
          </div>
          <br>
          <p class="working__text">{{ $t("termsOfUse.title2_3") }}</p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block5.text1") }}</p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.title2_4") }}</p>
          <br>
          <ul class="working__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block6.text1") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block6.text2") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block6.text3") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block6.text4") }}</p>
            </li>
          </ul><br>
          <p class="working__text">{{ $t("termsOfUse.title2_5") }}</p>
          <br>
          <ul class="working__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block7.text1") }}</p>
            </li>
          </ul>
          <br>
          <p class="working__text">{{ $t("termsOfUse.title2_6") }}</p>
          <br>
          <ul class="working__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block8.text1") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block8.text2") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block8.text3") }}</p>
            </li>
            <li class="working__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block8.text4") }}</p>
            </li>
            <li class="working__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block8.text5") }}</p>
            </li>
          </ul><br>
          <p class="working__text">{{ $t("termsOfUse.title2_7") }}</p>
          <br>
          <ul class="working__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block9.text1") }}</p>
            </li>
          </ul><br>
          <p class="working__text">{{ $t("termsOfUse.title2_8") }}</p>
          <br>
          <ul class="working__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text" v-if="Tr.currentLocale === 'ru'">
                Для консультаций по ценам и услугам, пожалуйста, свяжитесь с нашим отделом продаж по почте <a href="mailto:welcome@zv-ai.ru">welcome@zv-ai.ru</a>.
              </p>
              <p class="working__text" v-if="Tr.currentLocale === 'en'">
                Please contact our sales department at <a href="mailto:welcome@zv-ai.ru">welcome@zv-ai.ru</a> for any questions about prices and services..
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
      <subPageFooter></subPageFooter>
    </div>
  </div>
</template>

<script>
import Tr from "@/i18n/translation";
import subPageFooter from "@/components/subPages/subPageFooter.vue";
import subPageHeader from "@/components/subPages/subPageHeader.vue";
import CharlaFormPopup from "@/components/CharlaFormPopup.vue";

export default {
  name: "publicOffer",
  components: { CharlaFormPopup, subPageHeader, subPageFooter },
  computed: {
    Tr() {
      return Tr
    }
  },

}
</script>

<style scoped lang="sass">
.subPage-background
  background: #F6F5F8
  height: 100%
.subPage-wrapper
  max-width: 1112px
  margin: auto
  padding: 64px 0

.faq-wrapper
  margin: 64px auto

.page-title
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 64px

h1
  font-size: 56px
  font-weight: 700
  margin: 0

  span
    color: #98DA2C

.title-btn
  display: flex
  align-items: center
  justify-content: center
  gap: 16px
  max-width: 240px
  width: 100%
  height: 48px
  border-radius: 24px
  background: #000
  color: #fff
  transition: .3s ease
  cursor: pointer
  border: 1px solid #000

  &:hover
    background: #fff
    color: #000

    svg path
      transition: .3s ease
      fill: #000

p
  font-size: 16px
  line-height: 164%

  &:last-child
    margin-bottom: 0

ul
  padding-left: 30px

  li
    font-size: 16px
    margin-bottom: 12px
    line-height: 164%
    list-style-type: disc

h2
  font-size: 22px
  margin: 20px 0 10px 0

h3
  font-size: 24px
  font-weight: 400
  margin: 0

@media screen and (max-width: 1200px)
  .subPage-wrapper
    margin: 0 40px

@media screen and (max-width: 970px)
  .reviews-grid
    grid-template-columns: 1fr 1fr
  h1
    font-size: 32px

@media screen and (max-width: 660px)
  .page-title
    display: flex
    flex-direction: column
    gap: 22px
  .subPage-wrapper
    margin: 0 20px
    padding: 24px 0
  h1
    font-size: 32px
    font-weight: 400
  h2
    font-size: 24px
  h3
    font-size: 18px

  p
    font-size: 13px
    line-height: 154%
  ul li
    font-size: 13px
    line-height: 154%
  .title-btn
    font-size: 14px
    max-width: 200px


</style>

<style scoped lang="scss">
.main__grid {
  display: grid;
  grid-template-columns: minmax(auto, 300px) 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  border-radius: 20px;
}

.main__text {
  padding: 10px;
  height: 100%;
}

.main__grid>p,
.main__grid>div {
  border: 1px solid #98da2c;
}

.main__grid__title {
  font-size: 20px;
  font-weight: 500;
  padding: 10px;
  justify-self: center;
  width: 100%;
  text-align: center;
}

.main__list_hollow {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 0 10px;
}

.main__list_hollow>li {
  position: relative;
  padding: 0 0 0 80px;
}

.main__list_hollow>li::before {
  content: "";
  border: #98da2c 1px solid;
  position: absolute;
  border-radius: 50%;
  left: 60px;
  top: 7px;
  width: 6px;
  height: 6px;
}

.working {


  &__container {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #000;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;
  }

  &__block {
    &__title {
      font-weight: 600;
      font-size: 22px;
      line-height: 127%;
      color: #000;
      padding: 0 0 32px 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &>li {
      display: grid;
      grid-template: 1fr / 48px 1fr;
    }

    &__title {
      font-weight: 600;
      font-size: 14px;
      line-height: 157%;
      color: #000;
    }

    &>li.working__list__marked {
      grid-template: 1fr / 72px 1fr;

      &>p {
        position: relative;

        &>svg {
          position: absolute;
          left: 50px;
          top: 9px;
        }
      }
    }
  }
}

@keyframes __fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes __fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes __slideUp {
  from {
    transform: translate(-50%, 120vh);
  }

  to {
    transform: translate(-50%, 0);
  }
}

@keyframes __slideDown {
  from {
    transform: translate(-50%, 0);
  }

  to {
    transform: translate(-50%, 120vh);
  }
}

@keyframes __slideUpClose {
  from {
    transform: translate(50%, 120vh);
  }

  to {
    transform: translate(50%, 0);
  }
}

@keyframes __slideDownClose {
  from {
    transform: translate(50%, 0);
  }

  to {
    transform: translate(50%, 120vh);
  }
}

@media screen and (max-width: 660px) {
  .main__grid__title {
    font-size: 13px;
    padding: 5px;
    border: none !important;
  }

  .main__grid {
    grid-template-columns: minmax(auto, 200px) 1fr;
    border: 1px solid #98da2c;
    overflow: hidden;
  }

  .first {
    margin: -5px 0 0 0 !important;
    border-top: 1px solid #98da2c;
  }

  .main__grid>p,
  .main__grid>div {
    border: none;
  }

  .main__grid>p {
    border-top: 1px solid #98da2c;
  }

  .working__title {
    font-size: 28px;
    margin: 10px 0 0 0
  }

  .working__list {
    gap: 8px;
    padding-left: 20px;
  }

  .working__text {
    margin: 0;
    font-size: 13px;
  }

  .working__block__title {
    font-size: 18px;
    margin: 0 0 32px 0;
    text-align: center;
    padding: 0;
  }

  .working__list>li.working__list__marked>p {
    margin: 0;
  }

  .main__text {
    font-size: 13px;
    padding: 5px;
    margin: 0;
  }
}
</style>