<template>
  <button class="charla__border__button">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "HeaderBorderButton"
}
</script>

<style scoped lang="scss">
  @import "@/scss/mixins";

  .charla__border__button{
    padding: 4px 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 183%;
    letter-spacing: 0px;
    background-color: white;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 20px;
    width: 100%;
    transition: 300ms ease all;
    width: fit-content;

    &:hover{
      background-color: black;
      color: white;
    }
  }
</style>