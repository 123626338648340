<template>
  <div class="block how-it-work" data-animation-trigger="true">
    <div class="block__left" data-animation="true" data-animation-type="move-up">
      <h2 class="block__title">
        <span>
          <span class="block__title__icon">
            <img src="@/assets/img/titleIcon2.svg">
          </span>
          {{ $t("CharlaHowItWork.title_part1") }}
        </span>
        <span class="block__title__colored">{{$t("CharlaHowItWork.title_part2")}}</span>
      </h2>
    </div>
    <div class="block__right" data-animation="true" data-animation-type="move-up">
      <div class="how-it-work__carousel" data-carousel="true" data-hidden-index="0">
        <div class="how-it-work__carousel__wrapper">
          <div class="how-it-work__carousel__item" v-for="slide in carouselData">
            <div class="how-it-work__carousel__item__container">
              <div class="how-it-work__carousel__item__background_white"></div>
              <div class="how-it-work__carousel__item__background_black"></div>
              <div class="how-it-work__carousel__item__icon">
                <span>{{ slide.number }}</span>
                <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 24C0 10.7452 10.7452 0 24 0H48V24C48 37.2548 37.2548 48 24 48H0V24Z" fill="#B561F7" />
                  <path d="M48 0L72 1.04907e-06C58.7452 4.69686e-07 48 10.7453 48 24L48 0Z" fill="#B561F7" />
                  <path d="M2.08074e-06 48L24 48C10.7452 48 5.79382e-07 58.7453 0 72L2.08074e-06 48Z" fill="#B561F7" />
                </svg>
              </div>
              <p class="how-it-work__carousel__item__title" v-html="slide.title"></p>
              <p class="how-it-work__carousel__item__text" v-html="slide.text"></p>
              <img class="how-it-work__carousel__item__screenshot" :src="slide.imgSrc">
            </div>
          </div>
        </div>
        <div class="carousel__button__wrapper">
          <button :class="this.hiddenIndex > 0 ? 'carousel__button__back_active' : 'carousel__button__back_disabled'" class="carousel__button carousel__button__back" @click="scrollCarousel">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="white" />
            </svg>
          </button>
          <button :class="this.hideArrow ? 'carousel__button_disabled' : 'carousel__button_active'" class="carousel__button" @click="scrollCarousel('right')">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="white" />
            </svg>
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import Tr from "@/i18n/translation";

export default {
  name: "CharlaHowItWork",
  data() {
    return {
      hiddenIndex: 0,
      hideArrow: false,
      carouselData: [
        {
          number: '01',
          title: this.$t("CharlaHowItWork.slide1.title"),
          text: this.$t("CharlaHowItWork.slide1.text"),
          imgSrc: 'img/HowItWorkScreen1.png',
        },
        {
          number: '02',
          title: this.$t("CharlaHowItWork.slide2.title"),
          text: this.$t("CharlaHowItWork.slide2.text"),
          imgSrc: 'img/HowItWorkScreen2.png',
        },
        {
          number: '03',
          title: this.$t("CharlaHowItWork.slide3.title"),
          text: this.$t("CharlaHowItWork.slide3.text"),
          imgSrc: 'img/HowItWorkScreen3.png',
        },
        {
          number: '04',
          title: this.$t("CharlaHowItWork.slide4.title"),
          text: this.$t("CharlaHowItWork.slide4.text"),
          imgSrc: 'img/HowItWorkScreen4.png',
        },
        {
          number: '05',
          title: this.$t("CharlaHowItWork.slide5.title"),
          text: this.$t("CharlaHowItWork.slide5.text"),
          imgSrc: 'img/HowItWorkScreen5.png',
        },
      ]
    }
  },
  computed: {
    Tr() {
      return Tr
    },
  },
  methods: {
    scrollCarousel(direction='left'){
      const carouselButtons = [...document.querySelectorAll('.how-it-work .carousel__button')]
      carouselButtons.forEach(e => e.style.pointerEvents = 'none')
      
      setTimeout(() => {
        carouselButtons.forEach(e => e.style.pointerEvents = 'all')
      }, 500)

      const carouselItems = [...document.querySelectorAll('.how-it-work__carousel__item')]
      const carouselItemsContainer = [...document.querySelectorAll('.how-it-work__carousel__item__container')]
      if (direction === 'right'){
        carouselItemsContainer.forEach((e,i) => {
          if (i === this.hiddenIndex){
            e.classList.add('disappear')
          } else if (i !== this.hiddenIndex && !e.classList.contains('disappear')){
            carouselItems[i].style.transition = '500ms transform ease'
            carouselItems[i].style.transform = `translate(calc(-${100 * (this.hiddenIndex + 1)}% - ${16 * (this.hiddenIndex + 1)}px), 0)`
          }
        })

        this.hiddenIndex += 1
      } else {
        this.hiddenIndex -= 1

        carouselItemsContainer.forEach((e, i) => {
          if (i === this.hiddenIndex){
            e.classList.add('appear')
            setTimeout(() => {
              e.classList.remove('disappear')
              e.classList.remove('appear')
            }, 500)
          } else if (i !== this.hiddenIndex && !e.classList.contains('disappear')) {
            carouselItems[i].style.transition = '500ms transform ease'
            carouselItems[i].style.transform = `translate(calc(-${100 * (this.hiddenIndex)}% - ${16 * (this.hiddenIndex)}px), 0)`
          }
        })
      }

      const carousel = document.querySelector('.how-it-work__carousel');
      carousel.dataset.hiddenIndex = this.hiddenIndex
    }
  },
  mounted() {
    const splideContainer= document.querySelector('.splide')

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          splideContainer.dataset.stopScroll = 'false'
          this.hideArrow = true
        } else {
          splideContainer.dataset.stopScroll = 'true'
          this.hideArrow = false
        }
      })
    }, {
      threshold: 1
    });

    let carouselItem = [...document.querySelectorAll('.how-it-work__carousel__item__container')];
    carouselItem = carouselItem[carouselItem.length - 1]
    observer.observe(carouselItem)

    const carousel = document.querySelector('.how-it-work__carousel')
    this.hiddenIndex = +carousel.dataset.hiddenIndex


    const config = {
      attributes: true,
    };
    const callback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes'){
          this.hiddenIndex = +carousel.dataset.hiddenIndex
        }
      })
    }

    const mutationObserver = new MutationObserver(callback)
    mutationObserver.observe(carousel, config)
  }
}
</script>

<style scoped lang="scss">
  .how-it-work{
    overflow: hidden;
  }
  .block__title__icon{
    background-color: var(--green);
  }

  .how-it-work__slogan{
    font-weight: 600;
    font-size: 22px;
    line-height: 127%;
    color: #000;

    display: flex;
    align-items: center;
    gap: 5px;

    & > b{
      color: var(--green);
      font-size: 30px;
      font-weight: 700;
    }
  }

  .block__title__colored{
    color: #ff6332;
  }

  .block__left{
    position: relative;
  }

  .block__right{
    position: relative;
    display: flex;
    align-items: center;
  }

  .how-it-work__carousel{
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translate(0, -50%);
    transition: 400ms left ease;

    &__wrapper{
      display: flex;
      gap: 16px;
      position: relative;
    }

    &__item{
      width: 360px;
      height: 396px;
      display: grid;
      place-items: center;
      // background: #fff;
      border-radius: 32px;
      padding: 6px 8px;
      position: relative;

      &__title{
        position: relative;
        z-index: 4;
        font-weight: 600;
        font-size: 18px;
        line-height: 111%;
        color: #fff;
        padding: 44px 36px 16px;
      }

      &__text{
        font-weight: 400;
        position: relative;
        z-index: 4;
        font-size: 12px;
        line-height: 150%;
        color: #fff;
        padding: 0 36px;

      }

      &__container{
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #000;
        border-radius: 32px;
        z-index: 2;
      }

      &__background{
        &_white{
          position: absolute;
          z-index: 1;
          top: -6px;
          left: -8px;
          width: calc(100% + 16px);
          height: calc(100% + 12px);
          border-radius: 32px;
          background-color: #fff;
        }

        &_black{
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 32px;
          background-color: #000;
        }
      }

      &__wrapper{
        position: relative;
        width: 100%;
        height: 100%;
        background: #fff;

      }

      &__screenshot{
        object-fit: contain;
        position: absolute;
        left: 0;
        top: 0;
        z-index:3;
        width: 100%;
        height: 100%;
      }

      &__icon{
        position: absolute;
        left: -8px;
        z-index: 4;
        top: -8px;

        & span{
          position: absolute;
          left: 12px;
          top: 15px;
          font-weight: 400;
          font-size: 16px;
          line-height: 137%;
          color: #fff;
        }
      }
    }
  }
  .how-it-work__carousel__item__border-top{
    width: 100%;
  }
  .step-title{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    height: 48px;
    text-align: center;
    color: #fff;
    background: #B561F6;
    box-sizing: border-box;
    top: -8px;
    z-index: 10;
  }
  .step-title span{
    position: relative;
    z-index: 10;
  }
  .screen-icon{
    position: absolute;
    top: 14px;
    left: 8px;
  }
  .step-title:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 30px;
    background: #B561F6;
    z-index: -1;
    top: 40px;
  }

  .block__text{
    max-width: 380px;
    width: 100%;
  }

  @media screen and (max-width: 1600px) {
    .block__title{
      font-size: 40px;
    }
  }

  @media screen and (max-width: 1000px){
    .how-it-work__carousel__item{
      width: 300px;
      height: 336px;
    }

    .how-it-work__carousel__item__title{
      font-size: 16px;
      padding: 32px 36px 16px;
    }

    .how-it-work__carousel__item__text{
      font-size: 10px;
    } 

    .carousel__button__wrapper {
      width: 420px;
    }
  }
  @media screen and (max-width: 900px) {
    .block__title{
      font-size: 34px;
    }

    .carousel__button__wrapper {
      width: 360px;
    }
  }
  @media screen and (max-width: 770px){
    .block__title{
      font-size: 30px;
    }

    .carousel__button__wrapper {
      width: 320px;
    }
  }


</style>