import Splide from '@splidejs/splide';
import { MyTransition } from "@/js/SplideCustomTransition.js";
import store from "@/store";


document.addEventListener('DOMContentLoaded', function () {

    const splide = new Splide('.splide', {
        direction: "ttb",
        height: '100vh',
        type: 'slider',
        focus: 'center',
        wheel: true,
        // snap: true,
        waitForTransition: true,
        wheelSleep: 1000,
        drag: false,
        // paginationDirection: 'ttb',
        // keyboard: true,
        perPage: 1,
        wheelMinThreshold: 99,
        perMove: 1,
        speed: 500,
        // updateOnMove: true
    });


    document.addEventListener('keyup', (key) => {
        const { Move, Controller } = splide.Components
        let currentSlide = Controller.getIndex()

        // Стрелка вверх
        if (key.keyCode === 38 && currentSlide !== 0) {
            currentSlide -= 1
            Controller.go(currentSlide)
        }

        // Стрелка вниз
        if (key.keyCode === 40 && currentSlide !== Controller.getEnd()) {
            currentSlide += 1
            Controller.go(currentSlide)
        }

    })

    const splideContainer = document.querySelector('.splide')
    const bullets = [...document.querySelectorAll('.aside__bullets > ul > li')]



    bullets.forEach((e, i) => {
        e.onclick = () => {
            const { Move, Controller } = splide.Components
            splideContainer.dataset.footerIndex = `${i}`

            if (Controller.getIndex() !== i) {
                splideContainer.dataset.hardMove = 'true'
                splideContainer.dataset.destinationIndex = `${i}`

                if (i > Controller.getIndex()) {
                    splideContainer.dataset.moveDirection = 'up'
                } else {
                    splideContainer.dataset.moveDirection = 'down'
                }

                Move.move(i, i, Controller.getIndex())
            }
        }
    });

    const burgerBullets = [...document.querySelectorAll('.burger-menu__links__navigation__items')];
    burgerBullets.forEach((e, i) => {
        e.onclick = () => {
            const { Move, Controller } = splide.Components
            splideContainer.dataset.footerIndex = `${i}`

            if (Controller.getIndex() !== i) {
                splideContainer.dataset.hardMove = 'true'
                splideContainer.dataset.destinationIndex = `${i}`

                if (i > Controller.getIndex()) {
                    splideContainer.dataset.moveDirection = 'up'
                } else {
                    splideContainer.dataset.moveDirection = 'down'
                }

                Move.move(i, i, Controller.getIndex())
            }
        }
    })

    const headerNavigation = [...document.querySelectorAll('.header__nav__group > button')]
    headerNavigation.forEach((e, i) => {
        e.onclick = () => {
            // let headerIndex = [4, 5, 6, 7, 8, 9]
            splideContainer.dataset.stopScroll = 'false'
            splideContainer.dataset.footerIndex = `${e.dataset.slideNumber}`

            const { Move, Controller } = splide.Components
            if (+e.dataset.slideNumber !== Controller.getIndex()) {
                splideContainer.dataset.destinationIndex = e.dataset.slideNumber
                splideContainer.dataset.hardMove = 'true'
                Move.move(+e.dataset.slideNumber, +e.dataset.slideNumber, Controller.getIndex())
            }
        }
    })

    const nextPageButton = document.querySelector('.footer__next-page__button');
    nextPageButton.classList.add('footer__next-page__button_closed')
    nextPageButton.classList.add('footer__next-page__button_opened')

    setTimeout(() => {
        nextPageButton.classList.remove('footer__next-page__button_closed')
    }, 200)

    nextPageButton.onclick = () => {
        splideContainer.dataset.stopScroll = 'false'

        const { Move, Controller } = splide.Components
        if (Controller.getIndex() !== Controller.getEnd() && splideContainer.dataset.sleep !== 'true' && store.getters.getCurrentPos !== 8) {
            splideContainer.dataset.sleep = 'true'
            Controller.go(Controller.getIndex() + 1)

            setTimeout(() => {
                splideContainer.dataset.sleep = 'false'
            }, 1000)
        }

        if (store.getters.getCurrentPos === 8) {
            splideContainer.dataset.hardMove = 'true'
            splideContainer.dataset.destinationIndex = `0`
            splideContainer.dataset.moveDirection = 'up'
            splideContainer.dataset.footerIndex = `0`
            Move.move(0, 0, 9)
        }
    }

    const headerTopButton = document.querySelector('.header__logo')
    headerTopButton.onclick = () => {
        splideContainer.dataset.stopScroll = 'false'

        const { Move, Controller } = splide.Components
        if (Controller.getIndex() !== 0) {
            splideContainer.dataset.hardMove = 'true'
            splideContainer.dataset.destinationIndex = `0`
            splideContainer.dataset.moveDirection = 'up'
            splideContainer.dataset.footerIndex = `0`
            Move.move(0, 0, Controller.getIndex())
        }
    }

    splide.mount({}, MyTransition);
});
