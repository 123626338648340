
<template>
  <footer class="footer">
    <div class="footer__wrapper">
      <nav class="footer__slogan">
          <p v-html="$t('CharlaLandingBlock.info1')"></p>
        <!--        <p class="footer__next-page__text" v-if="this.getCurrentPos === 0">{{ $t("footer.title") }}</p>-->
      </nav>
      <a class="footer__start" :href="$t('charlaRegLink')" @click="click__new_id48(); sendMetrik('click__new_id48')" target="_blank">
        <span class="footer__start__wrapper">
          <button class="footer__start__content" >
            Тестировать сейчас
          </button>
        </span>
      </a>
      <div class="footer__next-page" @click="moveToNextSlide">
        <div class="footer__next-page__button">
          <div class="footer__next-page__button__wrapper">
            <div class="footer__next-page__button__content" v-for="(item, i) in tellText" :key="i">
              <p v-if="this.getCurrentPos === i">{{ item }}</p>
            </div>
          </div>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.42 16H6.58L0 9.42L1.42 8L7 13.58L7 0L9 8.74224e-08V13.58L14.58 8L16 9.42L9.42 16Z"
              fill="black" />
          </svg>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {mapGetters} from "vuex";
import Tr from "@/i18n/translation";
import { useGtm } from '@gtm-support/vue-gtm';
import { sendMetrik } from "@/utils/metrica";



export default {
  name: "CharlaFooter",
  computed: {
    ...mapGetters(['getCurrentPos']),
    Tr() {
      return Tr
    },
    tellText(){
      return [
        this.$t("footer.screen_name1"),
        this.$t("footer.screen_name2"),
        this.$t("footer.screen_name3"),
        this.$t("footer.screen_name4"),
        this.$t("footer.screen_name5"),
        this.$t("footer.screen_name6"),
        this.$t("footer.screen_name7"),
        this.$t("footer.screen_name8"),
        this.$t("footer.screen_name9"),
        this.$t("footer.screen_name10")
      ]
    }
  },
  methods: {
    moveToNextSlide(){

    },
    sendMetrik: sendMetrik,
    click__new_id48(){
      const gtm = useGtm()
      gtm.dataLayer().push({
        'event':'click__new_id48',
        'eventCategory':'click',
        'eventAction':'',
        'eventLabel':'new_id48'
      })
    },
    click_linkTg_new_id4(){
      const gtm = useGtm()
      gtm.dataLayer().push({
        'event':'click_link-tg_new_id4',
        'eventCategory':'click',
        'eventAction':'link-tg',
        'eventLabel':'new_id4'
      })
    }
  },
  mounted() {

  },
  data() {
    return {
    }
  },
}
</script>
<style scoped lang="sass">
.ru-locale
  .footer__next-page__button
    font-size: 12px
    white-space: normal
    text-align: center
</style>
<style scoped lang="scss">

  .footer{
    position: fixed;
    left: 0;
    bottom: 24px;
    width: 100%;
    z-index: 1;

    &__wrapper{
      width: 50vw;
      display: flex;
      justify-content: space-between;
      padding: 0 0 0 200px;
      position: relative;
      align-items: center;
    }

    &__start{
      white-space: nowrap;
      position: absolute;
      left: 200px;
      display: grid;
      grid-template-columns: 0fr;
      transition: 500ms ease ;


      &__wrapper{
        overflow: hidden;
      }

      &__content{
        font-weight: 600;
        font-size: 16px;
        line-height: 137%;
        color: #fff;
        border-radius: 24px;
        background: #ff6332;
        padding: 16px 24px;
        transition: 300ms ease background-color;

        &:hover{
          background-color: var(--purple);
        }
      }

      &_active{
        grid-template-columns: 1fr;
      }

      &__content{

      }


      &__text{
        font-weight: 600;
        font-size: 14px;
        line-height: 157%;
        color: #fff;
      }
    }

    &__slogan{
        font-weight: 400;
        font-size: 8px;
        line-height: 14px;
        color: #000;
        max-width: 446px;
        width: 100%;
        opacity: 1;
        transition: 200ms ease opacity;

        &_active{
          opacity: 0;
        }
    }

    &__next-page{
      position: relative;
      white-space: nowrap;
      z-index: 1;
      display: flex;
      align-items: center;
      gap: 16px;
      transform: translate(84px, 0);

      &__text{
        font-weight: 400;
        font-size: 12px;
        user-select: none;
      }

      &__button{
        font-weight: 600;
        font-size: 14px;
        padding: 13px 24px;
        background-color: white;
        border: 1px solid #000;
        border-radius: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: fit-content;
        white-space: nowrap;
        transition: 400ms ease all;
        user-select: none;
        overflow: hidden;
        height: 44px;

        &  p{
          white-space: nowrap;
        }

        &:hover{
          background-color: black;
          color: white;
        }

        & svg path {
          transition: 400ms ease fill;
        }

        &:hover svg path{
          fill: white;
        }

        & > svg{
          transition: 300ms all ease;
        }

        &_opened{
          gap: 24px;

          &  .footer__next-page__button__wrapper{
            grid-template-rows: 1fr;
            grid-template-columns: 1fr;
          }
        }

        &_closed{
          gap: 0;

          &  .footer__next-page__button__wrapper{
            grid-template-rows: 0fr;
            grid-template-columns: 0fr;
          }
        }

        &__wrapper{
          display: grid;
          grid-template-rows: 0fr;
          grid-template-columns: 0fr;
          transition: 300ms all ease;


        }

        &__content{
          overflow: hidden;
        }
      }
    }
  }

  .rotate__arrow{
    transform: rotate(180deg);
  }


  @keyframes __fullWidth {
    from{
      max-width: 0;
      padding: 0;
    }
    to{
      max-width: 100%;
      padding: 13px 24px;
    }

  }

  @media screen and (max-width: 1600px) {
    .footer__wrapper{
      padding: 0 0 0 168px;
      height: 54px;
    }

    .footer__start{
      left: 168px;
    }

    .footer__slogan{
      max-width: 370px;
    }

    .footer__next-page{
      justify-self: flex-end;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(84px, -50%);
    }
  }


  @media screen and (max-width: 1440px){
    .footer__wrapper{
      padding: 0 0 0 56px;
    }

    .footer__start{
      left: 56px;
    }
  }
  @media screen and (max-width: 1300px){
    .footer__next-page__button_opened {
      gap: 10px;
    }
    .footer__next-page__button {
      font-size: 13px;
      white-space: normal;
    }

    .footer__start__content{
      font-size: 13px;
      padding: 14px 24px;
    }
  }

  @media screen and (max-width: 1240px){
    .footer__next-page__button__content{
      font-size: 10px;
    }

    .footer__next-page__button{
      padding: 8px 16px;

      & > svg{
        width: 10px;
        height: 10px;
      }
    }
  }
  @media screen and (max-width: 1100px){
    .footer__wrapper {
      padding: 0 0 0 24px ;
    }
    .footer__start{
      left: 24px;
    }
  }

  @media screen and (max-width: 1060px){
      .footer__slogan{
      max-width: 300px;
    }
  }
  
  @media screen and (max-width: 950px){
    .footer__next-page{
      transform: translate(116px, -50%);
    }
  }

  @media screen and (max-width: 880px){
    .footer__slogan{
      font-size: 8px;
      max-width: 250px;
    }
  }
</style>