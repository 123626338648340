<template>
  <div class="block documents" data-animation-trigger="true">
    <div class="block__left" data-animation="true" data-animation-type="move-up">
      <h2 class="block__title">
        <span><span class="block__title__icon">
          <img src="@/assets/img/titleIcon11.svg">
        </span>Документы</span>
        <span>и информация</span>
      </h2>
      <nav class="documents__navigation">
        <div class="documents__navigation__item" @click="setFunctionsState(true)">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 9.42L16 6.58L9.42 -2.87621e-07L8 1.42L13.58 7L-3.0598e-07 7L-3.93402e-07 9L13.58 9L8 14.58L9.42 16L16 9.42Z" fill="#B561F7"/>
          </svg>
          Функциональные характеристики, системные требования
        </div>
        <a href="/Инструкция-по-эксплуатации.pdf" target="_blank" class="documents__navigation__item" >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 9.42L16 6.58L9.42 -2.87621e-07L8 1.42L13.58 7L-3.0598e-07 7L-3.93402e-07 9L13.58 9L8 14.58L9.42 16L16 9.42Z" fill="#B561F7"/>
          </svg>
          Инструкция по эксплуатации
        </a>
        <div class="documents__navigation__item" @click="setAboutState(true)">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 9.42L16 6.58L9.42 -2.87621e-07L8 1.42L13.58 7L-3.0598e-07 7L-3.93402e-07 9L13.58 9L8 14.58L9.42 16L16 9.42Z" fill="#B561F7"/>
          </svg>
          О компании
        </div>
        <div class="documents__navigation__item"  @click="setWorkingPopup(true);">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 9.42L16 6.58L9.42 -2.87621e-07L8 1.42L13.58 7L-3.0598e-07 7L-3.93402e-07 9L13.58 9L8 14.58L9.42 16L16 9.42Z" fill="#B561F7"/>
          </svg>
          Условия работы
        </div>
        <div class="documents__navigation__item" @click="setTermsState(true)">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 9.42L16 6.58L9.42 -2.87621e-07L8 1.42L13.58 7L-3.0598e-07 7L-3.93402e-07 9L13.58 9L8 14.58L9.42 16L16 9.42Z" fill="#B561F7"/>
          </svg>
          Публичная оферта
        </div>
        <div class="documents__navigation__item" @click="setPrivacyState(true)">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 9.42L16 6.58L9.42 -2.87621e-07L8 1.42L13.58 7L-3.0598e-07 7L-3.93402e-07 9L13.58 9L8 14.58L9.42 16L16 9.42Z" fill="#B561F7"/>
          </svg>
          Политика конфиденциальности
        </div>
        <div class="documents__navigation__item" @click="setCookiePopup(true)">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 9.42L16 6.58L9.42 -2.87621e-07L8 1.42L13.58 7L-3.0598e-07 7L-3.93402e-07 9L13.58 9L8 14.58L9.42 16L16 9.42Z" fill="#B561F7"/>
          </svg>
          Политика работы с Cookies
        </div>
      </nav>
    </div>
    <div class="block__right" data-animation="true" data-animation-type="move-up">
 
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Tr from '@/i18n/translation'
export default {
  name: "CharlaDocuments",
  data() {
    return {
      hiddenIndex: 0,
      hideArrow: false
    }
  },
  setup(){
    return{Tr}
  },
  methods: {
    ...mapActions(['getNewFormState', 'setPrivacyState', 'setTermsState', 'setAboutState', 'setFormState', 'setFaqState', 'setFaqContent', 'setWorkingPopup', 'setCookiePopup', 'setFunctionsState']),
  }
}
</script>

<style scoped lang="scss">
  .block__title__icon{
    background-color: var(--green);
    padding: 10px;

    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  .block__right{
    position: relative;
  }

  .documents__slogan{
    max-width: 368px;
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;
  }
  .documents__buttons{
    display: flex;
    width: fit-content;
    gap: 8px;
    transition: .3s ease;

    &__ask{
      font-weight: 600;
      font-size: 14px;
      line-height: 157%;
      color: #fff;
      border-radius: 24px;
      padding: 13px 24px;
      background-color: black;
      transition: .3s ease;
    }

    &__chat{
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid #000;
      transition: .3s ease;
    }
  }
  .documents__buttons:hover{
    gap: 0;
  }
  .documents__buttons:hover .documents__buttons__ask{
    background: #B561F6;
    border-radius: 24px 0 0 24px;
  }
  .documents__buttons:hover .documents__buttons__chat{
    background: #B561F6;
    border-color: #B561F6;
    border-radius: 0 24px 24px 0;
  }
  .documents__buttons .documents__buttons__chat svg path{
    transition: .3s ease;
  }
  .documents__buttons:hover .documents__buttons__chat svg path{
    fill: #fff;
  }

  .documents__navigation{
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-wrap: wrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;

    &__item{
      color: #000;
      cursor: pointer;
      position: relative;
      transition: 300ms ease all;
      display: flex;
      align-items: center;
      gap: 16px;

      &:hover{
        color: var(--purple);
      }
    }
  }

  .doc__carousel{
    position: absolute;
    left: -44px;
    top: 50%;
    transform: translate(0, -50%);

    &__wrapper{
      display: flex;
      gap: 16px;
      position: relative;
      overflow: hidden;
    }

    &__item{
      border-radius: 32px;
      padding: 32px;
      width: 100%;
      height: 100%;
      background-color: white;
      display: grid;
      grid-template: 1fr / auto 1fr;
      cursor: pointer;
      box-shadow: inset 8px 0 0 0 var(--purple);


      &__wrapper{
        width: 360px;
        height: 360px;
        display: grid;
        place-items: center;
      }

      &__container{
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        height: 100%;
      }

      & > svg{
        justify-self: flex-end;
      }

      &__title{
        max-width: 234px;
        width: 100%;
        font-weight: 600;
        font-size: 14px;
        line-height: 157%;
        color: #000;
      }
    }
  }

  @media  screen and (max-width: 1850px){
    .documents__navigation {
      flex-direction: column;
      gap: 10px;
    }
  }

  @media screen and (max-width: 1300px) {
    .block__title{
      font-size: 36px;
    }
  }

  @media screen and (max-width: 720px){
    .block__title{
      font-size: 32px;
    }
  }
</style>