import i18n from "@/i18n/index.js";

const Translation = {
    get defaultLocale() {
        return 'ru'
    },

    get supportedLocales() {
        return ['ru']
    },

    get currentLocale() {
        return i18n.global.locale.value
    },

    set currentLocale(newLocale) {
        i18n.global.locale.value = newLocale
    },

}

export default Translation