import { EventInterface } from '@splidejs/splide';
import store from "@/store";
import { createLogger } from "vuex";

export function MyTransition(Splide, Components) {
    const { bind } = EventInterface(Splide);
    const { Move } = Components;
    const { list } = Components.Elements;
    const { Controller } = Splide.Components
    let endCallback;
    let x = -0
    const splideContainer = document.querySelector('.splide');
    const nextPageButton = document.querySelector('.footer__next-page__button');
    const nextPageButtonArrow = document.querySelector('.footer__next-page__button > svg')

    let tryNew = -50;
    function mount() {
        bind(list, 'transitionend', e => {
            if (e.target === list && endCallback) {
                // Removes the transition property
                cancel();
                endCallback();
            }
        });
    }

    function start(index, done) {
        Splide.options.wheelSleep = 200
        splideContainer.dataset.moveDirection = 'down'

        if (splideContainer.dataset.stopScroll === 'false' && store.getters.getCurrentPos !== 9 ||
            splideContainer.dataset.stopScroll === 'false' && store.getters.getCurrentPos === 9 && getWheelDirection(index, store.getters.getCurrentPos) === 'up' ||
            splideContainer.dataset.stopScroll === 'true' && getWheelDirection(index, store.getters.getCurrentPos) === 'up') {
            Splide.options.wheelSleep = 1000
            const destination = Move.toPosition(index, true);
            let scrollDirection = 'down'
            list.style.transition = 'transform 100ms ease';


            let currentSlide = store.getters.getCurrentPos

            if (x > destination) {
                x = destination
                splideContainer.dataset.moveDirection = 'down'
            } else {
                x = destination
                splideContainer.dataset.moveDirection = 'up'
            }

            if (splideContainer.dataset.moveDirection === 'down') {
                list.children[currentSlide].querySelector('.block__left').style.transition = 'all 500ms ease 0ms '
                list.children[currentSlide].querySelector('.block__right').style.transition = 'all 500ms ease 0ms'

                list.children[currentSlide].querySelector('.block__left').style.transform = 'translate(0, -100vh)'
                list.children[currentSlide].querySelector('.block__right').style.transform = 'translate(0, -100vh)'

                list.children[currentSlide].querySelector('.block__left').style.opacity = '0'
                list.children[currentSlide].querySelector('.block__right').style.opacity = '0'
            }

            if (splideContainer.dataset.moveDirection === 'up') {
                currentSlide = Controller.getIndex() + 1

                if (splideContainer.dataset.hardMove === 'true') {
                    currentSlide = Controller.getIndex()
                }

                list.children[currentSlide].querySelector('.block__left').style.transition = 'transform 500ms ease 0ms '
                list.children[currentSlide].querySelector('.block__right').style.transition = 'transform 500ms ease 0ms'

                list.children[currentSlide].querySelector('.block__left').style.transform = 'translate(0, 100vh)'
                list.children[currentSlide].querySelector('.block__right').style.transform = 'translate(0, 100vh)'
            }

            nextPageButton.classList.add('footer__next-page__button_closed')

            setTimeout(() => {
                Move.translate(destination);
            }, 400)

            endCallback = done;

        } else if (splideContainer.dataset.stopScroll === 'true' && splideContainer.dataset.typeScrollAnimation === 'rightScroll') {
            Splide.options.wheelSleep = 300

            const noMoveIndex = store.getters.getCurrentPos
            Controller.setIndex(noMoveIndex)

            const landingCarousel = document.querySelector('.landing__carousel');
            const translatePosition = +landingCarousel.dataset.translatePosition + 33.33
            landingCarousel.dataset.translatePosition = `${translatePosition}`
            landingCarousel.style.transform = `translate(${translatePosition}%, 0)`

            done();
        } else if (splideContainer.dataset.stopScroll === 'true' && splideContainer.dataset.typeScrollAnimation === 'leftScroll') {
            Splide.options.wheelSleep = 200

            const noMoveIndex = store.getters.getCurrentPos
            Controller.setIndex(noMoveIndex)
            const compareCarousel = document.querySelector('.compare__carousel');
            const compareCarouselItems = [...compareCarousel.children]

            if (splideContainer.dataset.switch === "true") {
                Splide.options.wheelSleep = 700
            } else {
                const translatePosition = +compareCarousel.dataset.translatePosition + 1
                compareCarousel.dataset.translatePosition = `${translatePosition}`
                Splide.options.wheelSleep = 400
                compareCarouselItems.forEach(e => {
                    e.style.transform = `translate(calc(-${translatePosition * 100}% - ${16 * translatePosition}px), 0)`
                })
            }

            // compareCarousel.style.transform = `translate(${translatePosition}%, 0)`

            done();
        } else if (splideContainer.dataset.stopScroll === 'true' && splideContainer.dataset.typeScrollAnimation === 'centerScroll') {
            Splide.options.wheelSleep = 200

            const noMoveIndex = store.getters.getCurrentPos
            Controller.setIndex(noMoveIndex)

            const priceCarousel = document.querySelector('.price__carousel');
            const priceTitle = document.querySelector('.prices .block__title');

            const translatePosition = priceCarousel.getBoundingClientRect().width / 2
            priceCarousel.style.left = `-${translatePosition}px`
            priceTitle.style.opacity = 0;
            // compareCarousel.style.transform = `translate(${translatePosition}%, 0)`

            done();
        } else if (splideContainer.dataset.stopScroll === 'true' && splideContainer.dataset.typeScrollAnimation === 'default') {
            Splide.options.wheelSleep = 400

            const currentSlideCarousel = list.children[store.getters.getCurrentPos].querySelector('[data-carousel*="true"]')
            const currentSlideCarouselItems = [...currentSlideCarousel.children[0].children]

            const noMoveIndex = store.getters.getCurrentPos
            Controller.setIndex(noMoveIndex)

            let hiddenIndex = +currentSlideCarousel.dataset.hiddenIndex;
            if (splideContainer.dataset.switch === "true") {
                Splide.options.wheelSleep = 700
            } else {
                Splide.options.wheelSleep = 400

                currentSlideCarouselItems.forEach((e, i) => {
                    if (i === hiddenIndex) {
                        e.children[0].classList.add('disappear')
                    } else if (i !== this.hiddenIndex && !e.children[0].classList.contains('disappear')) {
                        e.style.transition = '500ms transform ease'
                        e.style.transform = `translate(calc(-${100 * (hiddenIndex + 1)}% - ${16 * (hiddenIndex + 1)}px), 0)`
                    }
                })
                currentSlideCarousel.dataset.hiddenIndex = `${hiddenIndex + 1}`
            }

            done();
        } else if (splideContainer.dataset.stopScroll === 'false' && store.getters.getCurrentPos === 9 && getWheelDirection(index, store.getters.getCurrentPos) === 'down') {
            const noMoveIndex = store.getters.getCurrentPos
            Controller.setIndex(noMoveIndex)
            done();
        }

        // const footerText = document.querySelector('.footer__next-page__text');
        const footerButton = document.querySelector('.footer__start');
        const footerSlogan = document.querySelector('.footer__slogan')
        // const langChoose = document.querySelector('.aside__lang')
        // if (Controller.getIndex() >= 1 && window.innerWidth < 1600 ){
        //     footerText.style.display = 'none'
        // } else if (Controller.getIndex() === 0 && window.innerWidth < 1600 && !+splideContainer.dataset.footerIndex){
        //     setTimeout(() => {
        //         footerText.style.display = 'block'
        //     }, 600)
        // }
        //
        // if (+splideContainer.dataset.footerIndex >= 1 && window.innerWidth < 1600){
        //     footerText.style.display = 'none'
        // } else if (+splideContainer.dataset.footerIndex === 0 && window.innerWidth < 1600){
        //     setTimeout(() => {
        //         footerText.style.display = 'block'
        //     }, 600)
        // }

        if (Controller.getIndex() >= 1) {
            footerButton.classList.add('footer__start_active')
            footerSlogan.classList.add('footer__slogan_active')
        } else {
            footerButton.classList.remove('footer__start_active')
            footerSlogan.classList.remove('footer__slogan_active')
        }

        if (Controller.getIndex() >= 1 && Controller.getIndex() < 9) {
            footerButton.classList.add('footer__start_active')
            footerSlogan.classList.add('footer__slogan_active')
        } else {
            footerButton.classList.remove('footer__start_active')
            footerSlogan.classList.remove('footer__slogan_active')
        }

        if (+splideContainer.dataset.footerIndex >= 1 && +splideContainer.dataset.footerIndex < 9) {
            footerButton.classList.add('footer__start_active')
            footerSlogan.classList.add('footer__slogan_active')
        } else if (+splideContainer.dataset.footerIndex || +splideContainer.dataset.footerIndex === 0 || +splideContainer.dataset.footerIndex === 9) {
            footerButton.classList.remove('footer__start_active')
            footerSlogan.classList.remove('footer__slogan_active')
        }
    }

    function cancel() {
        list.style.transition = '';
        const splideContainer = document.querySelector('.splide')

        let currentSlide = store.getters.getCurrentPos

        if (splideContainer.dataset.hardMove === 'true') {
            Controller.setIndex(+splideContainer.dataset.destinationIndex)
            delete splideContainer.dataset.hardMove
            delete splideContainer.dataset.destinationIndex
            delete splideContainer.dataset.footerIndex
        }

        store.dispatch('getNewPosition', Controller.getIndex())

        list.children[currentSlide].querySelector('.block__left').style.transition = ''
        list.children[currentSlide].querySelector('.block__right').style.transition = ''

        // list.children[currentSlide].querySelector('.block__left').style.transform = ''
        // list.children[currentSlide].querySelector('.block__right').style.transform = ''
        nextPageButton.classList.remove('footer__next-page__button_closed')

        if (Controller.getIndex() === 8) {
            nextPageButtonArrow.classList.add('rotate__arrow')
        } else {
            nextPageButtonArrow.classList.remove('rotate__arrow')
        }

        nextPageButton.classList.add('footer__next-page__button_opened')
    }

    function getWheelDirection(deservedIndex, currentIndex) {
        if (deservedIndex > currentIndex) {
            return 'down'
        } else {
            return 'up'
        }
    }

    return {
        mount,
        start,
        cancel,
    };
}