<template>
  <div class="block" data-animation-trigger="true">
    <div class="block__left" data-animation="true" data-animation-type="move-up">
      <h2 class="block__title">
        <span>
          <span class="block__title__icon">
             <img src="@/assets/img/titleIcon4.svg">
          </span>
          {{ $t("CharlaStatistics.title_part1") }}
        </span>
        <span v-html='$t("CharlaStatistics.title_part2")'></span>
      </h2>
    </div>
    <div class="block__right" data-animation="true" data-animation-type="move-up">
      <div class="statistic__carousel" data-carousel="true" data-hidden-index="0">
        <div class="statistic__carousel__wrapper">
          <div class="statistic__carousel__item">
            <div class="statistic__carousel__item__container statistic__carousel__item_purple">
              <p class="statistic__carousel__item__title">AI-функции</p>
              <ul class="statistic__carousel__item__list">
                <li class="statistic__carousel__item__text">Добавление тайм-кодов</li>
                <li class="statistic__carousel__item__text">Подготовка субтитров</li>
                <li class="statistic__carousel__item__text">Подготовка краткого содержания транскрибируемого файла
                  (суммаризация)
                </li>
                <li class="statistic__carousel__item__text">Подготовка статьи для публикации<br> в соцсетях на основе
                  расшифрованного файла
                </li>
                <li class="statistic__carousel__item__text">Перевод полученной расшифровки<br> на нужный язык</li>
              </ul>
            </div>
          </div>

          <div class="statistic__carousel__item ">
            <div class="statistic__carousel__item__container statistic__carousel__item_red">
              <p class="statistic__carousel__item__title">Расшифровка в текст аудио- и видеофайлов любого веса<br> и
                продолжительности</p>
              <p class="statistic__carousel__item__text">Тестовый период 1 день для веб-версии<br> и 20 000₽ — для
                интеграции по API. Специальные условия для крупных проектов</p>
            </div>
          </div>

          <div class="statistic__carousel__item ">
            <div class="statistic__carousel__item__container statistic__carousel__item_green">
              <p class="statistic__carousel__item__title">Доступные способы загрузки файлов</p>
              <ul class="statistic__carousel__item__list">
                <li class="statistic__carousel__item__text">Напрямую с устройства</li>
                <li class="statistic__carousel__item__text statistic__carousel__item__socials">По ссылке на аудио- и видеофайлы:
                  <span>
                      <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1_1710)">
                          <rect x="4.94006" y="4" width="14.8235" height="8" fill="white"/>
                          <mask id="mask0_1_1710" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="-1" y="0"
                                width="99" height="17">
                            <path d="M97.3681 0H-0.127441V16.0176H97.3681V0Z" fill="white"/>
                          </mask>
                          <g mask="url(#mask0_1_1710)">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M4.72278 1.12595C3.52881 2.2519 3.52881 4.06409 3.52881 7.68847V8.32917C3.52881 11.9536 3.52881 13.7657 4.72278 14.8917C5.91675 16.0176 7.83842 16.0176 11.6817 16.0176H12.3612C16.2045 16.0176 18.1261 16.0176 19.3201 14.8917C20.5141 13.7657 20.5141 11.9536 20.5141 8.32917V7.68847C20.5141 4.06409 20.5141 2.2519 19.3201 1.12595C18.1261 0 16.2045 0 12.3612 0H11.6817C7.83842 0 5.91675 0 4.72278 1.12595ZM6.39511 4.87207C6.4871 9.03666 8.69517 11.5394 12.5664 11.5394H12.7858V9.15677C14.2084 9.29025 15.284 10.2713 15.7157 11.5394H17.7257C17.1737 9.64398 15.7228 8.59616 14.8169 8.19571C15.7228 7.70184 16.9967 6.50052 17.301 4.87207H15.475C15.0787 6.19353 13.904 7.39483 12.7858 7.50829V4.87207H10.9599V9.49047C9.82751 9.22352 8.39795 7.92877 8.33425 4.87207H6.39511Z"
                                  fill="#0077FF"/>
                          </g>
                        </g>
                        <defs>
                          <clipPath id="clip0_1_1710">
                            <rect width="24" height="16" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                      Вконтакте (только видео)
                    </span>
                  <span>
                      <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19.9756 4.7256C19.2856 3.96821 17.6167 3.72797 16.4016 3.65205C15.8627 3.61757 15.3257 3.52368 14.8046 3.37256C14.5691 3.30728 14.3335 3.22475 14.1036 3.13599C13.0819 2.73254 11.3964 2.34449 9.57756 3.08538C9.5197 3.11069 9.46331 3.13049 9.4106 3.158C9.35458 3.17817 9.29782 3.20678 9.23995 3.23209C7.46125 4.05587 6.59954 5.55122 6.20296 6.57195C6.1123 6.80009 6.01315 7.02822 5.90111 7.24462C5.65933 7.72767 5.36706 8.1821 5.0265 8.59913C4.25546 9.53441 3.30234 10.9146 3.38711 11.9313C3.40075 12.1103 3.44645 12.279 3.53307 12.4301C3.7015 12.7305 3.97424 12.8889 4.18359 12.9869C5.70872 13.7131 9.24327 13.3463 13.0402 11.4222C17.0381 9.95657 19.7095 7.6246 20.2067 6.01886C20.2767 5.79952 20.3449 5.49253 20.2384 5.16573C20.1871 5.00325 20.095 4.85874 19.9756 4.7256Z"
                            fill="#263238"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M20.1757 4.88981C20.9525 6.61385 17.8071 9.64675 13.182 11.6506C8.55686 13.6545 4.0513 13.9296 3.42583 12.1463C2.98155 10.8799 5.94024 7.49874 10.5654 5.49522C15.1905 3.4917 19.6506 3.72435 20.1757 4.88981Z"
                              fill="#2962FF"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M8.1573 10.9466C7.84685 10.2941 9.39449 9.04317 11.6145 8.15179C13.8344 7.26042 15.8853 7.06751 16.1957 7.72002C16.5061 8.37253 14.9585 9.6235 12.7385 10.5149C10.5186 11.4063 8.46774 11.5995 8.1573 10.9466Z"
                              fill="white"/>
                      </svg>
                      Яндекс Диск
                    </span>
                </li>
                <li class="statistic__carousel__item__text">По прямой ссылке на аудио/видео</li>
                <li class="statistic__carousel__item__text">Записывая аудио или демонстрацию экрана (встречи, вебинары,
                  лекции и т.д.)
                </li>
              </ul>
            </div>
          </div>
          <div class="statistic__carousel__item ">
            <div class="statistic__carousel__item__container statistic__carousel__item_purple">
              <p class="statistic__carousel__item__title">Поддерживаемые форматы</p>
              <div class="statistic__carousel__item__columns">
                <ul class="statistic__carousel__item__list">
                  <p class="statistic__carousel__item__text">Аудио:</p>
                  <li class="statistic__carousel__item__text">Aac</li>
                  <li class="statistic__carousel__item__text">Ogg</li>
                  <li class="statistic__carousel__item__text">Amr</li>
                  <li class="statistic__carousel__item__text">Wav</li>
                  <li class="statistic__carousel__item__text">Flac</li>
                  <li class="statistic__carousel__item__text">M4a</li>
                  <li class="statistic__carousel__item__text">Mpeg</li>
                  <li class="statistic__carousel__item__text">Mp4</li>
                  <li class="statistic__carousel__item__text">Mp3 и другие</li>
                </ul>
                <ul class="statistic__carousel__item__list">
                  <p class="statistic__carousel__item__text">Видео:</p>
                  <li class="statistic__carousel__item__text">Mpeg</li>
                  <li class="statistic__carousel__item__text">Mp4</li>
                  <li class="statistic__carousel__item__text">Amr</li>
                  <li class="statistic__carousel__item__text">Aac и другие</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="statistic__carousel__item ">
            <div class="statistic__carousel__item__container statistic__carousel__item_red">
              <p class="statistic__carousel__item__title">Скоро: AI-функции</p>
              <ul class="statistic__carousel__item__list">
                <li class="statistic__carousel__item__text">Автозаполнение карточки в CRM<br> на основе транскрибируемого разговора</li>
                <li class="statistic__carousel__item__text">Автокоррекция полученной расшифровки (исправление орфографических, синтаксических, грубых стилистических ошибок)</li>
              </ul>
            </div>
          </div>
          <div class="statistic__carousel__item ">
            <div class="statistic__carousel__item__container statistic__carousel__item_green">
              <p class="statistic__carousel__item__title">Количество потоков</p>
              <p class="statistic__carousel__item__text">От 5 выделенных при корпоративном тарифе</p>
            </div>
          </div>
        </div>
        <div class="carousel__button__wrapper">
          <button :class="this.hiddenIndex > 0 ? 'carousel__button__back_active' : 'carousel__button__back_disabled'"
                  class="carousel__button carousel__button__back" @click="scrollCarousel">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z"
                    fill="white"/>
            </svg>
          </button>
          <button :class="this.hideArrow ? 'carousel__button_disabled' : 'carousel__button_active'"
                  class="carousel__button" @click="scrollCarousel('right')">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z"
                    fill="white"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Tr from "@/i18n/translation";

export default {
  name: "CharlaStatistics",
  data() {
    return {
      hiddenIndex: 0,
      hideArrow: false
    }
  },
  computed: {
    Tr() {
      return Tr
    },
  },
  methods: {
    scrollCarousel(direction = 'left') {
      const carouselItems = [...document.querySelectorAll('.statistic__carousel__item')]
      const carouselItemsContainer = [...document.querySelectorAll('.statistic__carousel__item__container')]
      const carouselButtons = [...document.querySelectorAll('.carousel__button')]

      carouselButtons.forEach(e => {
        e.style.pointerEvents = 'none'
        setTimeout(() => {
          e.style.pointerEvents = 'all'
        }, 500)
      })

      if (direction === 'right') {
        carouselItemsContainer.forEach((e, i) => {
          if (i === this.hiddenIndex) {
            e.classList.add('disappear')
          } else if (i !== this.hiddenIndex && !e.classList.contains('disappear')) {
            carouselItems[i].style.transition = '500ms transform ease'
            carouselItems[i].style.transform = `translate(calc(-${100 * (this.hiddenIndex + 1)}% - ${16 * (this.hiddenIndex + 1)}px), 0)`
          }
        })

        this.hiddenIndex += 1
      } else {
        this.hiddenIndex -= 1

        carouselItemsContainer.forEach((e, i) => {
          if (i === this.hiddenIndex) {
            e.classList.add('appear')
            setTimeout(() => {
              e.classList.remove('disappear')
              e.classList.remove('appear')
            }, 500)
          } else if (i !== this.hiddenIndex && !e.classList.contains('disappear')) {
            carouselItems[i].style.transition = '500ms transform ease'
            carouselItems[i].style.transform = `translate(calc(-${100 * (this.hiddenIndex)}% - ${16 * (this.hiddenIndex)}px), 0)`
          }
        })

      }

      const carousel = document.querySelector('.statistic__carousel');
      carousel.dataset.hiddenIndex = this.hiddenIndex
    }
  },
  mounted() {
    const splideContainer = document.querySelector('.splide')

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          splideContainer.dataset.stopScroll = 'false'
          this.hideArrow = true
        } else {
          splideContainer.dataset.stopScroll = 'true'
          this.hideArrow = false
        }
      })
    }, {
      threshold: 1
    });

    let money__statistic__carouselItem = [...document.querySelectorAll('.statistic__carousel__item')];
    money__statistic__carouselItem = money__statistic__carouselItem[money__statistic__carouselItem.length - 1]
    observer.observe(money__statistic__carouselItem)

    const carousel = document.querySelector('.statistic__carousel')
    this.hiddenIndex = +carousel.dataset.hiddenIndex


    const config = {
      attributes: true,
    };
    const callback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes') {
          this.hiddenIndex = +carousel.dataset.hiddenIndex
        }
      })
    }

    const mutationObserver = new MutationObserver(callback)
    mutationObserver.observe(carousel, config)
  }
}
</script>


<style scoped lang="scss">
.block__title__icon {
  padding: 0 8px;
  background-color: var(--red);

  & > img {
    transform: translate(0, -4px);
  }
}

.statistics__slogan {
  font-weight: 400;
  font-size: 14px;
  line-height: 157%;
  color: #000;
  max-width: 368px;
}

.statistics__link {
  font-weight: 600;
  font-size: 22px;
  line-height: 127%;
  color: #000;
  display: flex;
  align-items: center;
  gap: 10px;

  & > span {
    font-weight: 500;
    font-size: 24px;
    color: var(--red);
  }
}

.block__right {
  position: relative;
}

.statistic__carousel {
  position: absolute;
  top: 50%;
  left: -80px;
  transform: translate(0, -50%);

  &__wrapper {
    display: flex;
    gap: 16px;
    position: relative;
    overflow: hidden;
  }

  &__item {
    width: 360px;
    height: 396px;
    display: grid;
    place-items: center;

    &__columns {
      display: flex;

      .statistic__carousel__item__list {
        gap: 2px;
      }
    }

    &__container {
      padding: 16px;
      border-radius: 32px;
      display: flex;
      row-gap: 16px;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & li {
        position: relative;
        padding: 0 0 0 16px;

        &::before {
          content: "";
          width: 4px;
          height: 4px;
          background-color: #fff;
          position: absolute;
          left: 4px;
          border-radius: 50%;
          top: 6px;
        }
      }
    }

    &__socials {
      display: flex;
      flex-direction: column;
      gap: 4px;

      & span {
        display: grid;
        column-gap: 8px;
        grid-template: 1fr / auto 1fr;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #fff;
      }
    }

    &__title {
      font-weight: 600;
      font-size: 18px;
      line-height: 133%;
      color: #fff;
    }

    &__text {
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #fff;
    }

    &_red {
      background-color: var(--red);
    }

    &_purple {
      background-color: var(--purple);
    }

    &_green {
      background-color: var(--green);
    }

    &_yellow {
      background-color: var(--purple);
    }
  }

}

.statistic__carousel__item__comparison {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;


  &__right {
    text-align: right;
  }

  & span {
    color: white;
  }

  & p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 127%;
    color: #fff;
  }
}

.statistic__carousel__item_red .statistic__carousel__item__comparison {
  display: grid;
  grid-template: 1fr / auto 1fr auto;
}

.statistic__carousel__item_green .statistic__carousel__item__comparison {
  max-width: 240px;
}

.statistic__carousel__item_yellow .statistic__carousel__item__numbers {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  gap: 12px;

  & div {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 14px;
    line-height: 157%;
    color: #fff;
    gap: 8px;

    & > span {
      font-weight: 700;
      font-size: 56px;
      line-height: 100%;
      text-align: center;
      color: #fff;
    }
  }
}

.statistic__carousel > div:nth-child(0) {
  background-color: var(--red);
}

.carousel__button {
  background-color: var(--red);

  &__back {
    background-color: black;
  }
}

@media screen and (max-width: 1300px) {
  .block__title {
    font-size: 36px;
  }
}

@media screen and (max-width: 1200px) {
  .statistic__carousel {
    left: 0;
  }

  .statistic__carousel__item {
    height: 330px;
  }
}

@media screen and (max-width: 1000px) {
  .carousel__button__wrapper {
    width: 420px;
  }
}

@media screen and (max-width: 900px) {
  .carousel__button__wrapper {
    width: 360px;
  }
}

@media screen and (max-width: 770px) {
  .carousel__button__wrapper {
    width: 320px;
  }
}

</style>