<template>
  <CharlaFormPopup></CharlaFormPopup>
  <div class="subPage-background">
    <div class="subPage-wrapper">
      <subPageHeader></subPageHeader>
      <div class="page-title">
        <h1>Политика работы с Cookies</h1>
      </div>
      <div class="cookie__wrapper">
        <div class="cookie__container">
          <div class="cookie__block">
            <h2 class="cookie__title_small">1. Общие положения</h2>
            <p class="cookie__text">Приветствуем Вас в сервисе «Звук в букву».<br>Мы используем файлы cookie для того,
              чтобы взаимодействие с сайтом для пользователей было удобным и эффективным.</p>
            <p class="cookie__text">В данной документации подробно описаны виды файлов cookie, которые мы применяем, их
              назначение, а также способы, с помощью которых вы можете адаптировать их использование к своим
              предпочтениям.</p>
          </div>
          <div class="cookie__block">
            <h2 class="cookie__title_small">2. Что такое файлы cookie</h2>
            <p class="cookie__text">Файлы cookie представляют собой небольшие текстовые файлы, которые сохраняются на
              вашем устройстве во время посещения сайта.</p>
            <p class="cookie__text">Cookie содержат информацию о ваших действиях на веб-сайте, а также могут содержать
              сведения о вашем оборудовании, дате и времени сессии.</p>
          </div>
          <div class="cookie__block">
            <h2 class="cookie__title_small">3. Использование файлов cookie на сайте «Звук в букву»</h2>
            <p class="cookie__text"><b>Использование файлов cookie на сайте «Звук в букву»</b></p>
            <br>
            <ul class="cookie__list">
              <li>
                <p>
                  <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                  </svg>
                </p>
                <p class="cookie__text">Основные файлы cookie — данные файлы необходимы для функционирования нашего
                  сайта. Они обеспечивают базовое перемещение по сайту и доступ к защищенным разделам, являясь ключевыми
                  для основных функций сайта.</p>
              </li>
              <li>
                <p>
                  <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                  </svg>
                </p>
                <p class="cookie__text">Сессионные файлы cookie — работают в течение вашего активного визита и хранятся
                  только пока открыт веб-сайт. Они помогают сделать взаимодействие с сайтом более эффективным, запоминая
                  выбор на предыдущих страницах и избавляя от необходимости повторного ввода информации.</p>
              </li>
              <li>
                <p>
                  <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                  </svg>
                </p>
                <p class="cookie__text">Функциональные файлы cookie — данные файлы помогают запоминать ваши настройки и
                  предпочтения (например, выбранный язык или регион), что делает ваше взаимодействие с сайтом более
                  удобным и личностно ориентированным.</p>
              </li>
              <li>
                <p>
                  <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                  </svg>
                </p>
                <p class="cookie__text">Аналитические файлы cookie — собирают агрегированную информацию о том, как
                  посетители используют наш сайт. Это позволяет нам улучшать его структуру, удобство использования и
                  содержание, анализируя, как пользователи взаимодействуют с сайтом и какие страницы посещают наиболее
                  часто.</p>
              </li>
            </ul>
          </div>
          <div class="cookie__block">
            <h2 class="cookie__title_small">4. Управление файлами cookie</h2>
            <p class="cookie__text">Вы можете управлять своими предпочтениями в отношении файлов cookie через настройки
              вашего браузера.</p>
            <p class="cookie__text">Большинство веб-браузеров автоматически принимают файлы cookie, но предоставляют вам
              возможность изменить это поведение. Вы можете настроить браузер на предупреждение об отправке файлов
              cookie или на блокировку их приема.</p>
            <p class="cookie__text">Обратите внимание, что блокировка или удаление файлов cookie может негативно
              сказаться на функциональности сайта. Некоторые функции могут стать недоступны, а некоторые страницы могут
              не отображаться корректно.</p>
          </div>
          <div class="cookie__block">
            <h2 class="cookie__title_small">5. Удаление файлов cookie</h2>
            <p class="cookie__text">Удалить файлы cookie, уже сохраненные на вашем устройстве, можно через настройки
              вашего браузера. Удаление файлов cookie приведет к удалению информации о ваших действиях на сайтах, а
              также может потребовать повторного ввода информации, ранее сохраненной в файлах cookie.</p>
          </div>
          <div class="cookie__block">
            <h2 class="cookie__title_small">6. Изменения в политике использования файлов cookie</h2>
            <p class="cookie__text">Команда «Звук в букву» оставляет за собой право при необходимости обновлять
              настоящую политику использования файлов cookie, чтобы она соответствовала новым требованиям и стандартам
              безопасности. Любые изменения в политике будут публиковаться на нашем сайте. Мы рекомендуем пользователям
              периодически просматривать политику, чтобы быть в курсе любых обновлений или изменений.</p>
          </div>
          <div class="cookie__block">
            <h2 class="cookie__title_small">7. Обратная связь</h2>
            <p class="cookie__text">Если у вас возникли вопросы относительно нашей политики использования файлов cookie,
              пожалуйста, свяжитесь с нами по электронной почте <a href="mailto:welcome@zv-ai.ru">welcome@zv-ai.ru.</a></p>
          </div>
        </div>
      </div>
      <subPageFooter></subPageFooter>
    </div>
  </div>
</template>

<script>
import Tr from "@/i18n/translation";
import subPageFooter from "@/components/subPages/subPageFooter.vue";
import subPageHeader from "@/components/subPages/subPageHeader.vue";
import CharlaFormPopup from "@/components/CharlaFormPopup.vue";

export default {
  name: "privacyPolicy",
  components: {CharlaFormPopup, subPageHeader, subPageFooter},
  computed: {
    Tr() {
      return Tr
    }
  },

}
</script>

<style scoped lang="sass">
.subPage-background
  background: #F6F5F8
  height: 100%

.subPage-wrapper
  max-width: 1112px
  margin: auto
  padding: 64px 0

.faq-wrapper
  margin: 64px auto

.page-title
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 64px

h1
  font-size: 56px
  font-weight: 700
  margin: 0

  span
    color: #98DA2C

.title-btn
  display: flex
  align-items: center
  justify-content: center
  gap: 16px
  max-width: 240px
  width: 100%
  height: 48px
  border-radius: 24px
  background: #000
  color: #fff
  transition: .3s ease
  cursor: pointer
  border: 1px solid #000

  &:hover
    background: #fff
    color: #000

    svg path
      transition: .3s ease
      fill: #000

p
  font-size: 16px
  line-height: 164%

  &:last-child
    margin-bottom: 0

ul
  padding-left: 30px

  li
    font-size: 16px
    margin-bottom: 12px
    line-height: 164%
    list-style-type: disc

h2
  font-size: 22px
  margin: 20px 0 10px 0

h3
  font-size: 24px
  font-weight: 400
  margin: 0

@media screen and (max-width: 1200px)
  .subPage-wrapper
    margin: 0 40px

@media screen and (max-width: 970px)
  .reviews-grid
    grid-template-columns: 1fr 1fr
  h1
    font-size: 32px

@media screen and (max-width: 660px)
  .page-title
    display: flex
    flex-direction: column
    gap: 22px
  .subPage-wrapper
    margin: 0 20px
    padding: 24px 0
  h1
    font-size: 32px
    font-weight: 400
  h2
    font-size: 24px
  h3
    font-size: 18px

  p
    font-size: 13px
    line-height: 154%
  ul li
    font-size: 13px
    line-height: 154%
  .title-btn
    font-size: 14px
    max-width: 200px


</style>


<style lang="scss" scoped>
.cookie {
  &__block {
    display: flex;
    flex-direction: column;

    & .cookie__text:last-of-type {
      padding: 0;
    }
  }


  &__wrapper {
    width: 100%;
    z-index: 10000;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #000;
    margin: 0;

    &_small {
      font-weight: 600;
      font-size: 22px;
      margin: 0;
      line-height: 127%;
      color: #000;
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 0 0 32px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;
    padding: 0 0 16px;
  }
}
</style>