<template>
  <div class="form__popup" v-show="getFormState">
    <div class="form__background" @click="closeForm"></div>
    <div class="form__wrapper">
      <div class="form__container">
        <div class="form__close" @click="closeForm">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.42 10L20 18.58L18.58 20L10 11.42L1.42 20L0 18.58L8.58 10L0 1.42L1.42 0L10 8.58L18.58 0L20 1.42L11.42 10Z"
              fill="black" />
          </svg>
        </div>
        <div class="form__title__wrapper">
          <h2 class="form__title" v-html='$t("FormPopup.title")'></h2>
          <div class="form__title__error">
            <p v-html='$t("FormPopup.errorPlaceholder")'></p>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="4" cy="4" r="3.5" stroke="#FF6332" />
            </svg>
          </div>
        </div>
        <div class="form__block">
          <div class="form__block__item">
            <h2 class="form__block__item__title">
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4" r="3.5" stroke="#FF6332" />
              </svg>
              {{ $t("FormPopup.input2") }}
            </h2>
            <input class="form__block__item__input" placeholder="..." data-required-field=true>
          </div>
          <div class="form__block__item form__block__item__textarea">
            <h2 class="form__block__item__title">
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4" r="3.5" stroke="#FF6332" />
              </svg>
              {{ $t("FormPopup.input6") }}
            </h2>
            <textarea placeholder="..." data-required-field=true></textarea>
          </div>
          <div class="form__block__item">
            <h2 class="form__block__item__title">
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4" r="3.5" stroke="#FF6332" />
              </svg>
              {{ $t("FormPopup.input4") }}
            </h2>
            <div class="form-popup__item_phone_wrapper">
              <input class="form__block__item__input" placeholder="" data-required-field=true>
              <svg class="form__block__item__multiselect__icon" width="16" height="22" viewBox="0 0 16 22" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect y="6" width="16" height="2" fill="black" />
                <rect x="4" y="10" width="12" height="2" fill="black" />
                <rect x="8" y="14" width="8" height="2" fill="black" />
              </svg>
            </div>
          </div>


          <div class="form__block__item form__block__item__multi">
            <h2 class="form__block__item__title">
              {{ $t("FormPopup.input7") }}
            </h2>
            <div class="form__block__item__input" @click="multiselects.feedback = !multiselects.feedback; changeIcon()"
              :class="{ 'form__block__item__multiselect_active': multiselects.feedback }">
              <p class="form__block__item__multiselect__placeholder">{{ multiSelectContent.placeholderFeedback }}</p>
              <svg class="form__block__item__multiselect__icon" width="16" height="22" viewBox="0 0 16 22" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect y="6" width="16" height="2" fill="black" />
                <rect x="4" y="10" width="12" height="2" fill="black" />
                <rect x="8" y="14" width="8" height="2" fill="black" />
              </svg>
            </div>
            <div class="form__block__item__multiselect">
              <div class="form__block__item__multiselect__wrapper">
                <ul class="form__block__item__multiselect__container">
                  <li class="form__block__item__multiselect__item" v-for="(item, index) in multiSelectContent.feedback"
                    :key="index" @click="newFeedback(index)">
                    <div class="form__block__item__multiselect__item__box">
                      <div class="form__block__item__multiselect__item__box__check"
                        :class="{ 'form__block__item__multiselect__item__box__check_active': multiSelectContent.feedbackCheck[index] }">
                      </div>
                    </div>
                    <p class="form__block__item__multiselect__item__text">{{ item }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="form__block__item">
            <h2 class="form__block__item__title">{{ $t("FormPopup.input8") }}</h2>
            <input class="form__block__item__input" placeholder="...">
          </div>
          <div class="form__block__privacy">
            <div class="form__block__privacy__checkbox"
              :class="{ 'form__block__privacy__checkbox_active': checkBoxActive }"
              @click="checkBoxActive = !checkBoxActive">
              <div class="form__block__privacy__checkbox__check"></div>
            </div>
            <h2 class="form__block__privacy__title">{{ $t("FormPopup.pp1") }}<br><span class="privacy__link"
                @click="openPrivacy">{{ $t("FormPopup.pp2") }}</span></h2>
          </div>
          <div class="form__block__submit">
            <button class="form__block__submit__button" @click="validateForm()">
              {{ $t("FormPopup.btn") }}
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16 9.42L16 6.58L9.42 -2.87621e-07L8 1.42L13.58 7L-3.0598e-07 7L-3.93402e-07 9L13.58 9L8 14.58L9.42 16L16 9.42Z"
                  fill="white" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="success__wrapper">
      <h2 class="success__title">
        {{ $t("FormPopup.success") }}
        <svg width="24" height="35" viewBox="0 0 24 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.1603 0C19.4536 2.72 21.1603 5.49333 22.2803 8.32C23.427 11.12 24.0003 14.0667 24.0003 17.16C24.0003 20.2267 23.427 23.1733 22.2803 26C21.1603 28.8 19.4536 31.5733 17.1603 34.32H11.3203C13.0536 31.2533 14.3203 28.32 15.1203 25.52C15.947 22.72 16.3603 19.9333 16.3603 17.16C16.3603 14.3333 15.947 11.52 15.1203 8.72C14.3203 5.92 13.0536 3.01333 11.3203 0H17.1603Z"
            fill="#98DA2C" />
          <path
            d="M4.88844 30.3999C3.6351 30.3999 2.60844 30.0132 1.80844 29.2399C1.0351 28.4666 0.648438 27.4932 0.648438 26.3199C0.648438 25.1466 1.0351 24.1866 1.80844 23.4399C2.60844 22.6666 3.6351 22.2799 4.88844 22.2799C6.11511 22.2799 7.1151 22.6666 7.88844 23.4399C8.68844 24.1866 9.08844 25.1466 9.08844 26.3199C9.08844 27.4932 8.68844 28.4666 7.88844 29.2399C7.1151 30.0132 6.11511 30.3999 4.88844 30.3999ZM4.88844 16.5199C3.6351 16.5199 2.60844 16.1332 1.80844 15.3599C1.0351 14.5866 0.648438 13.6132 0.648438 12.4399C0.648438 11.2666 1.0351 10.3066 1.80844 9.5599C2.60844 8.78657 3.6351 8.3999 4.88844 8.3999C6.11511 8.3999 7.1151 8.78657 7.88844 9.5599C8.68844 10.3066 9.08844 11.2666 9.08844 12.4399C9.08844 13.6132 8.68844 14.5866 7.88844 15.3599C7.1151 16.1332 6.11511 16.5199 4.88844 16.5199Z"
            fill="#98DA2C" />
        </svg>
      </h2>
      <p class="success__text">{{ $t("FormPopup.success_text") }}</p>
      <button class="success__button" @click="closeSuccess" style="border: none">{{ $t("FormPopup.success_btn")
        }}</button>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import '@/js/phoneMasks.js';
import { useGtm } from "@gtm-support/vue-gtm";
import { sendMetrik } from "@/utils/metrica";

export default {
  name: "CharlaFormPopup",
  methods: {
    ...mapActions(['setFormState', 'setReadPrivacy', 'setPrivacyState']),
    form_sendFormFeedback_new_id47() {
      const gtm = useGtm()
      gtm.dataLayer().push({
        'event': 'form_send-form-feedback_new_id47',
        'eventCategory': 'form',
        'eventAction': 'send-form-feedback',
        'eventLabel': 'new_id47'
      })
    },
    sendMetrik: sendMetrik,
    validateForm() {
      const allFields = [...document.querySelectorAll('input.form__block__item__input, .form__block__item  > textarea')]
      const requiredFields = [...document.querySelectorAll('[data-required-field*=true]')];
      const requiredCircles = [...document.querySelectorAll('.form__block__item .form__block__item__title > svg:not(.form__block__item__multi .form__block__item__title > svg)')];
      const errorWrapper = document.querySelector('.form__title__error');
      const errorTextCircle = document.querySelector('.form__title__error > svg');
      const errorText = document.querySelector('.form__title__error > p');
      const privacyCheckbox = document.querySelector('.form__block__privacy__checkbox');
      // const phoneMask = document.querySelector('.country-placeholder > p').innerHTML

      this.send = true

      if (!privacyCheckbox.classList.contains('form__block__privacy__checkbox_active')) {
        privacyCheckbox.classList.add('error__checkbox')
        errorText.innerHTML = this.$t("formPopupMsg.error1")
        this.send = false

        if (!errorWrapper.classList.contains('error__border')) {
          errorWrapper.classList.add('error__border')
          errorTextCircle.classList.add('error__fill')
        }
      }

      privacyCheckbox.addEventListener('click', () => {
        privacyCheckbox.classList.remove('error__checkbox')
      })

      if (requiredFields[2].value !== '' && this.getPhoneMask.match(/x/g).length !== requiredFields[2].value.match(/\d/g).length && this.getPhoneMask.match(/x/g).length !== 31) {
        errorText.innerHTML = this.$t("formPopupMsg.error2")
        this.send = false
        requiredFields[2].classList.add('error__input');
        requiredCircles[2].classList.add('error__fill')

        requiredFields[2].addEventListener('click', () => {
          requiredFields[2].classList.remove('error__input')
          requiredCircles[2].classList.remove('error__fill')
        })

        if (!errorWrapper.classList.contains('error__border')) {
          errorWrapper.classList.add('error__border')
          errorTextCircle.classList.add('error__fill')
        }
      }

      requiredFields.forEach((e, i) => {
        if (e.value === '') {
          errorText.innerHTML = this.$t("formPopupMsg.error4")
          e.classList.add('error__input')
          requiredCircles[i].classList.add('error__fill')

          e.addEventListener('click', () => {
            e.classList.remove('error__input')
            requiredCircles[i].classList.remove('error__fill')
          })

          if (!errorWrapper.classList.contains('error__border')) {
            errorWrapper.classList.add('error__border')
            errorTextCircle.classList.add('error__fill')
          }

          this.send = false
        }
      })


      if (this.send) {
        this.openSuccess()
        this.form_sendFormFeedback_new_id47();
        this.sendMetrik('form_send-form-feedback_new_id47');
        errorText.innerHTML = this.$t("formPopupMsg.error4")
        errorWrapper.classList.remove('error__border')
        errorTextCircle.classList.remove('error__fill')
        privacyCheckbox.classList.remove('form__block__privacy__checkbox_active')


        const orderForm = {
          name: allFields[0].value,
          message: allFields[1].value,
          phone: `${document.querySelector('.country-placeholder > p').innerHTML} ${allFields[2].value}`,
          time: allFields[3].value,
          connections: this.multiSelectContent.placeholderFeedback
        };


        this.multiSelectContent.placeholderCompany = '...'
        this.multiSelectContent.placeholderFeedback = '...'

        const jsonData = JSON.stringify(orderForm);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', './php/sendtg.php', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
          if (xhr.status === 200) {
            console.log('Success')
          } else {
            console.error('Произошла ошибка при отправке формы.');
          }
        };
        xhr.send(jsonData);

        allFields.forEach(e => {
          e.value = ''
        })
      }
    },
    newFeedback(index) {

      if (this.multiSelectContent.placeholderFeedback === '...') {
        this.multiSelectContent.placeholderFeedback = '';
      }

      this.multiSelectContent.feedbackCheck[index] = !this.multiSelectContent.feedbackCheck[index]
      this.multiSelectContent.placeholderFeedback = ''
      this.multiSelectContent.feedback.forEach((e, i) => {
        if (this.multiSelectContent.feedbackCheck[i]) {
          this.multiSelectContent.placeholderFeedback += `${e}; `
        }
      })

      if (this.multiSelectContent.placeholderFeedback === '') {
        this.multiSelectContent.placeholderFeedback = '...';
      }

    },
    changeIcon() {
      const icons = [...document.querySelectorAll('.form__block__item__multiselect__icon')]

      if (this.multiselects.feedback) {
        [...icons[1].children].forEach((e, i) => {
          if (i === 0) {
            e.style.transform = 'translate(0, 8px)'
          }

          if (i === 2) {
            e.style.transform = 'translate(0, -8px)'
          }
        })
      } else {
        [...icons[1].children].forEach((e, i) => {
          if (i === 0) {
            e.style.transform = 'translate(0, 0)'
          }

          if (i === 2) {
            e.style.transform = 'translate(0, 0)'
          }
        })
      }
    },
    closeForm() {
      const formWrapper = document.querySelector('.form__wrapper');
      const formBackground = document.querySelector('.form__background');

      formWrapper.classList.add('form__wrapper__disable')
      formBackground.classList.add('form__background__disable')

      setTimeout(() => {
        formWrapper.classList.remove('form__wrapper__disable')
        formBackground.classList.remove('form__background__disable')
        this.setFormState(false)
      }, 500)
    },
    openSuccess() {
      const formWrapper = document.querySelector('.form__wrapper');
      const successWrapper = document.querySelector('.success__wrapper');
      formWrapper.classList.add('form__wrapper__success')

      setTimeout(() => {
        successWrapper.classList.add('success__wrapper__active')
      }, 250)
    },
    closeSuccess() {
      const successWrapper = document.querySelector('.success__wrapper');
      const formBackground = document.querySelector('.form__background');
      const formWrapper = document.querySelector('.form__wrapper');

      formBackground.classList.add('form__background__disable')
      successWrapper.classList.add('success__wrapper__disable')

      setTimeout(() => {
        formBackground.classList.remove('form__background__disable')
        successWrapper.classList.remove('success__wrapper__disable')
        successWrapper.classList.remove('success__wrapper__active')
        formWrapper.classList.remove('form__wrapper__success')

        this.setFormState(false)
      }, 500)
    },
    openPrivacy() {
      const formWrapper = document.querySelector('.form__wrapper');
      const formBackground = document.querySelector('.form__background');

      formWrapper.classList.add('form__wrapper__disable')
      formBackground.classList.add('form__background__disable')

      setTimeout(() => {
        formWrapper.classList.remove('form__wrapper__disable')
        formBackground.classList.remove('form__background__disable')
        this.setReadPrivacy(true)
        this.setPrivacyState(true)
        this.setFormState(false)
      }, 500)
    }
  },
  computed: {
    ...mapGetters(['getFormState', 'getPhoneMask'])
  },
  mounted() {
    window.addEventListener('keydown', () => {
      if (event.key === "Escape") {
        this.closeForm();

        setTimeout(() => {
          this.setFormState(false);
        }, 500)
      }
    })
  },
  data() {
    return {
      checkBoxActive: false,
      multiselects: {
        company: false,
        feedback: false
      },
      multiSelectContent: {
        company: [this.$t("formPopupMsg.company_item1"), this.$t("formPopupMsg.company_item2"), this.$t("formPopupMsg.company_item3"),
        this.$t("formPopupMsg.company_item4"), this.$t("formPopupMsg.company_item5"), this.$t("formPopupMsg.company_item6")],
        // company: ['Marketing and advertising', 'Education', 'Sales', 'Software development', 'Media', 'Other'],
        placeholderCompany: '...',
        feedback: ['Email', 'Telegram', this.$t("formPopupMsg.feedback")],
        feedbackCheck: [false, false, false],
        placeholderFeedback: '...'
      },
      send: true,
      errorText: ""
    }
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/phoneMask.css";

input {
  box-sizing: border-box;
  height: 100%;
}

.form {
  &__background {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    cursor: pointer;
    z-index: 9999;
    animation: 500ms ease forwards __fadeIn;

    &__disable {
      animation: 500ms ease forwards __fadeOut;
    }
  }

  &__wrapper {
    background-color: white;
    max-width: 836px;
    width: 100%;
    z-index: 10000;
    border-radius: 44px;
    height: 746px;
    animation: 500ms ease forwards __slideUp;


    &__disable {
      animation: 500ms ease forwards __slideDown;
    }

    &__success {
      animation: 500ms ease forwards __slideUpSuccess;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__container {
    display: flex;
    padding: 64px;
    flex-direction: column;
    position: relative;
    gap: 48px;
  }

  &__close {
    position: absolute;
    background-color: white;
    top: 24px;
    right: -48px;
    transform: translate(50%, 0);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 100000;
    display: grid;
    place-items: center;
    cursor: pointer;
  }

  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #000;

    &__wrapper {
      display: grid;
      grid-template: 1fr / 1fr 320px;

    }

    &__error {
      height: fit-content;
      border-bottom: 2px solid black;
      transition: 300ms border-bottom ease;
      padding: 0 0 10px 16px;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #000;
      position: relative;

      &>svg {
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }

  &__block {
    display: grid;
    grid-template: repeat(4, auto) / repeat(3, 1fr);
    width: 100%;
    gap: 24px;

    &__item {

      &:nth-child(1) {
        grid-area: 1 / 1 / 1 / span 3;
      }

      &:nth-child(3) {
        grid-area: 2 / 1 / 2 / span 3;
      }


      display: grid;
      grid-template: 1fr auto / auto;
      //flex-direction: column;
      gap: 8px;

      &__multi {
        position: relative;
        z-index: 9;

        &>.form__block__item__input {
          padding: 9px 40px 9px 16px;
          display: flex;

          &>p {
            overflow: hidden;
            text-overflow: ellipsis;

          }
        }
      }

      &__multiselect {
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translate(0, 100%);
        width: calc(100%);
        background-color: black;
        border-radius: 20px;
        padding: 0;
        display: grid;
        grid-template-rows: 0fr;
        transition: 300ms all ease;
        outline: 1px solid transparent;

        &__wrapper {
          overflow: hidden;
        }

        &__container {
          display: flex;
          flex-direction: column;

          &>.form__block__item__multiselect__item:last-of-type {
            padding: 12px 16px;
          }
        }

        &__item {
          display: flex;
          gap: 12px;
          align-items: center;
          cursor: pointer;
          padding: 12px 16px 0 16px;

          &:hover .form__block__item__multiselect__item__text {
            color: var(--purple);
          }

          &__text {
            font-weight: 400;
            font-size: 11px;
            line-height: 150%;
            color: #fff;
            transition: 300ms ease color;
            text-overflow: ellipsis;
          }

          &__box {
            width: 24px;
            height: 24px;
            border: 1px solid white;
            border-radius: 4px;
            position: relative;

            &__check {
              position: absolute;
              background-color: white;
              width: 12px;
              height: 12px;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              z-index: 200;
              opacity: 0;
              transition: 300ms ease opacity;

              &_active {
                opacity: 1;
              }
            }
          }
        }

        &__placeholder {
          //position: absolute;
          //left: 16px;
          font-weight: 400;
          font-size: 14px;
          line-height: 157%;
          color: #000;
          user-select: none;
          bottom: 6px;
          white-space: nowrap;
        }

        &__icon {
          position: absolute;
          right: 16px;
          top: 50%;
          cursor: pointer;
          transform: translate(0, -50%);

          &>rect {
            transition: 300ms ease all;
          }
        }
      }

      &__input {
        border: 1px solid #000;
        border-radius: 20px;
        padding: 9px 16px;
        width: 100%;
        height: 40px;
        font-weight: 400;
        font-size: 14px;
        line-height: 157%;
        transition: 300ms ease all;
        color: black;
        position: relative;
        cursor: pointer;
        overflow-x: hidden;


        &::placeholder {
          font-weight: 400;
          font-size: 14px;
          line-height: 157%;
          color: #000;
          transition: 300ms ease all;
        }

        &:focus {
          background-color: var(--input);
          color: #fff;
        }

        &:focus~.country-placeholder {
          color: white;
        }

        &:focus::placeholder {
          color: white;
        }
      }

      &__title {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #000;
        display: flex;
        align-items: center;
        gap: 8px;

        &>svg {
          fill: none;
          transition: 300ms ease fill;
        }
      }

      &__textarea {
        grid-area: 3 / 1 / 3 / span 3;

        &>textarea {
          border: 1px solid #000;
          border-radius: 20px;
          padding: 9px 16px;
          height: 106px;
          font-weight: 400;
          font-size: 14px;
          line-height: 157%;
          color: black;
          resize: none;
          transition: 300ms ease all;


          &::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 157%;
            color: #000;
            transition: 300ms ease all;
          }

          &:focus {
            background-color: var(--input);
            color: #fff;
          }

          &:focus::placeholder {
            color: white;
          }
        }
      }
    }

    &__submit {
      grid-area: 5 / 1 / 5 / span 3;
      display: flex;
      justify-content: flex-end;

      &__button {
        background-color: var(--purple);
        color: white;
        display: flex;
        gap: 16px;
        padding: 13px 62px;
        border-radius: 24px;
      }
    }

    &__privacy {
      display: flex;
      align-items: center;
      gap: 16px;
      align-self: flex-end;
      padding: 0 0 2px;

      &__checkbox {
        border: 1px solid #000;
        border-radius: 4px;
        transition: 300ms ease border;
        padding: 10px;
        width: 24px;
        height: 24px;
        position: relative;
        cursor: pointer;

        &_active>.form__block__privacy__checkbox__check {
          opacity: 1;
        }

        &__check {
          position: absolute;
          background-color: var(--purple);
          width: 12px;
          height: 12px;
          left: 5px;
          top: 5px;
          opacity: 0;
          transition: 300ms ease opacity;
        }
      }

      &__title {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #000;
      }
    }
  }
}

.success {
  &__wrapper {
    border-radius: 44px;
    padding: 64px;
    width: 480px;
    background-color: white;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 120vh);
    display: flex;
    flex-direction: column;
    gap: 48px;
    z-index: 10001;

    &__active {
      animation: 500ms ease forwards __successUp;
    }

    &__disable {
      animation: 500ms ease forwards __successDown;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #000;
    display: grid;
    grid-template: 1fr / 1fr auto;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;
  }

  &__button {
    font-weight: 600;
    font-size: 14px;
    line-height: 157%;
    text-align: center;
    color: #fff;
    background-color: var(--green);
    padding: 13px 54px;
    width: fit-content;
    border-radius: 24px;
  }
}

.privacy__link {
  color: var(--purple);
  cursor: pointer;
}

.form__block__item__multiselect_active {
  border-radius: 20px 20px 0 0;

  &+.form__block__item__multiselect {
    grid-template-rows: 1fr;
    border-radius: 0 0 20px 20px;
  }
}

.error__input {
  border: 1px solid var(--red) !important;
}

.error__fill {
  fill: var(--red) !important;
}

.error__border {
  border-bottom: 2px solid var(--red);
}

.error__checkbox {
  border: 1px solid var(--red);
}

.form-popup__item_phone_wrapper .form__block__item__input {
  padding: 9px 35px 9px 16px;
}

.form__popup {
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  position: fixed;
  left: 0;
  top: 0;
  display: grid;
  place-items: center;
  z-index: 9999;
}

@keyframes __fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes __fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes __slideUp {
  from {
    transform: translate(0, 120vh);
  }

  to {
    transform: translate(0, 0);
  }
}

@keyframes __slideDown {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, 120vh);
  }
}

@keyframes __slideUpSuccess {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, -150vh);
  }
}

@keyframes __successUp {
  from {
    transform: translate(-50%, 150vh);
  }

  to {
    transform: translate(-50%, -50%);
  }
}

@keyframes __successDown {
  from {
    transform: translate(-50%, -50%);
  }

  to {
    transform: translate(-50%, 150vh);
  }
}

@media screen and (max-height: 750px) {
  .form__wrapper {
    height: auto;
  }

  .form__container {
    gap: 24px;
    padding: 42px;
  }

  .form__block {
    gap: 14px;
  }
}

@media screen and (max-width: 660px) {
  .form__wrapper {
    overflow-y: scroll;
    width: calc(100vw - 16px);
    height: calc(100vh - 16px);
    border-radius: 26px;
  }

  .form__close {
    top: 5px;
    right: 30px;
    background: #000;
  }

  .form__close svg path {
    fill: #fff;
  }

  .form__container {
    padding: 16px;
  }

  .form__title__wrapper {
    display: flex;
    flex-direction: column;
  }

  .form__title {
    font-size: 22px;
    text-align: center;
  }

  .form__title br {
    display: none;
  }

  .form__block {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .form__block__item__input {
    max-width: 100%;
    height: 40px;
  }

  .form__block__item__input {
    width: 100%;
    box-sizing: border-box;
  }

  .form__block__submit__button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 100%;
  }

  .form__block__privacy {
    align-self: self-start;
  }

  .form__block__privacy__checkbox {
    width: 12px;
    height: 12px;
    padding: 6px;
  }

  .form__block__privacy__checkbox__check {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 660px) {
  .form__block__item__input {
    overflow: hidden;
  }

  .form__block__item__multi>.form__block__item__input>p {
    overflow: visible;
    position: absolute;
    top: -6px;
  }

  .success__wrapper {
    width: 100%;
    padding: 34px;
    box-sizing: border-box;
  }
}
</style>