<template>
  <div class="functions__popup" v-show="getFunctionsState">
    <div class="functions__background" @click="closeFunctions()"></div>
    <div class="functions__close" @click="closeFunctions()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.42 10L20 18.58L18.58 20L10 11.42L1.42 20L0 18.58L8.58 10L0 1.42L1.42 0L10 8.58L18.58 0L20 1.42L11.42 10Z"
            fill="black"/>
      </svg>
    </div>
    <div class="functions__wrapper">
      <div class="functions__container">
        <h2 class="functions__title">Функциональные характеристики, системные требования</h2>
        <p class="functions__text">AI-сервис транскрибации (расшифровки) аудио и видео файлов в текст.<br>
          Сервис поддерживает 100+ языков.<br>
          <b>Сервис работает в формате веб-клиента и не требует установки программы-клиента на устройство
            пользователя.</b></p>
        <div class="functions__block">
          <p class="functions__subtitle">Функционал сервиса:</p>
          <ul class="functions__list">
            <li class="functions__list__item">загрузка файлов с устройства пользователя, запись аудио- и видеофайлов с
              устройства пользователя — по одному или пакетная,
            </li>
            <li class="functions__list__item">транскрибация (расшифровка) аудио и видео файлов: с устройства
              пользователя, записанных с устройства пользователя, по ссылке,
            </li>
            <li class="functions__list__item">проверка качества транскрибации (расшифровки) с помощью плеера и трекера
              записи,
            </li>
            <li class="functions__list__item">транскрибация (расшифровка) аудио и видеофайлов с добавлением следующих
              параметров: тайм-коды, диаризация (разделение на спикеров), суммаризация (краткое содержание текста),
            </li>
            <li class="functions__list__item">скачивание результата расшифровки на устройство пользователя в форматах
              .txt, .docx, .srt,
            </li>
            <li class="functions__list__item">оценка полученной текстовой расшифровки,</li>
            <li class="functions__list__item">возможность поделиться полученной расшифровкой,</li>
            <li class="functions__list__item">поминутная оплата расшифровки, подписка,</li>
            <li class="functions__list__item">история оплат и расшифровок</li>
          </ul>
        </div>
        <a class="functions__link" href="/Функциональные-характеристики-ПО.pdf">Функциональные характеристики</a>
        <div class="functions__block">
          <p class="functions__subtitle">Системные требования</p>
          <p class="functions__text"><b>Для ПК (Windows, macOS, Linux)</b></p>
        </div>
        <div class="functions__block">
          <p class="functions__text">Операционная система:</p>
          <br>
          <ul class="functions__list">
            <li class="functions__list__item">Windows: Windows 7 и выше</li>
            <li class="functions__list__item">macOS: macOS 10.10 и выше</li>
            <li class="functions__list__item">Linux: Современные дистрибутивы</li>
          </ul>
        </div>
        <div class="functions__block">
          <p class="functions__text">Процессор:</p>
          <br>
          <ul class="functions__list">
            <li class="functions__list__item">1.6 GHz или выше (двухъядерный процессор</li>
          </ul>
        </div>
        <div class="functions__block">
          <p class="functions__text">Оперативная память:</p>
          <br>
          <ul class="functions__list">
            <li class="functions__list__item">Минимум 1 ГБ RAM</li>
          </ul>
        </div>
        <div class="functions__block">
          <p class="functions__text">Видеокарта:</p>
          <br>
          <ul class="functions__list">
            <li class="functions__list__item">Встроенная видеокарта или любая дискретная видеокарта, поддерживающая аппаратное ускорение видео (например, Intel HD Graphics или эквивалент)</li>
          </ul>
        </div>
        <div class="functions__block">
          <p class="functions__text">Интернет-соединение:</p>
          <br>
          <ul class="functions__list">
            <li class="functions__list__item">не менее 500 кбит/с</li>
          </ul>
        </div>
        <div class="functions__block">
          <p class="functions__text">Браузер:</p>
          <br>
          <ul class="functions__list">
            <li class="functions__list__item">Google Chrome, Mozilla Firefox, Microsoft Edge или Safari (обновленные версии)</li>
          </ul>
        </div>
        <p class="functions__text"><b>Для мобильных устройств (смартфоны и планшеты)</b></p>
        <div class="functions__block">
          <p class="functions__text">Операционная система:</p>
          <br>
          <ul class="functions__list">
            <li class="functions__list__item">Android: 5.0 (Lollipop) и выше</li>
            <li class="functions__list__item">iOS: iOS 12 и выше</li>
          </ul>
        </div>
        <div class="functions__block">
          <p class="functions__text">Процессор и память:</p>
          <br>
          <ul class="functions__list">
            <li class="functions__list__item">Устройства с 1 ГБ RAM и более</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tr from "@/i18n/translation";
import {mapGetters, mapActions} from "vuex";

export default {
  methods: {
    ...mapActions(['setFunctionsState']),
    closeFunctions() {
      const functionsWrapper = document.querySelector('.functions__wrapper');
      const functionsBackground = document.querySelector('.functions__background');
      const functionsClose = document.querySelector('.functions__close')

      functionsWrapper.classList.add('functions__wrapper__disable');
      functionsBackground.classList.add('functions__background__disable');
      functionsClose.classList.add('functions__close__disable')


      setTimeout(() => {
        functionsWrapper.classList.remove('functions__wrapper__disable');
        functionsBackground.classList.remove('functions__background__disable');
        functionsClose.classList.remove('functions__close__disable')
        this.setFunctionsState(false)
      }, 500)
    }
  },
  computed: {
    ...mapGetters(["getFunctionsState"]),
    Tr() {
      return Tr
    }
  }
}
</script>

<style lang="scss" scoped>
.functions {
  &__background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    animation: 500ms ease forwards __fadeIn;

    &__disable {
      animation: 500ms ease forwards __fadeOut;
    }
  }

  &__link {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--purple);
  }

  &__subtitle {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    padding: 0 0 24px 0;
    margin: 0;
  }

  &__block {
    display: flex;
    flex-direction: column;

    & .functions__text:last-of-type {
      padding: 0;
    }
  }

  &__close {
    position: fixed;
    background-color: white;
    top: 48px;
    left: calc(50% + 418px);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 100000;
    transform: translate(50%, 0);
    display: grid;
    place-items: center;
    cursor: pointer;
    animation: 500ms ease forwards __slideUpClose;

    &__disable {
      animation: 500ms ease forwards __slideDownClose;
    }
  }

  &__wrapper {
    padding: 64px;
    position: fixed;
    left: 50%;
    top: 24px;
    transform: translate(-50%, 0);
    max-width: 836px;
    width: 100%;
    background-color: white;
    height: calc(100vh - 48px);
    overflow: hidden scroll;
    z-index: 10000;
    border-radius: 44px;
    animation: 500ms ease forwards __slideUp;

    &__disable {
      animation: 500ms ease forwards __slideDown;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    font-size: 14px;
    list-style: none;
    line-height: 157%;
    color: #000;

    &__item {
      padding: 0 0 0 48px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 11px;
        width: 20px;
        height: 2px;
        background-color: var(--green);
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 30px;
    line-height: 120%;
    color: #000;

    &_small {
      font-weight: 600;
      font-size: 22px;
      line-height: 127%;
      color: #000;
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 0 0 32px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;
  }
}

@keyframes __fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes __fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes __slideUp {
  from {
    transform: translate(-50%, 120vh);
  }

  to {
    transform: translate(-50%, 0);
  }
}

@keyframes __slideDown {
  from {
    transform: translate(-50%, 0);
  }

  to {
    transform: translate(-50%, 120vh);
  }
}

@keyframes __slideUpClose {
  from {
    transform: translate(50%, 120vh);
  }

  to {
    transform: translate(50%, 0);
  }
}

@keyframes __slideDownClose {
  from {
    transform: translate(50%, 0);
  }

  to {
    transform: translate(50%, 120vh);
  }
}

@media screen and (max-width: 660px) {
  .functions__wrapper {
    width: calc(100vw - 16px);
    height: calc(100vh - 16px);
    padding: 0;
    top: 8px;
  }

  .functions__popup {
    width: 100%;
    height: 100%;
  }

  .functions__container {
    gap: 30px;
    padding: 20px;
  }

  .functions__title {
    font-size: 22px;
    margin: 0;

    &_small {
      font-size: 18px;
      margin: 0;
    }
  }

  .functions__subtitle {
    font-size: 18px;
  }

  .functions__close {
    background: #000;
    top: 18px;
    right: 40px;
    left: unset;
    width: 36px;
    height: 36px;
  }

  .functions__close svg {
    width: 15px;
    height: 15px;
  }

  .functions__close svg path {
    fill: #fff;
  }

  .functions__list {
    gap: 8px;
  }

  .functions__text,
  .functions__list__item {
    margin: 0;
    font-size: 12px;
  }
}
</style>