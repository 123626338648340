<template>
  <div class="block" data-animation-trigger="true">
    <div class="block__left" data-animation="true" data-animation-type="move-up">
      <h2 class="block__title">
        <span>
          <span class="block__title__icon">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
              <path class="st0" d="M21.2,10.8c0.2,0.2,0.5,0.5,0.7,0.7l-0.7,0.7c-0.2-0.2-0.5-0.5-0.7-0.7s-0.5-0.5-0.7-0.7l0.7-0.7
                C20.7,10.4,20.9,10.6,21.2,10.8z M16.8,8.2c0.5,0.4,1,0.8,1.5,1.3L19,8.8c-0.5-0.5-1-0.9-1.5-1.3L16.8,8.2z M13.8,5.8
                c0.5,0.4,1,0.8,1.6,1.2l0.6-0.8c-0.5-0.4-1.1-0.8-1.6-1.2L13.8,5.8z M10.5,3.6c0.5,0.3,1.1,0.7,1.6,1l0.6-0.8
                c-0.6-0.4-1.1-0.7-1.7-1.1L10.5,3.6z M9.3,1.8C8.7,1.5,8.1,1.2,7.5,1L7.1,1.9c0.6,0.2,1.1,0.5,1.7,0.8L9.3,1.8z M5.6,0.3
                C4.9,0.1,4.2,0,3.6,0L3.6,1c0.5,0,1.1,0.1,1.8,0.3L5.6,0.3z M1.6,1.6c0.1-0.1,0.3-0.3,0.5-0.3L1.7,0.3C1.4,0.4,1.1,0.6,0.8,0.8
                S0.4,1.4,0.3,1.7l0.9,0.4C1.3,1.9,1.4,1.7,1.6,1.6z M1.3,5.3C1.1,4.7,1,4.1,1,3.6L0,3.6c0,0.6,0.1,1.3,0.3,2L1.3,5.3z M1,7.5
                c0.2,0.6,0.5,1.2,0.8,1.8l0.9-0.5C2.4,8.2,2.1,7.6,1.9,7.1L1,7.5z M4.7,12.2c-0.4-0.6-0.7-1.1-1-1.6L2.8,11c0.3,0.6,0.7,1.1,1.1,1.7
                L4.7,12.2z M5,14.4c0.4,0.5,0.8,1.1,1.2,1.6L7,15.3c-0.4-0.5-0.8-1-1.2-1.6L5,14.4z M7.5,17.5c0.4,0.5,0.9,1,1.3,1.5l0.7-0.7
                c-0.4-0.5-0.9-1-1.3-1.5L7.5,17.5z M11.6,21.9l0.7-0.7c-0.2-0.2-0.5-0.5-0.7-0.7s-0.5-0.5-0.7-0.7l-0.7,0.7c0.2,0.2,0.5,0.5,0.7,0.7
                C11.1,21.4,11.3,21.6,11.6,21.9z M15.2,23.8c-0.5-0.4-1-0.8-1.5-1.3L13,23.2c0.5,0.5,1,0.9,1.5,1.3L15.2,23.8z M16.1,25.8
                c0.5,0.4,1.1,0.8,1.6,1.2l0.6-0.8c-0.5-0.4-1-0.8-1.6-1.2L16.1,25.8z M19.3,28.2c0.6,0.4,1.1,0.7,1.7,1.1l0.5-0.9
                c-0.5-0.3-1.1-0.7-1.6-1L19.3,28.2z M22.7,30.2c0.6,0.3,1.2,0.6,1.8,0.8l0.4-0.9c-0.6-0.2-1.1-0.5-1.7-0.8L22.7,30.2z M26.4,31.7
                c0.7,0.2,1.4,0.3,2,0.3l0.1-1c-0.5,0-1.1-0.1-1.8-0.3L26.4,31.7z M30.4,30.4c-0.1,0.1-0.3,0.3-0.5,0.3l0.4,0.9
                c0.3-0.1,0.6-0.3,0.8-0.6c0.2-0.2,0.4-0.5,0.6-0.8l-0.9-0.4C30.7,30.1,30.6,30.3,30.4,30.4z M30.7,26.7c0.2,0.7,0.3,1.2,0.3,1.8
                l1-0.1c0-0.6-0.1-1.3-0.3-2L30.7,26.7z M31,24.5c-0.2-0.6-0.5-1.2-0.8-1.8l-0.9,0.5c0.3,0.6,0.6,1.2,0.8,1.7L31,24.5z M27.3,19.8
                c0.4,0.6,0.7,1.1,1,1.6l0.9-0.5c-0.3-0.6-0.7-1.1-1.1-1.7L27.3,19.8z M25.8,16.1L25,16.7c0.4,0.5,0.8,1,1.2,1.6l0.8-0.6
                C26.6,17.1,26.2,16.6,25.8,16.1z M24.5,14.5c-0.4-0.5-0.9-1-1.3-1.5l-0.7,0.7c0.4,0.5,0.9,1,1.3,1.5L24.5,14.5z M11.6,11.6
                c0.2-0.2,0.5-0.5,0.7-0.7l-0.7-0.7c-0.2,0.2-0.5,0.5-0.7,0.7c-0.2,0.2-0.5,0.5-0.7,0.7l0.7,0.7C11.1,12,11.3,11.8,11.6,11.6z
                 M7.5,14.5l0.8,0.6c0.4-0.5,0.8-1,1.3-1.5L8.8,13C8.3,13.5,7.9,14,7.5,14.5z M5,17.6l0.8,0.6c0.4-0.5,0.8-1,1.2-1.6l-0.8-0.6
                C5.8,16.6,5.4,17.1,5,17.6z M4.7,19.8l-0.8-0.6c-0.4,0.6-0.7,1.1-1.1,1.7l0.9,0.5C4,20.9,4.3,20.4,4.7,19.8z M1,24.5l0.9,0.4
                c0.2-0.6,0.5-1.1,0.8-1.7l-0.9-0.5C1.5,23.3,1.2,23.9,1,24.5z M1.3,26.7l-1-0.3c-0.2,0.7-0.3,1.4-0.3,2l1,0.1
                C1,27.9,1.1,27.3,1.3,26.7z M1.2,29.9l-0.9,0.4c0.1,0.3,0.3,0.6,0.6,0.8c0.2,0.2,0.5,0.4,0.8,0.6l0.4-0.9c-0.2-0.1-0.4-0.2-0.5-0.3
                C1.4,30.3,1.3,30.1,1.2,29.9z M3.6,31l0.1,1c0.6,0,1.3-0.1,2-0.3l-0.3-1C4.7,30.9,4.1,31,3.6,31z M7.1,30.1L7.5,31
                c0.6-0.2,1.2-0.5,1.8-0.8l-0.5-0.9C8.2,29.6,7.6,29.9,7.1,30.1z M10.5,28.4l0.5,0.9c0.6-0.3,1.1-0.7,1.7-1.1l-0.6-0.8
                C11.6,27.7,11.1,28,10.5,28.4z M13.8,26.2l0.6,0.8c0.5-0.4,1.1-0.8,1.6-1.2L15.3,25C14.8,25.4,14.3,25.8,13.8,26.2z M16.8,23.8
                l0.6,0.8c0.5-0.4,1-0.9,1.5-1.3l-0.7-0.7C17.8,22.9,17.3,23.4,16.8,23.8z M20.4,20.4c-0.2,0.2-0.5,0.5-0.7,0.7l0.7,0.7
                c0.2-0.2,0.5-0.5,0.7-0.7c0.2-0.2,0.5-0.5,0.7-0.7l-0.7-0.7C20.9,20,20.7,20.2,20.4,20.4z M24.5,17.5l-0.8-0.6
                c-0.4,0.5-0.8,1-1.3,1.5l0.7,0.7C23.7,18.5,24.1,18,24.5,17.5z M27,14.4l-0.8-0.6c-0.4,0.5-0.8,1-1.2,1.6l0.8,0.6
                C26.2,15.4,26.6,14.9,27,14.4z M27.3,12.2l0.8,0.6c0.4-0.6,0.7-1.1,1.1-1.7l-0.9-0.5C28,11.1,27.7,11.6,27.3,12.2z M31,7.5l-0.9-0.4
                c-0.2,0.6-0.5,1.1-0.8,1.7l0.9,0.5C30.5,8.7,30.8,8.1,31,7.5z M30.7,5.3l1,0.3c0.2-0.7,0.3-1.4,0.3-2l-1-0.1
                C31,4.1,30.9,4.7,30.7,5.3z M30.8,2.1l0.9-0.4c-0.1-0.3-0.3-0.6-0.6-0.8c-0.2-0.2-0.5-0.4-0.8-0.6l-0.4,0.9c0.2,0.1,0.4,0.2,0.5,0.3
                C30.6,1.7,30.7,1.9,30.8,2.1z M28.4,1l-0.1-1c-0.6,0-1.3,0.1-2,0.3l0.3,1C27.3,1.1,27.9,1,28.4,1z M22.7,1.8l0.5,0.9
                c0.6-0.3,1.2-0.6,1.7-0.8L24.5,1C23.9,1.2,23.3,1.5,22.7,1.8z M21.5,3.6L21,2.8c-0.6,0.3-1.1,0.7-1.7,1.1l0.6,0.8
                C20.4,4.3,20.9,4,21.5,3.6z M18.2,5.8L17.6,5c-0.5,0.4-1.1,0.8-1.6,1.2L16.7,7C17.2,6.6,17.7,6.2,18.2,5.8z M15.2,8.2l-0.6-0.8
                c-0.5,0.4-1,0.9-1.5,1.3l0.7,0.7C14.2,9.1,14.7,8.6,15.2,8.2z" fill="#FFF"/>
              </svg>
          </span>{{ $t("CharlaReview.title_part1") }}</span>
        <span v-html='$t("CharlaReview.title_part2")'></span>
      </h2>
      <div class="review__slogan" v-html='$t("CharlaReview.slogan") '>
      </div>
      <div class="review__numbers">
        <h2 class="review__numbers__title review__numbers__title_purple">250K</h2>
        <h2 class="review__numbers__title review__numbers__title_green">3M+</h2>
        <p class="review__numbers__text" v-html='$t("CharlaReview.info_part1")'></p>
        <p class="review__numbers__text" v-html='$t("CharlaReview.info_part2")'></p>
      </div>
    </div>
    <div class="block__right" data-animation="true" data-animation-type="move-up">
      <div class="review__carousel" data-carousel="true" data-hidden-index="0">
        <div class="review__carousel__wrapper">
          <div class="review__carousel__item">
            <div class="review__carousel__item__container">
              <svg class="review__carousel__item__corner_green" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32 2.54313e-06L0 0C17.6731 7.72516e-07 32 14.3271 32 32L32 2.54313e-06Z" fill="white" />
              </svg>
              <svg class="review__carousel__item__corner_white" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32 2.54313e-06L0 0C17.6731 7.72516e-07 32 14.3271 32 32L32 2.54313e-06Z" fill="white" />
              </svg>
              <div class="review__carousel__item__title__wrapper">
                <h2 class="review__carousel__item__title" v-html='$t("CharlaReview.reviews.review1.name")'></h2>
                <p class="review__carousel__item__date">{{$t("CharlaReview.reviews.review1.date")}}</p>
              </div>
              <div class="review__carousel__text">
                {{$t("CharlaReview.reviews.review1.text")}}
              </div>
            </div>
          </div>
          <div class="review__carousel__item">
            <div class="review__carousel__item__container">
              <svg class="review__carousel__item__corner_green" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32 2.54313e-06L0 0C17.6731 7.72516e-07 32 14.3271 32 32L32 2.54313e-06Z" fill="white" />
              </svg>
              <svg class="review__carousel__item__corner_white" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32 2.54313e-06L0 0C17.6731 7.72516e-07 32 14.3271 32 32L32 2.54313e-06Z" fill="white" />
              </svg>
              <div class="review__carousel__item__title__wrapper">
                <h2 class="review__carousel__item__title" v-html='$t("CharlaReview.reviews.review2.name")'></h2>
                <p class="review__carousel__item__date">{{$t("CharlaReview.reviews.review2.date")}}</p>
              </div>
              <div class="review__carousel__text">
                {{$t("CharlaReview.reviews.review2.text")}}
              </div>
            </div>
          </div>
          <div class="review__carousel__item">
            <div class="review__carousel__item__container">
              <svg class="review__carousel__item__corner_green" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32 2.54313e-06L0 0C17.6731 7.72516e-07 32 14.3271 32 32L32 2.54313e-06Z" fill="white" />
              </svg>
              <svg class="review__carousel__item__corner_white" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32 2.54313e-06L0 0C17.6731 7.72516e-07 32 14.3271 32 32L32 2.54313e-06Z" fill="white" />
              </svg>
              <div class="review__carousel__item__title__wrapper">
                <h2 class="review__carousel__item__title" v-html='$t("CharlaReview.reviews.review3.name")'></h2>
                <p class="review__carousel__item__date">{{$t("CharlaReview.reviews.review3.date")}}</p>
              </div>
              <div class="review__carousel__text">
                {{$t("CharlaReview.reviews.review3.text")}}
              </div>
            </div>
          </div>
          <div class="review__carousel__item">
            <div class="review__carousel__item__container">
              <svg class="review__carousel__item__corner_green" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32 2.54313e-06L0 0C17.6731 7.72516e-07 32 14.3271 32 32L32 2.54313e-06Z" fill="white" />
              </svg>
              <svg class="review__carousel__item__corner_white" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32 2.54313e-06L0 0C17.6731 7.72516e-07 32 14.3271 32 32L32 2.54313e-06Z" fill="white" />
              </svg>
              <div class="review__carousel__item__title__wrapper">
                <h2 class="review__carousel__item__title" v-html='$t("CharlaReview.reviews.review4.name")'></h2>
                <p class="review__carousel__item__date">{{$t("CharlaReview.reviews.review4.date")}}</p>
              </div>
              <div class="review__carousel__text">
                {{$t("CharlaReview.reviews.review4.text")}}
              </div>
            </div>
          </div>
        </div>
        <div class="carousel__button__wrapper">
          <button :class="this.hiddenIndex > 0 ? 'carousel__button__back_active' : 'carousel__button__back_disabled'" class="carousel__button carousel__button__back" @click="scrollCarousel">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="white" />
            </svg>
          </button>
          <button :class="this.hideArrow ? 'carousel__button_disabled' : 'carousel__button_active'" class="carousel__button" @click="scrollCarousel('right')">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="white" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CharlaReview",
  data() {
    return {
      hiddenIndex: 0,
      hideArrow: false
    }
  },
  methods: {
    scrollCarousel(direction='left'){
      const carouselItems = [...document.querySelectorAll('.review__carousel__item')]
      const carouselItemsContainer = [...document.querySelectorAll('.review__carousel__item__container')]
      const carouselButtons = [...document.querySelectorAll('.carousel__button')]

      carouselButtons.forEach(e => {
        e.style.pointerEvents = 'none'
        setTimeout(() => {
          e.style.pointerEvents = 'all'
        }, 500)
      })

      if (direction === 'right'){
        carouselItemsContainer.forEach((e,i) => {
          if (i === this.hiddenIndex){
            e.classList.add('disappear')
          } else if (i !== this.hiddenIndex && !e.classList.contains('disappear')){
            carouselItems[i].style.transition = '500ms transform ease'
            carouselItems[i].style.transform = `translate(calc(-${100 * (this.hiddenIndex + 1)}% - ${16 * (this.hiddenIndex + 1)}px), 0)`
          }
        })

        this.hiddenIndex += 1
      } else {
        this.hiddenIndex -= 1

        carouselItemsContainer.forEach((e, i) => {
          if (i === this.hiddenIndex){
            e.classList.add('appear')
            setTimeout(() => {
              e.classList.remove('disappear')
              e.classList.remove('appear')
            }, 500)
          } else if (i !== this.hiddenIndex && !e.classList.contains('disappear')) {
            carouselItems[i].style.transition = '500ms transform ease'
            carouselItems[i].style.transform = `translate(calc(-${100 * (this.hiddenIndex)}% - ${16 * (this.hiddenIndex)}px), 0)`
          }
        })

      }

      const carousel = document.querySelector('.review__carousel');
      carousel.dataset.hiddenIndex = this.hiddenIndex
    }
  },
  mounted() {
    const splideContainer= document.querySelector('.splide')

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          splideContainer.dataset.stopScroll = 'false'
          this.hideArrow = true
        } else {
          splideContainer.dataset.stopScroll = 'true'
          this.hideArrow = false
        }
      })
    }, {
      threshold: 1
    });

    let money__statistic__carouselItem = [...document.querySelectorAll('.review__carousel__item')];
    money__statistic__carouselItem = money__statistic__carouselItem[money__statistic__carouselItem.length - 1]
    observer.observe(money__statistic__carouselItem)

    const carousel = document.querySelector('.review__carousel')
    this.hiddenIndex = +carousel.dataset.hiddenIndex


    const config = {
      attributes: true,
    };
    const callback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes'){
          this.hiddenIndex = +carousel.dataset.hiddenIndex
        }
      })
    }

    const mutationObserver = new MutationObserver(callback)
    mutationObserver.observe(carousel, config)
  }
}
</script>

<style scoped lang="scss">
  .block__title__icon{
    background-color: var(--green);
    padding: 4px 0;

    & > svg{
      width: 100%;
      height: 100%;
    }
  }

  .review__slogan{
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;
    max-width: 368px;
  }

  .review__numbers{
    display: grid;
    grid-template: auto auto / auto 1fr;
    column-gap: 47px;
    row-gap: 8px;

    &__title{
      font-weight: 700;
      font-size: 56px;
      line-height: 100%;

      &_purple{
        color: var(--purple);
      }

      &_green{
        color: var(--green);
      }
    }

    &__text{
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #000;
    }
  }

  .block__right{
    position: relative;
  }
  .review__carousel{
    position: absolute;
    left: -8s0px;
    top: 50%;
    transform: translate(0, -50%);

    &__wrapper{
      display: flex;
      gap: 16px;
      position: relative;
    }

    &__text{
      display: flex;
      align-items: flex-end;
      font-weight: 400;
      font-size: 14px;
      line-height: 157%;
      color: #000;
    }

    &__item{
      width: 360px;
      height: 360px;
      display: grid;
      place-items: center;

      &__container{
        background-color: white;
        border-radius: 0 32px 32px 32px;
        padding: 32px;
        display: grid;
        grid-template: auto 1fr / 1fr;
        position: relative;
        box-shadow: inset 8px 0 0 0 var(--green);
        width: 100%;
        height: 100%;
      }


      &__corner_white{
        position: absolute;
        left: -24px;
        top: 0;
        z-index: 2;

        & > path{
          fill: white;
        }
      }

      &__corner_green{
        position: absolute;
        left: -32px;
        top: 0;
        z-index: 1;

        & > path{
          fill: var(--green);
        }
      }

      &__date{
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #000;
      }



      &__title{
        font-weight: 600;
        font-size: 22px;
        line-height: 127%;
        color: #000;

        &__wrapper{
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
</style>