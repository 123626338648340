<template>
  <div class="block advantages" data-animation-trigger="true">
    <div class="block__left" data-animation="true" data-animation-type="move-up">
      <h2 class="block__title">
        <span class="block__title__colored">
          <span class="block__title__icon">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 104 24" style="enable-background:new 0 0 104 24;" xml:space="preserve">
              <path class="st0" d="M16,22.6l0.7,0.7L16,24l-0.7-0.7L16,22.6L16,22.6L16,22.6z M12.7,20.7L14,22l0.7-0.7L13.4,20L12.7,20.7z M10,18
                l1.3,1.3l0.7-0.7l-1.3-1.3L10,18z M7.3,15.3l1.3,1.3L9.4,16L8,14.6L7.3,15.3z M4.7,12.7L6,14l0.7-0.7L5.4,12L4.7,12.7z M2,10
                l1.3,1.3L4,10.6L2.7,9.3L2,10z M0.7,7.3L0,8l0.7,0.7L1.4,8l0,0l0,0L0.7,7.3z M2,6l0.7,0.7L4,5.4L3.3,4.7L2,6z M4.7,3.3L5.4,4
                l1.3-1.3L6,2L4.7,3.3z M8,1.3L8,1.3l0.7-0.6L8,0L7.3,0.7L8,1.3L8,1.3z M11.3,3.3L10,2L9.3,2.7L10.6,4L11.3,3.3z M14,6l-1.3-1.3
                L12,5.4l1.3,1.3L14,6z M17.4,8l-0.7-0.7L16,8l-0.7-0.7L14.6,8L16,9.4L17.4,8z M20,5.4l-0.7-0.7L18,6l0.7,0.7L20,5.4z M22.7,2.7L22,2
                l-1.3,1.3L21.4,4L22.7,2.7z M23.3,0.7L24,1.4l0,0l0,0l0.7-0.7L24,0L23.3,0.7z M25.3,2.7L26.6,4l0.7-0.7L26,2L25.3,2.7z M28,5.4
                l1.3,1.3L30,6l-1.3-1.3L28,5.4z M30.6,8L30.6,8L30.6,8l0.7,0.7L32,8l-0.7-0.7L30.6,8z M28,10.6l0.7,0.7L30,10l-0.7-0.7L28,10.6z
                M25.3,13.3L26,14l1.3-1.3L26.6,12L25.3,13.3z M22.6,16l0.7,0.7l1.3-1.3L24,14.6L22.6,16z M20,18.6l0.7,0.7L22,18l-0.7-0.7L20,18.6z
                M17.3,21.3L18,22l1.3-1.3L18.6,20L17.3,21.3z M52,22.7L52,22.7l-0.7,0.6L52,24l0.7-0.7L52,22.7L52,22.7z M48.7,20.7L50,22l0.7-0.7
                L49.4,20L48.7,20.7z M46,18l1.3,1.3l0.7-0.7l-1.3-1.3L46,18z M43.3,15.3l1.3,1.3l0.7-0.7L44,14.6L43.3,15.3z M40.7,12.7L42,14
                l0.7-0.7L41.4,12L40.7,12.7z M38,10l1.3,1.3l0.7-0.7l-1.3-1.3L38,10z M36.7,7.3L36,8l0.7,0.7L37.4,8l0,0l0,0L36.7,7.3z M38,6
                l0.7,0.7L40,5.4l-0.7-0.7L38,6z M40.7,3.3L41.4,4l1.3-1.3L42,2L40.7,3.3z M44,1.3L44,1.3l0.7-0.6L44,0l-0.7,0.7L44,1.3L44,1.3z
                M47.3,3.3L46,2l-0.7,0.7L46.6,4L47.3,3.3z M50,6l-1.3-1.3L48,5.4l1.3,1.3L50,6z M53.4,8l-0.7-0.7L52,8l-0.7-0.7L50.6,8L52,9.4
                L53.4,8z M56,5.4l-0.7-0.7L54,6l0.7,0.7L56,5.4z M58.7,2.7L58,2l-1.3,1.3L57.4,4L58.7,2.7z M59.3,0.7L60,1.4l0,0l0,0l0.7-0.7L60,0
                L59.3,0.7z M61.3,2.7L62.6,4l0.7-0.7L62,2L61.3,2.7z M64,5.4l1.3,1.3L66,6l-1.3-1.3L64,5.4z M66.6,8L66.6,8L66.6,8l0.7,0.7L68,8
                l-0.7-0.7L66.6,8z M64,10.6l0.7,0.7L66,10l-0.7-0.7L64,10.6z M61.3,13.3L62,14l1.3-1.3L62.6,12L61.3,13.3z M58.6,16l0.7,0.7l1.3-1.3
                L60,14.6L58.6,16z M56,18.6l0.7,0.7L58,18l-0.7-0.7L56,18.6z M53.3,21.3L54,22l1.3-1.3L54.6,20L53.3,21.3z M88,22.7L88,22.7
                l-0.7,0.6L88,24l0.7-0.7L88,22.7L88,22.7z M84.7,20.7L86,22l0.7-0.7L85.4,20L84.7,20.7z M82,18l1.3,1.3l0.7-0.7l-1.3-1.3L82,18z
                M79.3,15.3l1.3,1.3l0.7-0.7L80,14.6L79.3,15.3z M76.7,12.7L78,14l0.7-0.7L77.4,12L76.7,12.7z M74,10l1.3,1.3l0.7-0.7l-1.3-1.3
                L74,10z M72.7,7.3L72,8l0.7,0.7L73.4,8l0,0l0,0L72.7,7.3z M74,6l0.7,0.7L76,5.4l-0.7-0.7L74,6z M76.7,3.3L77.4,4l1.3-1.3L78,2
                L76.7,3.3z M80,1.3L80,1.3l0.7-0.6L80,0l-0.7,0.7L80,1.3L80,1.3z M83.3,3.3L82,2l-0.7,0.7L82.6,4L83.3,3.3z M86,6l-1.3-1.3L84,5.4
                l1.3,1.3L86,6z M89.4,8l-0.7-0.7L88,8l-0.7-0.7L86.6,8L88,9.4L89.4,8z M92,5.4l-0.7-0.7L90,6l0.7,0.7L92,5.4z M94.7,2.7L94,2
                l-1.3,1.3L93.4,4L94.7,2.7z M95.3,0.7L96,1.4l0,0l0,0l0.7-0.7L96,0L95.3,0.7z M97.3,2.7L98.6,4l0.7-0.7L98,2L97.3,2.7z M100,5.4
                l1.3,1.3L102,6l-1.3-1.3L100,5.4z M103.3,7.3L102.6,8l0,0l0,0l0.7,0.7L104,8L103.3,7.3z M100,10.6l0.7,0.7L102,10l-0.7-0.7L100,10.6
                z M97.3,13.3L98,14l1.3-1.3L98.6,12L97.3,13.3z M94.6,16l0.7,0.7l1.3-1.3L96,14.6L94.6,16z M92,18.6l0.7,0.7L94,18l-0.7-0.7L92,18.6
                z M89.3,21.3L90,22l1.3-1.3L90.6,20L89.3,21.3z" fill="#FFF"/>
            </svg>
          </span>
          {{ $t("CharlaAdvantages.title_part1") }}
        </span>
        <span class="block__title__colored">{{ $t("CharlaAdvantages.title_part2") }}</span>
      </h2>
    </div>
    <div class="block__right" data-animation="true" data-animation-type="move-up">
      <div class="advantages__carousel" data-carousel="true" data-hidden-index="0">
        <div class="advantages__carousel__wrapper">
          <div class="advantages__carousel__item">
            <div class="advantages__carousel__item__container advantages__carousel__item_red">
              <div class="advantages__carousel__item__title__wrapper">
                <h2 class="advantages__carousel__item__title">{{ $t("CharlaAdvantages.slider.carouselItem1.title") }}</h2>
              </div>
              <p class="advantages__carousel__item__text" v-html='$t("CharlaAdvantages.slider.carouselItem1.text")'>
              </p>
            </div>
          </div>
          <div class="advantages__carousel__item">
            <div class="advantages__carousel__item__container advantages__carousel__item_purple">
              <div class="advantages__carousel__item__title__wrapper">
                <h2 class="advantages__carousel__item__title">{{ $t("CharlaAdvantages.slider.carouselItem2.title") }}</h2>
              </div>
              <p class="advantages__carousel__item__text" v-html='$t("CharlaAdvantages.slider.carouselItem2.text")'>
              </p>
            </div>
          </div>
          <div class="advantages__carousel__item">
            <div class="advantages__carousel__item__container advantages__carousel__item_green">
              <div class="advantages__carousel__item__title__wrapper">
                <h2 class="advantages__carousel__item__title">{{ $t("CharlaAdvantages.slider.carouselItem3.title") }}</h2>
              </div>
              <p class="advantages__carousel__item__text" v-html='$t("CharlaAdvantages.slider.carouselItem3.text")'>
              </p>
            </div>
          </div>
          <div class="advantages__carousel__item">
            <div class="advantages__carousel__item__container advantages__carousel__item_black">
              <div class="advantages__carousel__item__title__wrapper">
                <h2 class="advantages__carousel__item__title">{{ $t("CharlaAdvantages.slider.carouselItem4.title") }}</h2>
              </div>
              <div class="advantages__carousel__item__buttons">
                <a href="https://app.charla-ai.com/auth/sign-up/?&lang=RU" target="_blank"class="advantages__carousel__item__test">Тестировать&nbsp;сейчас</a>
                <button class="advantages__carousel__item__form" @click="setFormState(true)">Задать вопрос</button>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel__button__wrapper">
          <button :class="this.hiddenIndex > 0 ? 'carousel__button__back_active' : 'carousel__button__back_disabled'" class="carousel__button carousel__button__back" @click="scrollCarousel">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="white" />
            </svg>
          </button>
          <button :class="this.hideArrow ? 'carousel__button_disabled' : 'carousel__button_active'" class="carousel__button" @click="scrollCarousel('right')">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="white" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tr from "@/i18n/translation";
import {mapActions} from "vuex";

export default {
  name: "CharlaAdvantages",
  data() {
    return {
      hiddenIndex: 0,
      hideArrow: false
    }
  },
  computed:{
    Tr() {
      return Tr
    },
  },
  methods: {
    ...mapActions(['setFormState']),

    scrollCarousel(direction='left'){
      const carouselItems = [...document.querySelectorAll('.advantages__carousel__item')]
      const carouselItemsContainer = [...document.querySelectorAll('.advantages__carousel__item__container')]
      const carouselButtons = [...document.querySelectorAll('.carousel__button')]

      carouselButtons.forEach(e => {
        e.style.pointerEvents = 'none'
        setTimeout(() => {
          e.style.pointerEvents = 'all'
        }, 500)
      })

      if (direction === 'right'){
        carouselItemsContainer.forEach((e,i) => {
          if (i === this.hiddenIndex){
            e.classList.add('disappear')
          } else if (i !== this.hiddenIndex && !e.classList.contains('disappear')){
              carouselItems[i].style.transition = '500ms transform ease'
              carouselItems[i].style.transform = `translate(calc(-${100 * (this.hiddenIndex + 1)}% - ${16 * (this.hiddenIndex + 1)}px), 0)`
          }
        })

        this.hiddenIndex += 1
      } else {
        this.hiddenIndex -= 1

        carouselItemsContainer.forEach((e, i) => {
          if (i === this.hiddenIndex){
            e.classList.add('appear')
            setTimeout(() => {
              e.classList.remove('disappear')
              e.classList.remove('appear')
            }, 500)
          } else if (i !== this.hiddenIndex && !e.classList.contains('disappear')) {
            carouselItems[i].style.transition = '500ms transform ease'
            carouselItems[i].style.transform = `translate(calc(-${100 * (this.hiddenIndex)}% - ${16 * (this.hiddenIndex)}px), 0)`
          }
        })

      }

      const carousel = document.querySelector('.advantages__carousel');
      carousel.dataset.hiddenIndex = this.hiddenIndex
    }
  },
  mounted() {
    const splideContainer= document.querySelector('.splide')

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          splideContainer.dataset.stopScroll = 'false'
          this.hideArrow = true
        } else {
          splideContainer.dataset.stopScroll = 'true'
          this.hideArrow = false
        }
      })
    }, {
      threshold: 1
    });

    let money__statistic__carouselItem = [...document.querySelectorAll('.advantages__carousel__item')];
    money__statistic__carouselItem = money__statistic__carouselItem[money__statistic__carouselItem.length - 1]
    observer.observe(money__statistic__carouselItem)

    const carousel = document.querySelector('.advantages__carousel')
    this.hiddenIndex = +carousel.dataset.hiddenIndex


    const config = {
      attributes: true,
    };
    const callback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes'){
          this.hiddenIndex = +carousel.dataset.hiddenIndex
        }
      })
    }

    const mutationObserver = new MutationObserver(callback)
    mutationObserver.observe(carousel, config)
  }
}
</script>

<style scoped lang="scss">
  .block__title__colored{
    color: #ff6332;
  }

  .block__title__icon{
    background-color: var(--red);
    overflow: hidden;
    padding: 0;
    position: relative;

    & > svg{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 104px;
      height: 24px;
    }
  }

  .advantages .block__title{
    font-weight: 400;
    font-size: 52px;
    line-height: 123%;
    color: #ff6332;
  }

  .block__right{
    position: relative;
  }

  .advantages__slogan{
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;
  }

  .advantages__link{
    font-weight: 600;
    font-size: 22px;
    line-height: 127%;
    color: #000;

    & > span{
      color: var(--red);
    }
  }

  .advantages__carousel{
    position: absolute;
    left: -80px;
    top: 50%;
    transform: translate(0, -50%);

    &__wrapper{
      display: flex;
      gap: 16px;
      position: relative;
    }

    &__item{
      width: 400px;
      height: 202px;
      display: grid;
      place-items: center;

      &__buttons{
        display: flex;
        gap: 4px;
      }

      &__form{
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #000;
        background: #fff;
        border-radius: 24px;
        padding: 8px 14px;
        height: fit-content;
        width: 100%;
      }

      &__test{
        font-weight: 600;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #fff;
        background: #ff6332;
        border-radius: 24px;
        padding: 8px 14px;
        height: fit-content;
        width: 100%;
      }

      &__container{
        display: grid;
        grid-template: auto 1fr / 1fr;
        row-gap: 16px;
        border-radius: 32px;
        padding: 32px;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
      }

      &__text{
        font-weight: 400;
        font-size: 14px;
        line-height: 157%;
        color: #fff;
      }

      &__title{
        font-weight: 600;
        font-size: 22px;
        line-height: 127%;
        color: #000;

        &__wrapper{
          display: flex;
          justify-content: space-between;
        }
      }

      &_black{
        background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .advantages__carousel__item__title{
          color: #fff;
        }
      }

      &_purple{
        background-color: var(--purple);
      }

      &_red{
        background-color: var(--red);
      }

      &_green{
        background-color: var(--green);
      }
    }
  }

  .carousel__button{
    background-color: var(--red);

      &__back{
      background-color: black;
    }
  }
  .langs-btn{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    height: 40px;
    border-radius: 24px;
    background: #FF6332;
    color: #fff;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
  }

  @media screen and (max-width: 1650px){
    .advantages__carousel{
      left: 0;
    }
  }

  @media screen and (max-width: 1300px) {
     .advantages .block__title{
      font-size: 36px;
     }
  }

  @media screen and (max-width: 1000px){
    .carousel__button__wrapper {
      width: 420px;
    }
  }

  @media screen and (max-width: 900px){
    .carousel__button__wrapper {
      width: 360px;
    }
  }

  @media screen and (max-width: 830px){
    .advantages .block__title{
      font-size: 30px;
    }

    .advantages__carousel__item{
      width: 300px;
    }
    .advantages__carousel__item__title{
      font-size: 16px;
    }
    .advantages__carousel__item__text{
      font-size: 12px;
    }

    .advantages__carousel__item__container{
      padding: 16px;
    }

    .advantages__carousel__item__buttons{
      display: flex;
      flex-direction: column;
    }

    .advantages__carousel__item__test,
    .advantages__carousel__item__form{
      width: fit-content;
    }
  }
  
  @media screen and (max-width: 770px){
    .carousel__button__wrapper {
      width: 320px;
    }
  }
</style>