<template>
  <div class="block prices" data-animation-trigger="true">
    <div class="block__left" data-animation="true" data-animation-type="move-up">
      <h2 class="block__title">
        <span>{{ $t("CharlaPrice.title_part1") }}</span>
        <span><b>{{ $t("CharlaPrice.title_part2") }}</b></span>
      </h2>
    </div>
    <div class="block__right" data-animation="true" data-animation-type="move-up">
      <div class="price__carousel">
        <div class="price__carousel__item price__carousel__item_black">
          <div class="price__carousel__item__title__wrapper">
            <h2 class="price__carousel__item__title">{{ $t("CharlaPrice.Rates.rate1.title") }}</h2>
            <p class="price__carousel__item__price">
              <span>{{ $t("CharlaPrice.Rates.rate1.price") }}</span>
              {{ $t("CharlaPrice.Rates.rate1.time") }}
            </p>
            <h2 class="price__carousel__item__slogan">
              Для физлиц (личный аккаунт)
            </h2>
          </div>
          <ul class="price__carousel__item__list unlimited">
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              5 дней бесплатного безлимита: загружайте неограниченное количество аудио- и видеофайлов любой длительности и размера
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              Реферальная программа — приглашайте друзей и получите 30+ дней полного бесплатного безлимита
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              1 поток, 1 файл в очереди
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              Нет ограничений по весу и длительности файлов
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              Нет ограничений по количеству файлов
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              Загрузка с устройства, по ссылке, запись аудио с микрофона, запись с экрана
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              Техподдержка 24/7
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              Разделение на спикеров
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              Тайм-коды
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              Субтитры (расшифровка с тайм-кодами в формат .srt)
            </li>
          </ul>
          <a class="price__carousel__item__link" href="https://app.charla-ai.com/sign-in/" target="_blank">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0H8V6H0V8H8V14H10V8H18V6H10V0Z" fill="white" />
            </svg>
            {{ $t("CharlaPrice.Rates.rate1.btn") }}
          </a>
        </div>
        <div class="price__carousel__item price__carousel__item_purple">
          <div class="price__carousel__item__title__wrapper">
            <h2 class="price__carousel__item__title">{{ $t("CharlaPrice.Rates.rate2.title") }}</h2>
            <p class="price__carousel__item__price">
              {{ $t("CharlaPrice.Rates.rate2.time") }}
            </p>
            <h2 class="price__carousel__item__slogan">
              Для юрлиц (бизнес-аккаунт)
            </h2>
          </div>
          <ul class="price__carousel__item__list">
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="black" />
              </svg>
              Для редакций СМИ
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="black" />
              </svg>
              Для продюсерских центров
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="black" />
              </svg>
              Для отделов продаж небольших компаний (до 10 человек)
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="black" />
              </svg>
              Для ВУЗов
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="black" />
              </svg>
              Для коучинговых центров
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="black" />
              </svg>
              Для разработчиков мессенджеров, CRM, HRM и других решений — интеграция по API
            </li>
          </ul>
          <div class="price__carousel__item__link" @click="setFormState(true)">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0H8V6H0V8H8V14H10V8H18V6H10V0Z" fill="white" />
            </svg>
            {{ $t("CharlaPrice.Rates.rate2.btn") }}
          </div>
        </div>
        <div class="price__carousel__item price__carousel__item_gray">
          <div class="price__carousel__item__title__wrapper">
            <h2 class="price__carousel__item__title">{{ $t("CharlaPrice.Rates.rate3.title") }}</h2>
            <p class="price__carousel__item__price" style="color: #000">
              <span style="color: #B561F6">{{ $t("CharlaPrice.Rates.rate3.price") }}</span>
              {{ $t("CharlaPrice.Rates.rate3.time") }}
            </p>
            <h2 class="price__carousel__item__slogan">
              Для физлиц (личный аккаунт)
            </h2>
          </div>
          <ul class="price__carousel__item__list">
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              5 дней бесплатного безлимита: загружайте неограниченное количество аудио- и видеофайлов любой длительности и размера
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              Оплата только за фактическое использование
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              1 поток, 1 файл в очереди
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              Нет ограничений по весу и длительности файлов
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              Загрузка с устройства, по ссылке, запись аудио с микрофона, запись с экрана
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              Техподдержка 24/7
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              Разделение на спикеров
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              Тайм-коды
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              Субтитры (расшифровка с тайм-кодами в формат .srt)
            </li>
          </ul>
          <a href="https://app.charla-ai.com/sign-in/" target="_blank" class="price__carousel__item__link">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0H8V6H0V8H8V14H10V8H18V6H10V0Z" fill="white" />
            </svg>
            {{ $t("CharlaPrice.Rates.rate3.btn") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import Tr from "@/i18n/translation";
export default {
  name: "CharlaPrice",
  computed: {
    Tr() {
      return Tr
    }
  },
  mounted() {
    const splideContainer= document.querySelector('.splide')

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          splideContainer.dataset.stopScroll = 'false'
        } else {
          splideContainer.dataset.stopScroll = 'true'
        }
      })
    }, {
      threshold: 1
    });

    let carouselItem = [...document.querySelectorAll('.price__carousel__item')];
    carouselItem = carouselItem[carouselItem.length - 1]
    observer.observe(carouselItem)
  },
  methods:{
    ...mapActions(['setFormState'])
  }
}
</script>

<style scoped lang="scss">
  .block__right{
    position: relative;
    z-index: 2;
  }
  .prices .block__title{
    transition: 500ms ease opacity;
  }

  .price__carousel{
    position: absolute;
    display: flex;
    gap: 16px;
    left: -280px;
    top: 50%;
    transform: translate(0, -50%);
    transition: 500ms ease left;

    &__item{
      width: 400px;
      height: auto;
      border-radius: 32px;
      padding: 16px;
      display: grid;
      grid-template: auto 1fr auto / 1fr;
      row-gap: 16px;

      &__slogan{
        grid-area: 2 / 1 / 2 / span 2;
      }

      &__link{
        display: flex;
        gap: 16px;
        justify-content: center;
        align-items: center;
        padding: 13px 0;
        background-color: black;
        border-radius: 24px;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        line-height: 157%;
        color: #fff;
      }

      &__title{
        font-weight: 600;
        font-size: 22px;
        line-height: 127%;
        color: #fff;

        &__wrapper{
          display: grid;
          grid-template: auto auto / 1fr auto;
          align-items: center;
          row-gap: 8px;
        }
      }

      &__price{
        font-weight: 400;
        font-size: 14px;
        line-height: 157%;
        color: var(--light-bg);

        & > span{
          font-weight: 600;
          font-size: 22px;
          line-height: 127%;
          color: var(--purple);
        }
      }

      &__list{
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__item{
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          color: #fff;
          display: grid;
          grid-template: 1fr / 20px auto 1fr;
          align-items: center;
          gap: 16px;

          &__beta{
            font-weight: 600;
            font-size: 10px;
            line-height: 130%;
            padding: 4px 8px;
            border-radius: 24px;
            background-color: var(--red);
            width: fit-content;
            color: #fff;
          }
        }
      }

      &_black{
        background-color: black;

        & .price__carousel__item__slogan{
          color: var(--purple);
          font-weight: 600;
          font-size: 14px;
          line-height: 157%;
        }

        & > .price__carousel__item__link{
          background-color: var(--purple);
        }
      }

      &_purple{
        background-color: var(--purple);

        & .price__carousel__item__slogan{
          color: black;
          font-weight: 600;
          font-size: 14px;
          line-height: 157%;
        }
      }

      &_gray{
        background-color: var(--light-bg);

        & .price__carousel__item__slogan{
          color: var(--purple);
          font-weight: 600;
          font-size: 14px;
          line-height: 157%;
        }

        & .price__carousel__item__list{
          justify-content: flex-start;
          gap: 8px;
        }
        & .price__carousel__item__title{
          color: black;
        }

        & .price__carousel__item__list__item{
          color: black;
        }
      }
    }
  }


  @media screen and (max-width: 1300px){
    .price__carousel {
      left: -200px;
    }
  }
  @media screen and (max-height: 750px) {
    .price__carousel__item {
      row-gap: 8px;
      padding: 16px 32px;
    }
    .price__carousel__item_gray .price__carousel__item__list{
      gap: 8px;
    }
    .price__carousel__item__list{
      gap: 8px;
    }
    .price__carousel{
      top: 53%;
    }
  }
</style>