<template>
  <div class="cookie__popup" v-show="getCookiePopup">
    <div class="cookie__background" @click="closeCookie()"></div>
    <div class="cookie__close" @click="closeCookie()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.42 10L20 18.58L18.58 20L10 11.42L1.42 20L0 18.58L8.58 10L0 1.42L1.42 0L10 8.58L18.58 0L20 1.42L11.42 10Z"
            fill="black"/>
      </svg>
    </div>
    <div class="cookie__wrapper">
      <div class="cookie__container">
        <h2 class="cookie__title">Политика работы с Cookies</h2>
        <div class="cookie__block">
          <h2 class="cookie__title_small">1. Общие положения</h2>
          <p class="cookie__text">Приветствуем Вас в сервисе «Звук в букву».<br>Мы используем файлы cookie для того,
            чтобы взаимодействие с сайтом для пользователей было удобным и эффективным.</p>
          <p class="cookie__text">В данной документации подробно описаны виды файлов cookie, которые мы применяем, их
            назначение, а также способы, с помощью которых вы можете адаптировать их использование к своим
            предпочтениям.</p>
        </div>
        <div class="cookie__block">
          <h2 class="cookie__title_small">2. Что такое файлы cookie</h2>
          <p class="cookie__text">Файлы cookie представляют собой небольшие текстовые файлы, которые сохраняются на
            вашем устройстве во время посещения сайта.</p>
          <p class="cookie__text">Cookie содержат информацию о ваших действиях на веб-сайте, а также могут содержать
            сведения о вашем оборудовании, дате и времени сессии.</p>
        </div>
        <div class="cookie__block">
          <h2 class="cookie__title_small">3. Использование файлов cookie на сайте «Звук в букву»</h2>
          <p class="cookie__text"><b>Использование файлов cookie на сайте «Звук в букву»</b></p>
          <br>
          <ul class="cookie__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="cookie__text">Основные файлы cookie — данные файлы необходимы для функционирования нашего сайта.
                Они обеспечивают базовое перемещение по сайту и доступ к защищенным разделам, являясь ключевыми для
                основных функций сайта.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="cookie__text">Сессионные файлы cookie — работают в течение вашего активного визита и хранятся
                только пока открыт веб-сайт. Они помогают сделать взаимодействие с сайтом более эффективным, запоминая
                выбор на предыдущих страницах и избавляя от необходимости повторного ввода информации.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="cookie__text">Функциональные файлы cookie — данные файлы помогают запоминать ваши настройки и
                предпочтения (например, выбранный язык или регион), что делает ваше взаимодействие с сайтом более
                удобным и личностно ориентированным.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="cookie__text">Аналитические файлы cookie — собирают агрегированную информацию о том, как
                посетители используют наш сайт. Это позволяет нам улучшать его структуру, удобство использования и
                содержание, анализируя, как пользователи взаимодействуют с сайтом и какие страницы посещают наиболее
                часто.</p>
            </li>
          </ul>
        </div>
        <div class="cookie__block">
          <h2 class="cookie__title_small">4. Управление файлами cookie</h2>
          <p class="cookie__text">Вы можете управлять своими предпочтениями в отношении файлов cookie через настройки
            вашего браузера.</p>
          <p class="cookie__text">Большинство веб-браузеров автоматически принимают файлы cookie, но предоставляют вам
            возможность изменить это поведение. Вы можете настроить браузер на предупреждение об отправке файлов cookie
            или на блокировку их приема.</p>
          <p class="cookie__text">Обратите внимание, что блокировка или удаление файлов cookie может негативно сказаться
            на функциональности сайта. Некоторые функции могут стать недоступны, а некоторые страницы могут не
            отображаться корректно.</p>
        </div>
        <div class="cookie__block">
          <h2 class="cookie__title_small">5. Удаление файлов cookie</h2>
          <p class="cookie__text">Удалить файлы cookie, уже сохраненные на вашем устройстве, можно через настройки
            вашего браузера. Удаление файлов cookie приведет к удалению информации о ваших действиях на сайтах, а также
            может потребовать повторного ввода информации, ранее сохраненной в файлах cookie.</p>
        </div>
        <div class="cookie__block">
          <h2 class="cookie__title_small">6. Изменения в политике использования файлов cookie</h2>
          <p class="cookie__text">Команда «Звук в букву» оставляет за собой право при необходимости обновлять настоящую
            политику использования файлов cookie, чтобы она соответствовала новым требованиям и стандартам безопасности.
            Любые изменения в политике будут публиковаться на нашем сайте. Мы рекомендуем пользователям периодически
            просматривать политику, чтобы быть в курсе любых обновлений или изменений.</p>
        </div>
        <div class="cookie__block">
          <h2 class="cookie__title_small">7. Обратная связь</h2>
          <p class="cookie__text">Если у вас возникли вопросы относительно нашей политики использования файлов cookie,
            пожалуйста, свяжитесь с нами по электронной почте <a href="mailto:welcome@zv-ai.ru">welcome@zv-ai.ru.</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tr from "@/i18n/translation";
import {mapGetters, mapActions} from "vuex";

export default {
  methods: {
    ...mapActions(['setCookiePopup']),
    closeCookie() {
      const cookieWrapper = document.querySelector('.cookie__wrapper');
      const cookieBackground = document.querySelector('.cookie__background');
      const cookieClose = document.querySelector('.cookie__close')

      cookieWrapper.classList.add('cookie__wrapper__disable');
      cookieBackground.classList.add('cookie__background__disable');
      cookieClose.classList.add('cookie__close__disable')


      setTimeout(() => {
        cookieWrapper.classList.remove('cookie__wrapper__disable');
        cookieBackground.classList.remove('cookie__background__disable');
        cookieClose.classList.remove('cookie__close__disable')
        this.setCookiePopup(false)
      }, 500)
    }
  },
  computed: {
    ...mapGetters(["getCookiePopup"]),
    Tr() {
      return Tr
    }
  }
}
</script>

<style lang="scss" scoped>
.cookie {
  &__background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    animation: 500ms ease forwards __fadeIn;

    &__disable {
      animation: 500ms ease forwards __fadeOut;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;

    & .cookie__text:last-of-type {
      padding: 0;
    }
  }

  &__close {
    position: fixed;
    background-color: white;
    top: 48px;
    left: calc(50% + 418px);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 100000;
    transform: translate(50%, 0);
    display: grid;
    place-items: center;
    cursor: pointer;
    animation: 500ms ease forwards __slideUpClose;

    &__disable {
      animation: 500ms ease forwards __slideDownClose;
    }
  }

  &__wrapper {
    padding: 64px;
    position: fixed;
    left: 50%;
    top: 24px;
    transform: translate(-50%, 0);
    max-width: 836px;
    width: 100%;
    background-color: white;
    height: calc(100vh - 48px);
    overflow: hidden scroll;
    z-index: 10000;
    border-radius: 44px;
    animation: 500ms ease forwards __slideUp;

    &__disable {
      animation: 500ms ease forwards __slideDown;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > li {
      display: grid;
      grid-template: 1fr / 48px 1fr;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #000;

    &_small {
      font-weight: 600;
      font-size: 22px;
      line-height: 127%;
      color: #000;
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 0 0 32px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;
    padding: 0 0 16px;
  }
}

@keyframes __fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes __fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes __slideUp {
  from {
    transform: translate(-50%, 120vh);
  }

  to {
    transform: translate(-50%, 0);
  }
}

@keyframes __slideDown {
  from {
    transform: translate(-50%, 0);
  }

  to {
    transform: translate(-50%, 120vh);
  }
}

@keyframes __slideUpClose {
  from {
    transform: translate(50%, 120vh);
  }

  to {
    transform: translate(50%, 0);
  }
}

@keyframes __slideDownClose {
  from {
    transform: translate(50%, 0);
  }

  to {
    transform: translate(50%, 120vh);
  }
}

@media screen and (max-width: 660px) {
  .cookie__wrapper {
    width: calc(100vw - 16px);
    height: calc(100vh - 16px);
    padding: 0;
    top: 8px;
  }

  .cookie__popup {
    width: 100%;
    height: 100%;
  }

  .cookie__container {
    gap: 30px;
    padding: 20px;
  }

  .cookie__title {
    font-size: 22px;
    margin: 0;

    &_small {
      font-size: 18px;
      margin: 0;
    }
  }

  .cookie__close {
    background: #000;
    top: 18px;
    right: 40px;
    left: unset;
    width: 36px;
    height: 36px;
  }

  .cookie__close svg {
    width: 15px;
    height: 15px;
  }

  .cookie__close svg path {
    fill: #fff;
  }

  .cookie__text {
    margin: 0;
  }
}
</style>