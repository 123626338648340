<template>
  <div class="privacy__popup" v-show="getPrivacyState">
    <div class="privacy__background" @click="closePrivacy()"></div>
    <div class="privacy__close" @click="closePrivacy()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.42 10L20 18.58L18.58 20L10 11.42L1.42 20L0 18.58L8.58 10L0 1.42L1.42 0L10 8.58L18.58 0L20 1.42L11.42 10Z"
            fill="black"/>
      </svg>
    </div>
    <div class="privacy__wrapper">
      <div class="privacy__container" id="ru_privacy">
        <h2 class="privacy__title">{{ $t("privacyPolicy.mainTitle") }}</h2>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">1.</p>
            <h2>{{ $t("privacyPolicy.title1") }}</h2>
          </div>
          <!--          <p class="privacy__text">{{$t("privacyPolicy.block1.text1")}}</p>-->
          <br>
          <ol class="privacy__list">
            <li>
              <p class="privacy__list__number">1.1.</p>
              <p class="privacy__text">Настоящая политика обработки персональных данных составлена в соответствии со ст.18.1 Федерального закона № 152-ФЗ «О персональных данных», с учетом требований Конституции Российской Федерации, федеральных законов и иных нормативных правовых актов Российской Федерации в области персональных данных. Политика определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые работниками ООО «ТИМЛИД» ОГРН 1222300022482 (далее – «Оператор персональных данных» или «Оператор»). </p>
            </li>
            <li>
              <p class="privacy__list__number">1.2</p>
              <p class="privacy__text">Оператор персональных данных стремится сохранять неприкосновенность личной
                информации и конфиденциальность личных данных пользователей и/или посетителей веб-сайта zv-ai.ru (далее
                – «Субъект персональных данных»), в соответствии с применимым законодательством.</p>
            </li>
            <li>
              <p class="privacy__list__number">1.3</p>
              <p class="privacy__text">Загружая наш веб-сайт на своем компьютере, мобильном устройстве или используя
                мобильное приложение, а также заполняя веб-формы, содержащие Ваши персональные данные и регистрируясь в
                качестве пользователя и/или передавая Ваши персональные данные иным способом, Вы добровольно
                предоставляете свое безусловное, информированное и сознательное согласие на сбор, обработку, хранение,
                использование и возможную последующую передачу своих персональных данных неопределенному кругу лиц
                (распространение персональных данных), предоставленных в связи с регистрацией на Сайте и использованием
                его сервисов на условиях, описанных в настоящей Политике обработки и защиты персональных данных (далее –
                «Политика»).</p>
            </li>
            <li>
              <p class="privacy__list__number">1.4</p>
              <p class="privacy__text">Действие настоящей Политики распространяется на процессы обработки персональных
                данных Субъекта персональных данных Оператором с использованием средств автоматизации, в том числе с
                использованием информационно-телекоммуникационных сетей, и без использования таких средств.</p>
            </li>
            <li>
              <p class="privacy__list__number">1.5</p>
              <p class="privacy__text">Оператор персональных данных применяет требуемые меры безопасности для
                обеспечения конфиденциальности персональных данных Субъекта персональных данных от утраты, кражи,
                несанкционированного доступа, неправомерного использования, изменения или уничтожения.</p>
            </li>
            <li>
              <p class="privacy__list__number">1.6</p>
              <p class="privacy__text">Оператор ставит своей важнейшей целью и условием осуществления своей деятельности
                соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты
                прав на неприкосновенность частной жизни, личную и семейную тайну.  </p>
            </li>
            <li>
              <p class="privacy__list__number">1.7</p>
              <p class="privacy__text">Настоящая Политика в отношении обработки персональных данных,  применяется ко
                всей информации, которую Оператор может получить от Субъекта персональных данных .</p>
            </li>
          </ol>
          <br>
          <p class="privacy__text"><span class="privacy__text_colored">ВНИМАНИЕ!</span> Оператор персональных данных
            исходит из того, что Субъект персональных данных действует добросовестно, осмотрительно, предоставляет
            достоверную и достаточную персональную информацию и прилагает все необходимые усилия к поддержанию такой
            информации в актуальном состоянии.</p>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">2.</p>
            <h2>{{ $t("privacyPolicy.title2") }}</h2>
          </div>
          <ol class="privacy__list">
            <li>
              <p class="privacy__list__number">2.1</p>
              <p class="privacy__text">Автоматизированная обработка персональных данных — обработка персональных данных
                с помощью средств вычислительной техники.</p>
            </li>
            <li>
              <p class="privacy__list__number">2.2</p>
              <p class="privacy__text">Блокирование персональных данных — временное прекращение обработки персональных
                данных (за исключением случаев, если обработка необходима для уточнения персональных данных).</p>
            </li>
            <li>
              <p class="privacy__list__number">2.3</p>
              <p class="privacy__text">Веб-сайт — совокупность графических и информационных материалов, а также программ
                для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу
                https://zv-ai.ru/.</p>
            </li>
            <li>
              <p class="privacy__list__number">2.4</p>
              <p class="privacy__text">Информационная система персональных данных — совокупность содержащихся в базах
                данных персональных данных и обеспечивающих их обработку информационных технологий и технических
                средств.</p>
            </li>
            <li>
              <p class="privacy__list__number">2.5</p>
              <p class="privacy__text">Обезличивание персональных данных — действия, в результате которых невозможно
                определить без использования дополнительной информации принадлежность персональных данных конкретному
                Пользователю или иному субъекту персональных данных.</p>
            </li>
            <li>
              <p class="privacy__list__number">2.6</p>
              <p class="privacy__text">Обработка персональных данных — любое действие (операция) или совокупность
                действий (операций), совершаемых с использованием средств автоматизации или без использования таких
                средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение
                (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ),
                обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
            </li>
            <li>
              <p class="privacy__list__number">2.7</p>
              <p class="privacy__text">Оператор — государственный орган, муниципальный орган, юридическое или физическое
                лицо, самостоятельно или совместно с другими лицами организующие и/или осуществляющие обработку
                персональных данных, а также определяющие цели обработки персональных данных, состав персональных
                данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
            </li>
            <li>
              <p class="privacy__list__number">2.8</p>
              <p class="privacy__text">Персональные данные — любая информация, относящаяся прямо или косвенно к
                определенному или определяемому Пользователю веб-сайта https://zv-ai.ru/.</p>

            </li>
            <li>
              <p class="privacy__list__number">2.9</p>
              <p class="privacy__text">Персональные данные, разрешенные субъектом персональных данных для
                распространения — персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом
                персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом
                персональных данных для распространения в порядке, предусмотренном Законом о персональных данных (далее
                — персональные данные, разрешенные для распространения).</p>

            </li>
            <li>
              <p class="privacy__list__number">2.10</p>
              <p class="privacy__text">Пользователь — любой посетитель веб-сайта https://zv-ai.ru/.</p>

            </li>
            <li>
              <p class="privacy__list__number">2.11</p>
              <p class="privacy__text">Предоставление персональных данных — действия, направленные на раскрытие
                персональных данных определенному лицу или определенному кругу лиц.</p>

            </li>
            <li>
              <p class="privacy__list__number">2.12</p>
              <p class="privacy__text">Распространение персональных данных — любые действия, направленные на раскрытие
                персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с
                персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в
                средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление
                доступа к персональным данным каким-либо иным способом.</p>

            </li>
            <li>
              <p class="privacy__list__number">2.13</p>
              <p class="privacy__text">Трансграничная передача персональных данных — передача персональных данных на
                территорию иностранного государства органу власти иностранного государства, иностранному физическому или
                иностранному юридическому лицу.</p>

            </li>
            <li>
              <p class="privacy__list__number">2.14</p>
              <p class="privacy__text">Уничтожение персональных данных — любые действия, в результате которых
                персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания
                персональных данных в информационной системе персональных данных и/или уничтожаются материальные
                носители персональных данных.</p>

            </li>
          </ol>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">3.</p>
            <h2>Оператор персональных данных вправе обрабатывать следующие персональные данные Субъекта персональных
              данных</h2>
          </div>
          <ol class="privacy__list">
            <li>
              <p class="privacy__list__number">3.1</p>
              <p class="privacy__text">Фамилия, имя, отчество (при наличии), дата рождения, пол.</p>
            </li>
            <li>
              <p class="privacy__list__number">3.2</p>
              <p class="privacy__text">Адрес проживания.</p>
            </li>
            <li>
              <p class="privacy__list__number">3.3</p>
              <p class="privacy__text">Контактные данные (телефон, адрес электронной почты).</p>
            </li>
            <li>
              <p class="privacy__list__number">3.4</p>
              <p class="privacy__text">Данные основного документа, удостоверяющего личность.</p>
            </li>
            <li>
              <p class="privacy__list__number">3.5</p>
              <p class="privacy__text">IP-адрес, MAC-адрес.</p>
            </li>
            <li>
              <p class="privacy__list__number">3.6</p>
              <p class="privacy__text">Финансовая информация, включая номер кредитной или дебетовой карты (только
                последние четыре цифры номера карты), или иную платежную информацию.</p>
            </li>
            <li>
              <p class="privacy__list__number">3.7</p>
              <p class="privacy__text">В ситуациях, когда этого требует национальное законодательство, Оператор имеет
                право запросить у Субъекта персональных данных дополнительные данные: документы, подтверждающие место
                его жительства; отсканированные копии удостоверения личности Субъекта персональных данных; фотографию
                Субъекта персональных данных с документом в руках (с образцом его почерка) и иные данные по усмотрению
                Субъекта персональных данных и усмотрению Оператора.</p>
            </li>
            <li>
              <p class="privacy__list__number">3.8</p>
              <p class="privacy__text">Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в
                т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и
                других).  </p>
            </li>
            <li>
              <p class="privacy__list__number">3.9</p>
              <p class="privacy__text">Вышеперечисленные данные далее по тексту Политики объединены общим понятием
                «Персональные данные». </p>
            </li>
            <li>
              <p class="privacy__list__number">3.10</p>
              <p class="privacy__text">Данные, автоматически передающиеся сайту в процессе его использования с помощью
                установленного на устройстве пользователя программного обеспечения, в том числе IP-адрес, информация из
                cookie, информация о браузере пользователя (или иной программе, с помощью которой осуществляется доступ
                к сайту), время доступа, адрес запрашиваемой страницы.  </p>
            </li>
          </ol>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">4.</p>
            <h2>Цели обработки персональных данных  </h2>
          </div>
          <ol class="privacy__list">
            <li>
              <p class="privacy__list__number">4.1</p>
              <p class="privacy__text">Цель обработки персональных данных — информирование Субъекта персональных данных,
                как пользователя услуг, посредством отправки электронных писем, СМС сообщений; предоставление доступа
                пользователю к сервисам, информации и/или материалам, содержащимся на вебсайте; идентификации
                пользователя в рамках взаимоотношений между Оператором и пользователем; идентификации Пользователя в
                рамках Сервисов, предоставляемых Оператором; подтверждения достоверности и полноты персональных данных,
                предоставленных пользователем; создания учетной записи пользователя; обработки и получения платежей от
                пользователя; предоставления пользователю эффективной клиентской и технической поддержки при
                возникновении проблем, связанных с использованием Сайта, приложения и/или Сервисов.</p>
            </li>
            <li>
              <p class="privacy__list__number">4.2</p>
              <p class="privacy__text">Оператор вправе направлять пользователю уведомления о новых продуктах и услугах,
                специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения
                информационных сообщений, направив Оператору письмо на адрес электронной почты welcome@zv-ai.ru с
                пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных предложениях».  </p>
            </li>
            <li>
              <p class="privacy__list__number">4.3</p>
              <p class="privacy__text">Обезличенные данные пользователя, собираемые с помощью сервисов
                интернет-статистики, служат для сбора информации о действиях пользователей на сайте, улучшения качества
                сайта и его содержания.</p>
            </li>

          </ol>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">5.</p>
            <h2>Правовые основания обработки персональных данных</h2>
          </div>
          <ol class="privacy__list">
            <li>
              <p class="privacy__list__number">5.1</p>
              <p class="privacy__text">Оператор обрабатывает персональные данные пользователя услуг только в случае их
                заполнения и/или отправки пользователем самостоятельно через специальные формы, расположенные на сайте
                zv-ai.ru. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору,
                пользователь выражает свое согласие с данной Политикой.  </p>
            </li>
            <li>
              <p class="privacy__list__number">5.2</p>
              <p class="privacy__text">При несогласии с положениями настоящего документа (частично или в целом), лицо,
                выразившее такую волю, не имеет права использовать сервис (сайт) Оператора.</p>
            </li>
            <li>
              <p class="privacy__list__number">5.3</p>
              <p class="privacy__text">Оператор обрабатывает обезличенные данные о пользователе в случае, если это
                разрешено в настройках браузера пользователя (включено сохранение файлов «cookie» и использование
                технологии JavaScript). </p>
            </li>
            <li>
              <p class="privacy__list__number">5.4</p>
              <p class="privacy__text">Обращаем Ваше внимание, что в соответствии с национальным законодательством
                каждой из стран, в которой предоставляются услуги, Оператор обязан хранить регламентированные
                законодательством данные абонентов в течение сроков, указанных в законе.</p>
            </li>
          </ol>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">6.</p>
            <h2>Порядок сбора, хранения, передачи, распространения и других видов обработки персональных данных  </h2>
          </div>
          <ol class="privacy__list">
            <li>
              <p class="privacy__list__number">6.1</p>
              <p class="privacy__text">Безопасность персональных данных, которые обрабатываются Оператором персональных
                данных, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для
                выполнения в полном объеме требований действующего законодательства в области защиты персональных данных
                каждой из стран, в которой предоставляются услуги Оператора.  </p>
            </li>
            <li>
              <p class="privacy__list__number">6.2</p>
              <p class="privacy__text">Оператор обеспечивает сохранность персональных данных и принимает все возможные
                меры, исключающие доступ к персональным данным неуполномоченных лиц. </p>
            </li>
            <li>
              <p class="privacy__list__number">6.3</p>
              <p class="privacy__text">Персональные данные Субъекта персональных данных никогда, ни при каких условиях
                не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего
                законодательства.</p>
            </li>
            <li>
              <p class="privacy__list__number">6.4</p>
              <p class="privacy__text">Субъекта персональных данных самостоятельно указывает свои персональные данные и
                непосредственно отвечает за достоверность предоставленной (публикуемой) информации. В случае выявления
                неточностей в персональных данных, Субъект персональных данных может актуализировать их самостоятельно,
                путем направления Оператору уведомления на адрес его электронной почты <a class="privacy__text_colored"
                                                                                          href="mailto: welcome@zv-ai.ru">welcome@zv-ai.ru</a>
                с пометкой «Актуализация персональных данных».</p>
            </li>
            <li>
              <p class="privacy__list__number">6.5</p>
              <p class="privacy__text">Срок обработки персональных данных является неограниченным. Субъект персональных
                данных может в любой момент отозвать свое согласие на обработку персональных данных, в том числе
                разрешенных для распространения, направив Оператору уведомление посредством электронной почты на его
                электронный адрес <a class="privacy__text_colored" href="mailto: welcome@zv-ai.ru">welcome@zv-ai.ru</a>
                с пометкой «Отзыв согласия на обработку персональных данных».  </p>
            </li>
            <li>
              <p class="privacy__list__number">6.6</p>
              <p class="privacy__text">Оператор вправе поручить обработку персональных данных третьему лицу только с
                письменного согласия Субъекта персональных данных, если иное не предусмотрено применимым
                законодательством, на основании заключенного с третьим лицом договора, условием которого является
                соблюдение конфиденциальности о неразглашении персональных данных.</p>
            </li>
            <li>
              <p class="privacy__list__number">6.7</p>
              <p class="privacy__text">Представители органов государственной власти (в том числе, контролирующих,
                надзорных, правоохранительных и других), получают доступ к персональным данным, обрабатываемым Опера</p>
            </li>
          </ol>
          <br>
          <p class="privacy__text">
            <span class="privacy__text_colored">ВНИМАНИЕ!</span> Оператор персональных данных оставляет за собой право
            проверить личность Субъекта персональных данных перед тем, как реализовывать его права, путем запроса
            документов, удостоверяющих личность.
            Не допускается предоставление персональных данных, принадлежащих иному субъекту, без его согласия. Вы несете
            ответственность за последствия, которые могли повлечь неисполнение вышеуказанного требования.
            Ваши данные могут быть переданы на территорию иностранного государства в случаях и в порядке,
            предусмотренными применимым законодательством (трансграничная передача).
          </p>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">7.</p>
            <h2>Трансграничная передача персональных данных </h2>
          </div>
          <ol class="privacy__list">
            <li>
              <p class="privacy__list__number">7.1</p>
              <p class="privacy__text">Оператор до начала осуществления трансграничной передачи персональных данных
                обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять
                передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.  </p>
            </li>
            <li>
              <p class="privacy__list__number">7.2</p>
              <p class="privacy__text">Трансграничная передача персональных данных на территории иностранных государств,
                не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в
                письменной форме Субъекта персональных данных на трансграничную передачу его персональных данных и/или
                исполнения договора, стороной которого является Субъект персональных данных.  </p>
            </li>
          </ol>
          <br>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">8.</p>
            <h2>Обязанности Субъекта персональных данных</h2>
          </div>
          <ol class="privacy__list">
            <li>
              <p class="privacy__list__number">8.1</p>
              <p class="privacy__text">Субъект персональных данных обязан передавать достоверные, необходимые для
                достижения целей обработки, персональные данные, а также подтверждать достоверность персональных данных
                предъявлением оригиналов документов. В случае изменения персональных данных, необходимых для достижения
                целей обработки, сообщить уточненные персональные данные и подтвердить изменения оригиналами документов,
                а также выполнять требования применимого законодательства.</p>
            </li>
          </ol>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">9.</p>
            <h2>Сроки обработки персональных данных</h2>
          </div>
          <ol class="privacy__list">
            <li>
              <p class="privacy__list__number">9.1</p>
              <p class="privacy__text">Сроки обработки персональных данных определяются исходя из целей обработки, но не
                дольше чем это определено применимым законодательством. Персональные данные, срок обработки (хранения)
                которых истек, должны быть уничтожены или обезличены, если иное не предусмотрено применимым
                законодательством. Хранение персональных данных осуществляется в форме, позволяющей определить Субъекта
                персональных данных, не дольше, чем этого требуют цели обработки персональных данных. Обрабатываемые
                персональные данные подлежат уничтожению или обезличиванию по достижении целей обработки или в случае
                утраты необходимости в достижении этих целей, если иное не предусмотрено законодательством.</p>
            </li>
          </ol>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">10.</p>
            <h2>Ограничения по возрасту</h2>
          </div>
          <ol class="privacy__list">
            <li>
              <p class="privacy__list__number">10.1</p>
              <p class="privacy__text">Оператор не оказывает услуги пользователям, не достигшим 18 лет.</p>
            </li>
          </ol>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">11.</p>
            <h2>Заключительные положения</h2>
          </div>
          <ol class="privacy__list">
            <li>
              <p class="privacy__list__number">11.1</p>
              <p class="privacy__text">
                В данном документе будут отражены любые изменения политики обработки персональных данных Оператором.
                Политика действует бессрочно до замены ее новой версией.
              </p>
            </li>
            <li>
              <p class="privacy__list__number">11.2</p>
              <p class="privacy__text">Актуальная версия Политики в свободном доступе расположена в сети Интернет по
                адресу <a class="privacy__text_colored" href="mailto: welcome@zv-ai.ru">welcome@zv-ai.ru</a></p>
            </li>
            <li>
              <p class="privacy__list__number">11.3</p>
              <p class="privacy__text">При внесении изменений в настоящую Политику указывается дата последнего
                обновления. Если Вы продолжаете каким-либо образом взаимодействовать с Оператором, в частности (не
                ограничиваясь) через Сайт и/или Приложение, и/или Вы не отозвали свое согласие на обработку Ваших
                данных, Вы соглашаетесь с действующей в это время редакцией Политики, в том числе предоставляете
                согласие на обработку Ваших данных.</p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Tr from "@/i18n/translation";

export default {
  name: "CharlaPrivacyPopup",
  methods: {
    ...mapActions(['setPrivacyState', 'setFormState', 'setReadPrivacy']),
    closePrivacy() {
      const privacyWrapper = document.querySelector('.privacy__wrapper');
      const privacyBackground = document.querySelector('.privacy__background');
      const privacyClose = document.querySelector('.privacy__close')

      privacyWrapper.classList.add('privacy__wrapper__disable');
      privacyBackground.classList.add('privacy__background__disable');
      privacyClose.classList.add('privacy__close__disable')


      setTimeout(() => {
        privacyWrapper.classList.remove('privacy__wrapper__disable');
        privacyBackground.classList.remove('privacy__background__disable');
        privacyClose.classList.remove('privacy__close__disable')
        this.setPrivacyState(false)

        if (this.getReadPrivacy) {
          this.setReadPrivacy(false)
          this.setFormState(true)
        }
      }, 500)
    }
  },
  computed: {
    ...mapGetters(['getPrivacyState', 'getReadPrivacy']),
    Tr() {
      return Tr
    }
  }
}
</script>

<style scoped lang="scss">
.main__grid {
  display: grid;
  grid-template: repeat(4, 1fr) / repeat(2, 1fr);
  border: 1px solid #98da2c;
  border-radius: 20px;
  overflow: hidden;
}

.main__text {
  border: 1px solid #98da2c;
  padding: 10px;
}

.privacy {
  &__background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    animation: 500ms ease forwards __fadeIn;

    &__disable {
      animation: 500ms ease forwards __fadeOut;
    }
  }

  &__close {
    position: fixed;
    background-color: white;
    top: 48px;
    left: calc(50% + 418px);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 100000;
    transform: translate(50%, 0);
    display: grid;
    place-items: center;
    cursor: pointer;
    animation: 500ms ease forwards __slideUpClose;

    &__disable {
      animation: 500ms ease forwards __slideDownClose;
    }
  }

  &__wrapper {
    padding: 64px;
    position: fixed;
    left: 50%;
    top: 24px;
    transform: translate(-50%, 0);
    max-width: 836px;
    width: 100%;
    background-color: white;
    height: calc(100vh - 48px);
    overflow: hidden scroll;
    z-index: 10000;
    border-radius: 44px;
    animation: 500ms ease forwards __slideUp;

    &__disable {
      animation: 500ms ease forwards __slideDown;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #000;

    &_small {
      font-weight: 600;
      font-size: 22px;
      line-height: 127%;
      color: #000;
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 0 0 32px;
    }

    &__number {
      width: 48px;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;

    & .privacy__text:last-of-type {
      padding: 0;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;
    padding: 0 0 16px;

    &_colored {
      color: #98da2c;
    }
  }

  &__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_padding {
      padding: 16px 0 0 0;
    }

    & > li {
      display: grid;
      grid-template: 1fr / 48px 1fr;
    }

    & > .privacy__list_line {
      grid-template: 1fr / 96px 1fr;

      & > p {
        position: relative;

        & > svg {
          position: absolute;
          right: 30px;
          top: 10px;
        }
      }
    }

    &__title {
      font-weight: 600;
      font-size: 14px;
      line-height: 157%;
      color: #000;
    }

    &__number {
      font-weight: 600;
      font-size: 14px;
      line-height: 157%;
      color: var(--green);

      &_uncolored {
        padding: 32px 0 0 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 157%;
        color: #000;
      }
    }
  }
}

.colored__text {
  color: #B561F6;
}

@keyframes __fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes __fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes __slideUp {
  from {
    transform: translate(-50%, 120vh);
  }
  to {
    transform: translate(-50%, 0);
  }
}

@keyframes __slideDown {
  from {
    transform: translate(-50%, 0);
  }
  to {
    transform: translate(-50%, 120vh);
  }
}

@keyframes __slideUpClose {
  from {
    transform: translate(50%, 120vh);
  }
  to {
    transform: translate(50%, 0);
  }
}

@keyframes __slideDownClose {
  from {
    transform: translate(50%, 0);
  }
  to {
    transform: translate(50%, 120vh);
  }
}

.short-line {
  grid-template: 1fr/46px 1fr !important;
}

@media screen and (max-width: 660px) {
  .privacy__wrapper {
    width: calc(100vw - 16px);
    height: calc(100vh - 16px);
    padding: 0;
    top: 8px;
    border-radius: 26px;
    box-sizing: border-box;
  }
  .privacy__container {
    gap: 24px;
    padding: 16px;
  }
  .privacy__title {
    font-size: 22px;
    margin: 10px 0 0 0
  }
  .privacy__close {
    background: #000;
    top: 18px;
    right: 36px;
    left: unset;
    width: 36px;
    height: 36px;
  }
  .privacy__close svg {
    width: 15px;
    height: 15px;
  }
  .privacy__close svg path {
    fill: #fff;
  }
  .privacy__list {
    gap: 8px;
    padding: 0;
    margin: 0;
  }
  .privacy__text {
    margin: 0;
    font-size: 13px;
  }
  .privacy__block__title {
    font-size: 18px;
    margin: 0 0 32px 0;
    text-align: center;
    padding: 0;
  }
  .privacy__title_small {
    align-items: self-start;
    padding: 0;
    margin-bottom: 20px;
  }
  .privacy__title_small h2 {
    font-size: 18px;
    margin: 0;
    padding: 0;
  }
  .privacy__list__title {
    margin: 0;
    padding: 0;
  }
  .privacy__title__number {
    font-size: 18px;
    margin: 0;
    padding: 0;
  }
  .privacy__list__number {
    padding: 0;
    margin: 0;
  }
  .privacy__list__number_uncolored {
    margin: 0;
    padding: 0;
  }
  .main__text {
    font-size: 13px;
    padding: 5px;
    margin: 0;
  }
}
</style>