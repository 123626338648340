<template>
  <div class="landing__block block" data-animation-trigger="true">
    <div class="block__left" data-animation="true" data-animation-type="move-down">
      <h1 class="landing__title">
         <span v-html='$t("CharlaLandingBlock.title_part1")'></span>
         <span v-html='$t("CharlaLandingBlock.title_part2")'></span>
      </h1>
      <h2 class="landing__title_small" v-html='$t("CharlaLandingBlock.slogan")'></h2>
      <div class="landing__buttons__wrapper">
        <a href="https://app.charla-ai.com/auth/sign-up" target="_blank" @click="click_buttonFree_new_id7(); sendMetrik('click_button-free_new_id7')" class="landing__join">
          {{ $t("CharlaLandingBlock.btn") }}
        </a>
        <button class="landing__test" @click="setFormState(true)">Запросить демо</button>
      </div>
      <div class="landing__slogan">
      </div>
    </div>
    <div class="block__right" data-animation="true" data-animation-type="move-down">
      <div class="landing__carousel__wrapper">
        <div class="landing__carousel" :style="[carouselBool ? {'transform': 'translate(' + carouselTranslate + '%, 0)'} : {'transform': 'translate(-66.66%,0)'}]" data-translate-position="-66.66">
          <div class="landing__carousel__item">
            <div class="landing__carousel__item__title" v-html='$t("CharlaLandingBlock.slider.carouselItem1.title")'></div>
            <p class="landing__carousel__item__text" v-html='$t("CharlaLandingBlock.slider.carouselItem1.text")'></p>
          </div>
          <div class="landing__carousel__item">
            <div class="landing__carousel__item__title" v-html='$t("CharlaLandingBlock.slider.carouselItem2.title")'></div>
            <p class="landing__carousel__item__text" v-html='$t("CharlaLandingBlock.slider.carouselItem2.text")'></p>
          </div>
          <div class="landing__carousel__item">
            <div class="landing__carousel__item__title" v-html='$t("CharlaLandingBlock.slider.carouselItem3.title")'></div>
            <p class="landing__carousel__item__text" v-html='$t("CharlaLandingBlock.slider.carouselItem3.text")'></p>
          </div>
        </div>
        <div class="landing__carousel__button__wrapper">
          <button :class="this.carouselTranslate > -50 ? 'landing__carousel__button__back_active' : 'landing__carousel__button__back_disabled'" class="landing__carousel__button landing__carousel__button__back" @click="scrollCarousel">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="white" />
            </svg>
          </button>
          <button :class="this.carouselTranslate === 0 ? 'landing__carousel__button_disabled' : 'landing__carousel__button_active'" class="landing__carousel__button" @click="scrollCarousel('right')">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="black" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tr from "@/i18n/translation";
import { useGtm } from '@gtm-support/vue-gtm';
import { sendMetrik } from "@/utils/metrica";
import {mapActions} from "vuex";


export default {
  name: "CharlaLandingBlock",
  data() {
    return {
      carouselTranslate: 0,
      carouselBool: true
    }
  },
  computed: {
    Tr() {
      return Tr
    }
  },
  props: {
    animateClass: ''
  },
  methods: {
     ...mapActions(['setFormState']),
    sendMetrik: sendMetrik,

    click_buttonFree_new_id7(){
      const gtm = useGtm()
      gtm.dataLayer().push({
        'event':'click_button-free_new_id7',
        'eventCategory':'click',
        'eventAction':'button-free',
        'eventLabel':'new_id7'
      })
    },
    scrollCarousel(direction) {
      const carouselButtons = [...document.querySelectorAll('.landing__carousel__button')];
      const carousel = document.querySelector('.landing__carousel');

      if (direction === 'right'){
        this.carouselTranslate += 33.33
      } else {
        this.carouselTranslate -= 33.33
      }

      carousel.dataset.translatePosition = `${this.carouselTranslate}`
    }
  },
  mounted() {
    const splideContainer= document.querySelector('.splide')
    const carousel = document.querySelector('.landing__carousel')
    this.carouselTranslate = +carousel.dataset.translatePosition

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          splideContainer.dataset.stopScroll = 'false'
        } else {
          splideContainer.dataset.stopScroll = 'true'
        }
      })
    }, {
      threshold: 1
    });

    const carouselItem = document.querySelector('.landing__carousel__item');
    observer.observe(carouselItem)

    const config = {
      attributes: true,
    };
    const callback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes'){
          this.carouselTranslate = +carousel.dataset.translatePosition
        }
      })
    }

    const mutationObserver = new MutationObserver(callback)
    mutationObserver.observe(carousel, config)
  }
}
</script>

<style scoped lang="scss">
  @import "@/scss/mixins";

  .block__right{
    position: relative;
    display: flex;
    align-items: center;
  }

  .landing{
    &__block{
      display: grid;
      grid-template: 1fr / 1fr 1fr;
      min-height: calc(100vh - 48px);
      justify-items: flex-start;
      padding: 24px 0;
      opacity: 1;
      transition: 300ms ease;
      scroll-snap-align: start;
      position: relative;
      z-index: 0;
      overflow: hidden;

      &__left{
        width: calc((50vw - 32px));
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        gap: 32px;
        padding: 0 0 0 176px;
      }

      &__right{
        width: calc((50vw - 12px));
        display: grid;
        align-items: center;
        position: relative;
        overflow: hidden;
      }
    }

    &__test{
      font-weight: 600;
      font-size: 16px;
      line-height: 137%;
      color: #fff;
      border-radius: 24px;
      padding: 16px 24px;
      background-color: #000;
    }

    &__buttons__wrapper{
      display: flex;
      gap: 16px;
    }

    &__title{
      display: flex;
      flex-direction: column;
      font-weight: 600;
      line-height: 133%;
      font-size: 36px;
      color: #000;
      margin: 0;

 

      &_small{
        display: flex;
        flex-direction: column;
        font-weight: 600;
        font-size: 18px;
        line-height: 156%;
        color: #000;
        max-width: 553px;
        width: 100%;
      }

      &__bold{
        font-weight: 600;
      }

      &__img{
        background-color: #FF6332;
        overflow: hidden;
        width: 88px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        transform: translate(0, -4px);

        &__wrapper{
          display: flex;
          align-items: flex-end;
          gap: 16px;
        }
      }
    }

    &__join{
      font-weight: 600;
      font-size: 16px;
      line-height: 137%;
      color: #fff;
      width: fit-content;
      padding: 16px 24px;
      border-radius: 24px;
      background-color: #FF6332;
    }

    &__slogan{
      font-weight: 400;
      font-size: 14px;
      max-width: 553px;
      width: 100%;
      line-height: 157%;
    }

    &__carousel{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-items: flex-start;
      width: fit-content;
      transition: 300ms ease transform;
      position: relative;

      &__wrapper{
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 304px;
      }

      &__button{
        background-color: white;
        padding: 16px 28px;
        border-radius: 24px;
        display: grid;
        place-items: center;
        transition: 300ms ease all;
        position: relative;
        z-index: 0;

        & > svg{
          transition: 300ms ease all;
        }

        &:hover > svg{
          transform: translate(10px, 0);
        }

        &:active > svg{
          transform: translate(10px, 0);
        }

        &_active{
          opacity : 1;
          transform : translate(0);
          pointer-events: all;
        }

        &_disabled{
          opacity : 0;
          transform : translate(-114px, 0);
          pointer-events: none;
        }

        &__back{
          z-index: -1;
          background-color: black;
          transform: rotate(180deg);

          &_active{
            opacity: 1;
            transform: translate(0) rotate(180deg);
            pointer-events : all;
            z-index: 1;
          }

          &_disabled{
            opacity: 0;
            transform: translate(114px) rotate(180deg);
            pointer-events: none;
            z-index: -1;
          }
        }

        &__wrapper{
          display: flex;
          justify-content: flex-end;
          position: absolute;
          width: 448px;
          bottom: 0;
          gap: 10px;
        }
      }

      & > .landing__carousel__item:nth-child(1){
        background-color: #FF6332;
      }
      & > .landing__carousel__item:nth-child(2){
        background-color: var(--purple);
      }
      & > .landing__carousel__item:nth-child(3){
        background-color: var(--green);
      }
      & > .landing__carousel__item:nth-child(4){
        background-color: #b561f6;

        & > .landing__carousel__item__numbers{
          padding: 0 10px 0 22px;
        }
      }

      &__item{
        width: 224px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 32px;
        height: 240px;

        &__title{
          font-weight: 600;
          font-size: 18px;
          line-height: 156%;
          color: #fff;
        }

        &__text{
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          color: #fff;
        }
      }
    }
  }

  .landing__join:hover{
    gap: 0;
  }

  .landing__join:hover > .landing__join__button {
    background-color: var(--purple);
    border-radius: 24px 0 0 24px;
  }

  .landing__join:hover > .landing__join__plus{
    border: 1px solid var(--purple);
    background-color: var(--purple);
    border-radius: 0 24px 24px 0;
  }

  .landing__join:hover > .landing__join__plus > svg > path{
    fill: white;
  }



  @media screen and (max-width: 1600px) {
    .landing__title{
      font-size: 36px;
      line-height: 117%;
    }

    .landing__title__img{
      width: 64px;
      height: 40px;
      transform: translate(0, -6px);
    }
  }
  @media screen and (max-width: 1300px){
    .landing__carousel__button__wrapper,
    .carousel__button__wrapper {
      width: 420px;
    }
  }

  @media screen and (max-width: 1175px){
    .landing__title{
      font-size: 30px;
      padding: 0 16px 0 0;
    }
    .landing__title_small{
      font-size: 16px;
      padding: 0 16px 0 0;
    }

    .landing__join, .landing__test{
      font-size: 14px;
    }

    .landing__buttons__wrapper{
      padding: 0 16px 0 0;
    }
  }

  @media screen and (max-width: 945px){
    .landing__title{
      font-size: 26px;
      padding: 0 16px 0 0;
    }

    .landing__title_small{
      font-size: 14px;
      padding: 0 16px 0 0;
    }

    .landing__join, .landing__test{
      font-size: 12px;
      padding: 12px 18px
    }

    .landing__buttons__wrapper{
      gap: 8px;
    }

    .landing__carousel__button__wrapper,
    .carousel__button__wrapper {
      width: 380px;
    }
  }

  @media screen and (max-width: 810px){
    .landing__title{
      font-size: 22px;
    }

    .landing__join, .landing__test{
      font-size: 11px;
      padding: 12px 14px;
      text-align: center;
    }

    .landing__carousel__button__wrapper,
    .carousel__button__wrapper {
      width: 300px;
    }
  }

</style>