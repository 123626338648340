<template>
  <div class="working__popup" v-show="getWorkingState">
    <div class="working__background" @click="closeWorking()"></div>
    <div class="working__close" @click="closeWorking()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.42 10L20 18.58L18.58 20L10 11.42L1.42 20L0 18.58L8.58 10L0 1.42L1.42 0L10 8.58L18.58 0L20 1.42L11.42 10Z"
            fill="black"/>
      </svg>
    </div>
    <div class="working__wrapper">
      <div class="working__container">
        <h2 class="working__title">{{ $t("termsOfUse.mainTitle") }}</h2>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title1") }}</div>
          <p class="working__text">{{ $t("termsOfUse.block1.text1") }}</p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block1.text2") }}</p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block1.text3") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title1_1") }}</div>
          <p class="working__text">{{ $t("termsOfUse.block2.text1") }}</p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block2.text2") }}</p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block2.text3") }}</p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block2.text4") }}</p>
          <br>
          <ul class="working__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block2.text5") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block2.text6") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block2.text7") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block2.text8") }}</p>
            </li>
          </ul>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block2.text9") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title1_2") }}</div>
          <p class="working__text">{{ $t("termsOfUse.block3.text1") }}</p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block3.text2") }}</p>
          <br>
          <ul class="working__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block3.text3") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block3.text4") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block3.text5") }}</p>
            </li>
          </ul>
          <br>
          <p class="working__text" v-if="Tr.currentLocale === 'ru'">
            Для возврата денежных средств на банковскую карту необходимо заполнить «Заявление о возврате денежных средств», которое Компания высылает по требованию на электронный адрес, и отправить его вместе с приложением копии паспорта по адресу <a href="mailto:welcome@zv-ai.ru">welcome@zv-ai.ru</a>.
          </p>
          <p class="working__text" v-if="Tr.currentLocale === 'en'">
            In order to make a refund to your bank card, you should fill in the "Application for refund", which the Company provides on request to your e-mail address, and send it together with a passport copy to <a href="mailto:welcome@zv-ai.ru">welcome@zv-ai.ru</a>.
          </p>
          <br>
          <p class="working__text" v-if="Tr.currentLocale === 'ru'">
            Возврат денежных средств будет осуществлен на банковскую карту в течение 21 (двадцати одного) рабочего дня со дня получения «Заявления о возврате денежных средств» Компанией. Для возврата денежных средств по операциям, проведенным с ошибками, необходимо обратиться с письменным заявлением и приложением копии паспорта, а также чеков/квитанций, подтверждающих ошибочное списание. Данное заявление необходимо направить по адресу <a href="mailto:welcome@zv-ai.ru">welcome@zv-ai.ru</a>.
          </p>
          <p class="working__text" v-if="Tr.currentLocale === 'en'">
            The refund will be made to the bank card within 21 (twenty-one) working days from the day of receiving the "Application for refund" by the Company. In order to refund money for transactions made in error, it is necessary to apply with a written application and attach a copy of the passport, as well as receipts/payment slip proving the incorrect payment. This application should be sent to <a href="mailto:welcome@zv-ai.ru">welcome@zv-ai.ru</a>.
          </p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block3.text8") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title2") }}</div>
          <p class="working__text">{{ $t("termsOfUse.title2_1") }}</p>
          <br>
          <ul class="working__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block4.text1") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block4.text2") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block4.text3") }}</p>
            </li>
          </ul>
          <br>
          <p class="working__text">{{ $t("termsOfUse.title2_2") }}</p>
          <br>
          <div class="main__grid">
            <p class="main__grid__title">{{ $t("termsOfUse.grid.gridTitle1") }}</p>
            <p class="main__grid__title">{{ $t("termsOfUse.grid.gridTitle2") }}</p>
            <p class="main__text">{{ $t("termsOfUse.grid.column1.row1") }}</p>
            <p class="main__text">{{ $t("termsOfUse.grid.column2.row1") }}</p>
            <p class="main__text">{{ $t("termsOfUse.grid.column1.row2") }}</p>
            <p class="main__text">{{ $t("termsOfUse.grid.column2.row2") }}</p>
            <p class="main__text">{{ $t("termsOfUse.grid.column1.row3") }}</p>
            <div>
              <p class="main__text first">{{ $t("termsOfUse.grid.column2.row3") }}</p>
            </div>
          </div>
          <br>
          <p class="working__text">{{ $t("termsOfUse.title2_3") }}</p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.block5.text1") }}</p>
          <br>
          <p class="working__text">{{ $t("termsOfUse.title2_4") }}</p>
          <br>
          <ul class="working__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block6.text1") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block6.text2") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block6.text3") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block6.text4") }}</p>
            </li>
          </ul><br>
          <p class="working__text">{{ $t("termsOfUse.title2_5") }}</p>
          <br>
          <ul class="working__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block7.text1") }}</p>
            </li>
          </ul>
          <br>
          <p class="working__text">{{ $t("termsOfUse.title2_6") }}</p>
          <br>
          <ul class="working__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block8.text1") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block8.text2") }}</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block8.text3") }}</p>
            </li>
            <li class="working__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block8.text4") }}</p>
            </li>
            <li class="working__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block8.text5") }}</p>
            </li>
          </ul><br>
          <p class="working__text">{{ $t("termsOfUse.title2_7") }}</p>
          <br>
          <ul class="working__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text">{{ $t("termsOfUse.block9.text1") }}</p>
            </li>
          </ul><br>
          <p class="working__text">{{ $t("termsOfUse.title2_8") }}</p>
          <br>
          <ul class="working__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="working__text" v-if="Tr.currentLocale === 'ru'">
                Для консультаций по ценам и услугам, пожалуйста, свяжитесь с нашим отделом продаж по почте <a href="mailto:welcome@zv-ai.ru">welcome@zv-ai.ru</a>.
              </p>
              <p class="working__text" v-if="Tr.currentLocale === 'en'">
                Please contact our sales department at <a href="mailto:welcome@zv-ai.ru">welcome@zv-ai.ru</a> for any questions about prices and services..
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Tr from "@/i18n/translation";

export default {
  name: "CharlaWorkingPopup",
  methods: {
    ...mapActions(['setWorkingPopup']),
    closeWorking() {
      const workingWrapper = document.querySelector('.working__wrapper');
      const workingBackground = document.querySelector('.working__background');
      const workingClose = document.querySelector('.working__close')

      workingWrapper.classList.add('working__wrapper__disable');
      workingBackground.classList.add('working__background__disable');
      workingClose.classList.add('working__close__disable');

      setTimeout(() => {
        workingWrapper.classList.remove('working__wrapper__disable');
        workingBackground.classList.remove('working__background__disable');
        workingClose.classList.remove('working__close__disable')
        this.setWorkingPopup(false)
      }, 500)
    }
  },
  computed: {
    ...mapGetters(['getWorkingState']),
  },
  setup(){
    return{Tr}
  },
}
</script>

<style scoped lang="scss">
.main__grid {
  display: grid;
  grid-template-columns: minmax(auto, 300px) 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  border-radius: 20px;
}

.main__text {
  padding: 10px;
  height: 100%;
}
.main__grid > p, .main__grid > div {
  border: 1px solid #98da2c;
}
.main__grid__title {
  font-size: 20px;
  font-weight: 500;
  padding: 10px;
  justify-self: center;
  width: 100%;
  text-align: center;
}
.main__list_hollow {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 0 10px;
}
.main__list_hollow > li {
  position: relative;
  padding: 0 0 0 80px;
}
.main__list_hollow > li::before {
  content: "";
  border: #98da2c 1px solid;
  position: absolute;
  border-radius: 50%;
  left: 60px;
  top: 7px;
  width: 6px;
  height: 6px;
}
.working {
  &__background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    animation: 500ms ease forwards __fadeIn;

    &__disable {
      animation: 500ms ease forwards __fadeOut;
    }
  }

  &__close {
    position: fixed;
    background-color: white;
    top: 48px;
    left: calc(50% + 418px);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 100000;
    transform: translate(50%, 0);
    display: grid;
    place-items: center;
    cursor: pointer;
    animation: 500ms ease forwards __slideUpClose;

    &__disable {
      animation: 500ms ease forwards __slideDownClose;
    }
  }

  &__wrapper {
    padding: 64px;
    position: fixed;
    left: 50%;
    top: 24px;
    transform: translate(-50%, 0);
    max-width: 836px;
    width: 100%;
    background-color: white;
    height: calc(100vh - 48px);
    overflow: hidden scroll;
    z-index: 10000;
    border-radius: 44px;
    animation: 500ms ease forwards __slideUp;

    &__disable {
      animation: 500ms ease forwards __slideDown;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #000;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;
  }

  &__block {
    &__title {
      font-weight: 600;
      font-size: 22px;
      line-height: 127%;
      color: #000;
      padding: 0 0 32px 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > li {
      display: grid;
      grid-template: 1fr / 48px 1fr;
    }

    &__title {
      font-weight: 600;
      font-size: 14px;
      line-height: 157%;
      color: #000;
    }

    & > li.working__list__marked {
      grid-template: 1fr / 72px 1fr;

      & > p {
        position: relative;

        & > svg {
          position: absolute;
          left: 50px;
          top: 9px;
        }
      }
    }
  }
}

@keyframes __fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes __fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes __slideUp {
  from {
    transform: translate(-50%, 120vh);
  }
  to {
    transform: translate(-50%, 0);
  }
}

@keyframes __slideDown {
  from {
    transform: translate(-50%, 0);
  }
  to {
    transform: translate(-50%, 120vh);
  }
}

@keyframes __slideUpClose {
  from {
    transform: translate(50%, 120vh);
  }
  to {
    transform: translate(50%, 0);
  }
}

@keyframes __slideDownClose {
  from {
    transform: translate(50%, 0);
  }
  to {
    transform: translate(50%, 120vh);
  }
}

@media screen and (max-width: 660px) {
  .main__grid__title{
    font-size: 13px;
    padding: 5px;
    border: none!important;
  }
  .main__grid{
    grid-template-columns: minmax(auto, 200px) 1fr;
    border: 1px solid #98da2c;
    overflow: hidden;
  }
  .first{
    margin: -5px 0 0 0!important;
    border-top: 1px solid #98da2c;
  }
  .main__grid > p, .main__grid > div {
    border: none;
  }
  .main__grid > p {
    border-top: 1px solid #98da2c;
  }
  .working__wrapper {
    width: calc(100vw - 16px);
    height: calc(100vh - 16px);
    padding: 0;
    top: 8px;
    border-radius: 26px;
    box-sizing: border-box;
  }
  .working__container {
    gap: 24px;
    padding: 16px;
  }
  .working__title {
    font-size: 28px;
    margin: 10px 0 0 0
  }
  .working__close {
    background: #000;
    top: 18px;
    right: 36px;
    left: unset;
    width: 36px;
    height: 36px;
  }
  .working__close svg {
    width: 15px;
    height: 15px;
  }
  .working__close svg path {
    fill: #fff;
  }
  .working__list {
    gap: 8px;
    padding-left: 20px;
  }
  .working__text {
    margin: 0;
    font-size: 13px;
  }
  .working__block__title {
    font-size: 18px;
    margin: 0 0 32px 0;
    text-align: center;
    padding: 0;
  }
  .working__list > li.working__list__marked > p {
    margin: 0;
  }
  .main__text {
    font-size: 13px;
    padding: 5px;
    margin: 0;
  }
}
</style>