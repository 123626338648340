import { createRouter, createWebHistory, RouterView } from 'vue-router'
import HomeView from '../views/main.vue'
import faqPage from '../views/faq.vue'
import pp from '../views/pp.vue'
import reviewsPage from '../views/reviews.vue'
import Translation from "@/i18n/translation";
import publicOffer from "@/views/publicOffer.vue";
import termsOfUse from "@/views/termsOfUse.vue";
import cookiePolicy from "@/views/cookiePolicy.vue"

const routes = [
    {
        path: '/',
        component: RouterView,
        children: [
            {
                path: '',
                name: 'home',
                component: HomeView
            },
            {
                path: 'faq',
                name: 'faq',
                component: faqPage
            },
            {
                path: 'reviews',
                name: 'reviews',
                component: reviewsPage
            },
            {
                path: 'privacy-policy',
                name: 'privacy-policy',
                component: pp,
            },
            {
                path: 'terms-of-use',
                name: 'terms-of-use',
                component: termsOfUse,
            },
            {
                path: 'public-offer',
                name: 'public-offer',
                component: publicOffer,
            },
            {
                path: 'cookie-policy',
                name: 'cookie-policy',
                component: cookiePolicy,
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
