<script>
import {useGtm} from "@gtm-support/vue-gtm";
import {sendMetrik} from "@/utils/metrica";

export default {
  name: "CharlaBackground",
  data(){
    return{
      videoIsPlaying: true
    }
  },
  methods: {
    sendMetrik: sendMetrik,
    toggleVideoPlay() {
      this.videoIsPlaying = !this.videoIsPlaying;
      const video = document.querySelector('video');
      if (this.videoIsPlaying) {
        video.play();
      } else {
        video.pause();
      }
    },
    click_buttonPlay_new_id8(){
      const gtm = useGtm()
      gtm.dataLayer().push({
        'event':'click_button-play_new_id8',
        'eventCategory':'click',
        'eventAction':'button-play',
        'eventLabel':'new_id8'
      })
    },
  }
}
</script>

<template>
  <div class="background">
    <div class="video-control-btn pause" @click="toggleVideoPlay(); click_buttonPlay_new_id8(); sendMetrik('click_button-play_new_id8')">
      <div class="condition-pause" v-if="videoIsPlaying">
        <span></span><span></span>
      </div>
      <div class="condition-play" v-else>
        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 7L0 14V0L10 7Z" fill="white"/>
        </svg>
      </div>
    </div>
    <div class="background__wrapper">
      <div class="footer__rounding"></div>
      <div class="video-wrapper">
        <video src="@/assets/video/background-video.mp4" poster="public/img/video-poster.webp" pip="false" autoplay muted loop></video>
      </div>
      <div class="video-top-clip"><img src="@/assets/video/video-top-right-clip.png"></div>
      <div class="video-bottom-right-clip"><img src="@/assets/video/video-right-bot-clip.png"></div>
      <div class="video-bottom-left-clip"><img src="@/assets/video/video-left-clip.png"></div>
<!--      <img class="background__image" src="@/assets/img/background.webp" alt="Background Image">-->
<!--      <a class="account">-->
<!--        <div class="account__wrapper">-->
<!--          <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M22 17V18H20V17C20 15.35 18.65 14 17 14H5C3.35 14 2 15.35 2 17V18H0V17C0 14.24 2.24 12 5 12H17C19.76 12 22 14.24 22 17ZM6 5C6 2.24 8.24 0 11 0C13.76 0 16 2.24 16 5C16 7.76 13.76 10 11 10C8.24 10 6 7.76 6 5ZM8 5C8 6.65 9.35 8 11 8C12.65 8 14 6.65 14 5C14 3.35 12.65 2 11 2C9.35 2 8 3.35 8 5Z" fill="black"/>-->
<!--          </svg>-->
<!--        </div>-->
<!--      </a>-->
<!--      <div class="chat">-->
<!--        <div class="chat__wrapper">-->
<!--          <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M14 0H8C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16H14C18.42 16 22 12.42 22 8C22 3.58 18.42 0 14 0ZM14 14H8C4.69 14 2 11.31 2 8C2 4.69 4.69 2 8 2H14C17.31 2 20 4.69 20 8C20 11.31 17.31 14 14 14ZM7 5H15V7H7V5ZM7 9H11V11H7V9Z" fill="white"/>-->
<!--          </svg>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<style scoped lang="scss">
  .background{
    position: relative;
  }
  .background__wrapper {
    position: fixed;
    top: 24px;
    right: 15px;
    width: calc(50vw - 24px);
    bottom: 24px;
    box-sizing: border-box;
    border-top-left-radius: 50px;
    overflow: hidden;
    z-index: -1;
  }

  .video-wrapper {
    width: 100%;
    height: 100%;
  }
  .video-wrapper video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
  }

  .video-top-clip {
    position: absolute;
    top: 0;
    right: 0;
  }

  .video-bottom-right-clip {
    position: absolute;
    bottom: -3px;
    right: 0;
  }

  .video-bottom-left-clip {
    position: absolute;
    bottom: -3px;
    left: 0;
  }

  .video-control-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 40px;
    border-radius: 20px;
    background-color: #000;
    position: absolute;
    top: 96px;
    right: 96px;
    cursor: pointer;
    z-index: 100;
    transition: 300ms ease background-color;

    &:hover{
      background-color: var(--purple);
    }
  }

  .condition-pause {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
  }
  .condition-pause span {
    width: 2px;
    height: 12px;
    background: #fff;
  }

  .pause .condition-pause {
    display: flex;
  }
  .pause .condition-play {
    display: flex;
  }
  //.background{
  //  position: fixed;
  //  right: 20px;
  //  z-index: -1;
  //  width: calc((100vw - 24px) / 2);
  //  height: calc(100% - 48px);
  //
  //
  //
  //  &__wrapper{
  //    position: relative;
  //    display: flex;
  //    justify-content: center;
  //  }
  //
  //  &__image{
  //    border-top-left-radius: 43px;
  //    object-fit: cover;
  //    height: 100%;
  //    width: calc((100vw - 24px) / 2);
  //  }
  //}
  //
  //.background__wrapper{
  //  position: relative;
  //  height: 100%;
  //}

  .footer__rounding{
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: 0;
    width: 120px;
    height: 72px;
    border-radius: 0 44px 0 0;
    background-color: white;
  }
  @media screen and (max-width: 1300px){
    .video-control-btn {
      top: 20px!important;
      right: 110px!important;
    }
    .compare__carousel__item {
      padding: 30px 15px;
    }
  }
  @media screen and (max-height: 750px) {
    .video-control-btn{
      top: 25px;
    }
  }
  @media screen and (max-height: 850px) {
    .video-control-btn{
      top: 25px;
    }
  }
</style>